import { render, staticRenderFns } from "./FabricsUpdateModal.vue?vue&type=template&id=598c1473&"
import script from "./FabricsUpdateModal.vue?vue&type=script&lang=js&"
export * from "./FabricsUpdateModal.vue?vue&type=script&lang=js&"
import style0 from "./FabricsUpdateModal.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports