<template>
    <div class="assign-blind-modal">
        <ModalContainer :title="$t('blinds.add_blind')" identifier="assign-blind-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving || is_loading_blinds">
                <FormInputSelect v-model="$v.blind.$model" identifier="blind"
                                 :label="$t('blinds.blind')" :options="blindOptions"
                                 :placeholder="$t('blinds.blind')" :disabled="is_saving || is_loading_blinds"
                                 :has-error="$v.blind.$error" track-by="id"
                                 :display-custom-label="(row) => `${row.attributes.name}`"
                                 class="select-container">
                    <template v-slot:errors>
                        <p v-if="!$v.blind.required">
                            {{$t('validation.x_is_required',{x: $t('users.blind')})}}
                        </p>
                    </template>
                </FormInputSelect>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('add')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import FormInputTextSelect from "../form/FormInputTextSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";

export default {
    name: "AssignBlindModal",
    components: {FormInputPassword, Button, FormInputSelect, FormInputTextSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    data() {
        return {
            blind: null,
            blindOptions: [],
            is_loading_blinds: false,
            is_saving: false
        }
    },
    validations: {
        blind: {required}
    },
    methods: {
        close(status) {
            this.$modal.hide('assign-blind-modal', status);
        },
        save() {
            this.$v.blind.$touch();
            if (this.$v.blind.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            this.$axios.put(`users/${this.$route.params.id}/blinds/${this.blind.id}/attach`)
                .then(({data}) => {
                    this.is_saving = false;
                    this.close(true);
                })
                .catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('users.error_assign_blind')),
                        type: 'error',
                    });
                });
        },
        retrieveBlindOptions() {
            this.is_loading_blinds = false;
            this.$axios.get('blinds/list')
                .then(({data}) => {
                    this.blindOptions = data.data;
                    this.is_loading_blinds = false;
                })
                .catch(e => {
                    this.is_loading_blinds = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$t('users.error_retrieve_blinds'),
                        type: 'error',
                    });
                });
        },
    },

    mounted() {
        this.retrieveBlindOptions();
    }
}
</script>

<style lang="scss">
    .checkbox-container {
        @apply flex flex-row mb-8 mt-2;

        .pretty {
            &:last-of-type {
                @apply ml-8;
            }
        }
    }
</style>
