<template>
  <div class="save-quote-modal">
    <ModalContainer :closable="true" :title="title" identifier="save-quote-modal">
      <div class="content-container">
        <Form :disabled="is_saving" class="form" @submit="save">
          <FormInputText v-model="$v.details.name.$model" :disabled="is_saving" :has-error="$v.details.name.$error"
                         :label="$t('users.name')" identifier="name">
            <template v-slot:errors>
              <p v-if="!$v.details.name.required">
                {{ $t('validation.x_is_required', {x: $t('users.name')}) }}
              </p>
            </template>
          </FormInputText>

          <FormInputText v-model="$v.details.address.$model" :disabled="is_saving"
                         :has-error="$v.details.address.$error"
                         :label="$t('users.address')" identifier="contact">
            <template v-slot:errors>
              <p v-if="!$v.details.address.required">
                {{ $t('validation.x_is_required', {x: $t('users.address')}) }}
              </p>
            </template>
          </FormInputText>

          <FormInputText v-model="$v.details.contact_num.$model" :disabled="is_saving"
                         :has-error="$v.details.contact_num.$error"
                         :label="$t('users.contact_number')" identifier="contact">
            <template v-slot:errors>
              <p v-if="!$v.details.contact_num.required">
                {{ $t('validation.x_is_required', {x: $t('users.contact_number')}) }}
              </p>
            </template>
          </FormInputText>

          <FormInputText v-model="$v.details.installation.$model" :disabled="is_saving"
                         :has-error="$v.details.installation.$error"
                         :label="`${$t('cart.installation')} (€)`" identifier="installation" type="number">
            <template v-slot:errors>
              <p v-if="!$v.details.installation.required">
                {{ $t('validation.x_is_required', {x: $t('cart.installation')}) }}
              </p>
            </template>
          </FormInputText>

          <FormInputText v-model="$v.details.discount.$model" :disabled="is_saving"
                         :has-error="$v.details.discount.$error"
                         :label="`${$t('cart.discount')} (%)`" identifier="discount" type="number">
            <template v-slot:errors>
              <p v-if="!$v.details.discount.required">
                {{ $t('validation.x_is_required', {x: $t('cart.discount')}) }}
              </p>
            </template>
          </FormInputText>

          <div class="buttons">
            <Button :class="{spinner: is_saving}" :className="`${confirmClass} wider`" type="submit">
              {{ confirmText }}
            </Button>
          </div>
        </Form>
      </div>
    </ModalContainer>
  </div>
</template>

<script>
import Button from "../Button";
import ModalContainer from "../modal/ModalContainer";
import {required} from "vuelidate/lib/validators";
import FormInputText from "@/components/form/FormInputText";
import Form from "@/components/form/Form";

export default {
  name: 'SaveQuoteModal',
  components: {Form, FormInputText, ModalContainer, Button},
  props: {
    title: {
      type: String,
      required: false,
    },
    confirmText: {
      type: String,
      required: false,
      default: 'Yes',
    },
    confirmClass: {
      type: String,
      required: false,
      default: '--negative'
    },
    value: {
      type: Number,
      required: true
    },
    blinds: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      details: {
        name: null,
        address: null,
        contact_num: null,
        installation: null,
        discount: null,
      },
      is_saving: false,
    }
  },
  validations: {
    details: {
      name: {required},
      address: {required},
      contact_num: {required},
      installation: {required},
      discount: {required},
    }
  },
  methods: {
    attachBlinds(quote_id) {
      let payload = []
      this.blinds.forEach(blind => {
        payload.push({id: blind.id, ...blind.attributes})
      })

      this.$axios.put(`quotes/${quote_id}/blinds/sync`, {blinds: payload}).then(async ({data}) => {
        this.$notify({
          text: this.$t('quotes.success_created'),
          type: 'success',
        });
      }).catch(e => {
        this.is_saving = false;

        this.$notify({
          title: this.$t('error'),
          text: this.$larerror(e.response.data.errors, this.$t('quotes.error_create')),
          type: 'error',
        });
      });

    },
    save() {
      this.$v.details.$touch();
      if (this.$v.details.$anyError || this.is_saving) return;

      this.is_saving = true;

      let payload = {
        client_details: {
          name: this.details.name,
          phone: this.details.contact_num,
          address: this.details.address
        },
        installation: this.details.installation,
        discount: this.details.discount,
        value: (this.value - (this.details.discount ? (this.value * (this.details.discount / 100)).toFixed(2) : 0) + (this.details.installation ? parseInt(this.details.installation) : 0)).toFixed(2),
        user_id: this.$store.getters.user.id
      };

      this.$axios.post(`quotes`, payload).then(async ({data}) => {
        await this.attachBlinds(data.data.id);

        await this.$store.commit('SET_CART', null);
        await this.$store.commit('SET_CART_COUNT', 0);

        this.$router.push({name: 'quotes'});
        this.close(true);

        this.is_saving = false;
      }).catch(e => {
        this.is_saving = false;

        this.$notify({
          title: this.$t('error'),
          text: this.$larerror(e.response.data.errors, this.$t('quotes.error_create')),
          type: 'error',
        });
      });
    },
    close(status) {
      this.$modal.hide('save-quote-modal', status);
    },
  },
}
</script>

<style lang="scss" scoped>
.save-quote-modal {
  .content-container {
    @apply flex flex-col;

    .buttons {
      @apply w-full flex;

      button {
        @apply ml-auto py-3;
      }
    }
  }
}
</style>
