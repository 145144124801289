<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <Select v-if="selected_report === 'patient_data'" v-model="fields" :options="fieldOptions"
                        :multiple="true" label="name"
                        :placeholder="$t('reports.select_fields')" trackBy="column" :disabled="is_generating"
                        className="filter"/>

                <Form class="date-form" v-if="selected_report === 'ward_bookings' || selected_report === 'bookings'"
                      @submit="()=>false" :disabled="is_generating">
                    <FormInputDateTime className="filter" v-model="$v.date.from.$model" identifier="date_from"
                                       :inline-input="true" :label="$t('reports.date_from')" :only-date="true"
                                       formatted="Do MMM YYYY" :placeholder="$t('reports.date_from')"
                                       :disabled="is_generating" :has-error="$v.date.from.$error"
                                       output-format="DD-MM-YYYY"></FormInputDateTime>
                    <FormInputDateTime className="filter" v-model="$v.date.to.$model" identifier="date_to"
                                       :inline-input="true" :label="$t('reports.date_to')" :only-date="true"
                                       formatted="Do MMM YYYY" :placeholder="$t('reports.date_to')"
                                       :disabled="is_generating" :has-error="$v.date.to.$error"
                                       output-format="DD-MM-YYYY"></FormInputDateTime>
                </Form>
            </template>
            <template v-slot:right>
                <Select v-if="selected_report === 'ward_bookings'" v-model="ward" :options="wardOptions"
                        :placeholder="$t('wards.select_ward')" :custom-label="(row) => row.attributes.name"
                        track-by="id" :disabled="is_generating" className="filter"/>
            </template>
        </Headbar>
        <main>
            <div class="reports-row">
                <button v-for="report in reportOptions" v-if="$store.getters.hasAnyRole(report.need_roles)"
                        class="report-button-wrapper"
                        :class="{selected: selected_report && report.identifier === selected_report}"
                        @click="selectReport(report.identifier)">
                    <div class="report-button">
                        <span>{{report.title}}</span>
                    </div>
                </button>
            </div>
            <div class="export-row">
                <h3>{{$t('reports.export_report')}}</h3>

                <div class="row">
                    <button v-for="type in typeOptions" class="type-button-wrapper"
                            :class="{spinner: is_generating && selected_type && type.name === selected_type.name}"
                            @click="selectType(type)">
                        <div class="type-button">
                            <font-awesome-icon :icon="[type.icon.type, type.icon.name]"/>
                            <span>{{type.name}}</span>
                        </div>
                    </button>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import Headbar from "../../components/headbar/Headbar";
    import FormInputDateTime from "../../components/form/FormInputDateTime";
    import Form from "../../components/form/Form";
    import {required} from 'vuelidate/lib/validators'
    import Select from "../../components/form/Select";

    export default {
        name: "Index",
        components: {Select, Form, FormInputDateTime, Headbar},
        data: function() {
          const typeOptions= [
                {
                  name: 'pdf',
                  icon: {
                    type: 'fal',
                    name: 'file-pdf',
                  }
                },
              ];

          if(this.$store.getters.hasRole('administrator'))
            typeOptions.push({
              name: 'excel',
              icon: {
                type: 'fal',
                name: 'file-excel',
              }
            });

            return {
                date: {
                    from: null,
                    to: null,
                },
                selected_report: null,
                selected_type: null,
                ward: null,
                wardOptions: [],
                fields: [],
                fieldOptions: [],
                reportOptions: [
                    {
                        identifier: 'patient_data',
                        title: this.$t('reports.patients_data'),
                        need_roles: ['medical_records', 'customer_care', 'management', 'administrator']
                    },
                    {
                        identifier: 'bookings',
                        title: this.$t('reports.articles'),
                        need_roles: ['customer_care', 'visitors_booking', 'management', 'administrator']
                    },
                    {
                        identifier: 'ward_bookings',
                        title: this.$t('reports.ward_bookings'),
                        need_roles: ['customer_care', 'visitors_booking', 'management', 'administrator']
                    }
                ],
                typeOptions,
                is_generating: false,
                is_loading_wards: false,
                is_loading_fields: false,
            }
        },
        validations: {
            date: {
                from: {required},
                to: {required}
            },
        },
        methods: {
            selectReport(report) {
                this.selected_report = report;
            },
            selectType(type) {
                this.selected_type = type;
                this.requestReport();
            },
            requestReport() {
                if (this.selected_report === null || this.selected_type === null)
                    return;

                let acceptHeader = null;

                if (this.selected_type.name === 'pdf') {
                    acceptHeader = 'application/pdf';
                } else if (this.selected_type.name === 'excel') {
                    acceptHeader = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                }

                if (this.selected_report === 'ward_bookings') {
                    if (!this.ward || !this.date.from || !this.date.to) {
                        this.$notify({
                            text: this.$t('reports.select_date_and_ward'),
                            type: 'error',
                        });

                        return;
                    }

                    this.is_generating = true;
                    this.$axios.post(`wards/${this.ward.id}/ward-bookings`, {
                        date_from: this.date.from,
                        date_to: this.date.to,
                        file_type: this.selected_type.name,
                    }, {
                        responseType: 'arraybuffer',
                        headers: {
                            'Accept': acceptHeader
                        }
                    }).then(({data}) => {
                        this.blobToFile(data, 'Ward articles', acceptHeader);
                        this.is_generating = false;
                    }).catch(e => {
                        this.is_generating = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data.errors, this.$t('reports.error_generate')),
                            type: 'error',
                        });
                    });
                } else if (this.selected_report === 'bookings') {
                    if (!this.date.from || !this.date.to) {
                        this.$notify({
                            text: this.$t('reports.select_date'),
                            type: 'error',
                        });

                        return;
                    }

                    this.is_generating = true;
                    this.$axios.post(`bookings/bookings`, {
                        date_from: this.date.from,
                        date_to: this.date.to,
                        file_type: this.selected_type.name,
                    }, {
                        responseType: 'arraybuffer',
                        headers: {
                            'Accept': acceptHeader
                        }
                    }).then(({data}) => {
                        this.blobToFile(data, 'bookings', acceptHeader);
                        this.is_generating = false;
                    }).catch(e => {
                        this.is_generating = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data.errors, this.$t('reports.error_generate')),
                            type: 'error',
                        });
                    });
                } else if (this.selected_report === 'patient_data') {
                    if (this.fields.length === 0) {
                        this.$notify({
                            text: this.$t('reports.error_fields'),
                            type: 'error',
                        });

                        return;
                    }

                    this.is_generating = true;
                    this.$axios.post(`patients/patients-data`, {
                        filters: {
                            filters: this.fields.map(f => f.column),
                        },
                        file_type: this.selected_type.name,
                    }, {
                        responseType: 'arraybuffer',
                        headers: {
                            'Accept': acceptHeader
                        }
                    }).then(({data}) => {
                        this.blobToFile(data, 'patient data', acceptHeader);
                        this.is_generating = false;
                    }).catch(e => {
                        this.is_generating = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data.errors, this.$t('reports.error_generate')),
                            type: 'error',
                        });
                    });
                }
            },
            blobToFile(data, filename, fileType) {
                const blob = new Blob([data], {type: fileType});
                const url = window.URL.createObjectURL(blob);

                const tempLink = document.createElement('a');
                tempLink.style.display = 'none';
                tempLink.href = url;
                tempLink.setAttribute('download', filename);
                if (typeof tempLink.download === 'undefined') {
                    tempLink.setAttribute('target', '_blank');
                }

                document.body.appendChild(tempLink);
                tempLink.click();
                document.body.removeChild(tempLink);
            },
            retrieveWards() {
                this.is_loading_wards = false;
                this.$axios.get('wards/list')
                    .then(({data}) => {
                        this.is_loading_wards = false;
                        this.wardOptions = data.data;
                    })
                    .catch(e => {
                        this.is_loading_wards = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('wards.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
            retrieveFields() {
                this.is_loading_fields = false;
                this.$axios.get('patients/list-patient-fields')
                    .then(({data}) => {
                        this.is_loading_fields = false;
                        this.fieldOptions = data;
                        this.fields = this.fieldOptions;
                    })
                    .catch(e => {
                        this.is_loading_fields = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('patients.error_retrieve_fields')),
                            type: 'error',
                        });
                    });
            },
        },

        mounted() {
            this.retrieveWards();

            this.retrieveFields();

            if (this.typeOptions.length)
                this.selected_type = this.typeOptions[0];
        },

        head() {
            return {
                title: {
                    inner: this.$t('nav.reports')
                },
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-container {
        .date-form {
            @apply flex flex-row bg-transparent;

            & > * {
                @apply mx-3;

                &:first-child {
                    @apply ml-0;
                }

                &:last-child {
                    @apply mr-0;
                }
            }

            .input-group {
                @apply mb-0;
            }
        }

        main {
            .reports-row {
                @apply flex flex-row flex-wrap -mx-2 mb-24;

                button.report-button-wrapper {
                    @apply p-2 w-1/5;

                    .report-button {
                        @apply bg-grey-light rounded-xl p-8;

                        span {
                            @apply text-black font-bold text-xl;
                        }
                    }

                    &:active, &:focus {
                        @apply outline-none;
                    }

                    &:hover, &:focus {
                        .report-button {
                            @apply bg-primary-over;
                        }
                    }

                    &.selected {
                        .report-button {
                            @apply bg-primary;
                        }
                    }
                }
            }

            .export-row {
                @apply flex flex-col items-center;

                h3 {
                    @apply text-black font-bold text-center text-2xl;
                }

                .row {
                    @apply mt-10 flex flex-row justify-center flex-wrap -mx-2;

                    button.type-button-wrapper {
                        @apply p-4;

                        .type-button {
                            @apply bg-grey-light rounded-xl p-4 flex flex-row items-center;

                            svg {
                                @apply text-black text-5xl;
                            }

                            span {
                                @apply text-black font-bold text-xl ml-4;
                            }
                        }

                        &:active, &:focus {
                            @apply outline-none;
                        }

                        &:hover, &:focus {
                            .type-button {
                                @apply bg-primary-over;
                            }
                        }

                        &.selected {
                            .type-button {
                                @apply bg-primary;
                            }
                        }
                    }
                }
            }
        }
    }
</style>