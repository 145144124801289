<template>
    <div :class="['page-container', {'agent-profile': isAgent}]">
        <div :class="[{'agent-profile-container': isAgent}]">
            <Headbar>
                <template v-slot:left>
                    <div class="headbar">
                        <div class="agent-header" v-if="$store.getters.hasRole('agent') === true">
                            <router-link :to="{name: 'calculators-index'}">
                                <font-awesome-icon :icon="['far', 'home-lg-alt']" class="home-button"/>
                            </router-link>
                            <h1>{{ $t('profile.my_profile') }}</h1>
                            <Button className="--primary --small --wider headbar-fix"
                                    :class="{spinner: is_saving}" :onclick="save">
                                {{ $t('save') }}
                            </Button>
                        </div>
                    </div>
                </template>
                <template v-slot:right>
                    <Button className="--primary --small --wider headbar-fix" v-if="$store.getters.hasRole('agent') === false"
                            :class="{spinner: is_saving}" :onclick="save">
                        {{ $t('save') }}
                    </Button>
                </template>
            </Headbar>
            <main>
                <Form class="form" :disabled="!$store.getters.hasPermission('update users') || is_saving">
                    <SectionHeader :title="$t('profile.personal_information')"></SectionHeader>
                    <div class="form-body">
                        <FormGroupThree>
                            <FormInputText v-model="$v.user.name.$model" identifier="name" :label="$t('users.name')"
                                           :placeholder="$t('users.name')" :disabled="is_saving"
                                           :has-error="$v.user.name.$error" :maxlength="10">
                                <template v-slot:errors>
                                    <p v-if="!$v.user.name.required">
                                        {{ $t('validation.x_is_required', {x: $t('users.name')}) }}
                                    </p>
                                </template>
                            </FormInputText>
                            <FormInputText v-model="$v.user.company.$model" identifier="company"
                                           :label="$t('users.company')"
                                           :placeholder="$t('users.company')" :disabled="is_saving"
                                           :has-error="$v.user.company.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.user.company.required">
                                        {{ $t('validation.x_is_required', {x: $t('users.company')}) }}
                                    </p>
                                </template>
                            </FormInputText>
                            <FormInputText v-model="$v.user.phone.$model" identifier="phone"
                                           :label="$t('users.contact_number')"
                                           :placeholder="$t('users.contact_number')" :disabled="is_saving"
                                           :has-error="$v.user.phone.$error" class="mobile-number">
                                <template v-slot:errors>
                                    <p v-if="!$v.user.phone.required">
                                        {{ $t('validation.x_is_required', {x: $t('users.contact_number')}) }}
                                    </p>
                                </template>
                            </FormInputText>
                        </FormGroupThree>
                        <FormGroupTwo>
                            <FormInputText v-model="$v.user.email.$model" identifier="email"
                                           :label="$t('users.email_address')"
                                           :placeholder="$t('users.email')" :disabled="is_saving"
                                           :has-error="$v.user.email.$error" autocomplete="off" class="email-address">
                                <template v-slot:errors>
                                    <p v-if="!$v.user.email.required">
                                        {{ $t('validation.x_is_required', {x: $t('users.email')}) }}
                                    </p>
                                    <p v-else-if="!$v.user.email.email">
                                        {{ $t('auth.enter_valid_email') }}
                                    </p>
                                </template>
                            </FormInputText>

                            <FormInputText v-model="$v.user.vat_num.$model" identifier="vat_number"
                                           :label="$t('users.vat_number')"
                                           :placeholder="$t('users.vat_number')" :disabled="is_saving"
                                           class="vat-number"
                                           :has-error="$v.user.vat_num.$error" autocomplete="off">
                                <template v-slot:errors>
                                    <p v-if="!$v.user.vat_num.required">
                                        {{ $t('validation.x_is_required', {x: $t('users.vat_number')}) }}
                                    </p>
                                </template>
                            </FormInputText>
                        </FormGroupTwo>
                    </div>
                </Form>
                <Form class="form" :disabled="!$store.getters.hasPermission('update users') || is_saving">
                    <SectionHeader :title="$t('profile.address_information')"></SectionHeader>
                    <div class="form-body">
                        <FormInputText v-model="$v.user.address.$model" identifier="address"
                                       :label="$t('users.address')"
                                       :placeholder="$t('users.address')" :disabled="is_saving"
                                       :has-error="$v.user.address.$error" autocomplete="off"
                                       :useTextarea="true" class="address" :largeTextarea="true">
                            <template v-slot:errors>
                                <p v-if="!$v.user.address.required">
                                    {{ $t('validation.x_is_required', {x: $t('users.address')}) }}
                                </p>
                            </template>
                        </FormInputText>
                    </div>
                </Form>
                <Form class="form" :disabled="!$store.getters.hasPermission('update users') || is_saving">
                    <SectionHeader :title="$t('profile.change_password')"></SectionHeader>
                    <div class="form-body">
                        <FormGroupTwo>
                            <FormInputPassword v-model="$v.user.password.$model" identifier="password"
                                               :label="$t('users.password')"
                                               :placeholder="$t('users.password')" :disabled="is_saving" type="password"
                                               :has-error="$v.user.password.$error" autocomplete="off">
                                <template v-slot:errors>
                                    <p v-if="!$v.user.password.required">
                                        {{ $t('validation.x_is_required', {x: $t('users.password')}) }}
                                    </p>
                                </template>
                            </FormInputPassword>
                        </FormGroupTwo>

                    </div>
                </Form>
            </main>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import Headbar from "../components/headbar/Headbar";
import Form from "../components/form/Form";
import SectionHeader from "../components/SectionHeader";
import Button from "../components/Button";
import FormInputText from "../components/form/FormInputText";
import FormInputSelect from "../components/form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import FormGroupTwo from "../components/form/FormGroupTwo";
import FormGroupThree from "../components/form/FormGroupThree";
import FormInputPassword from "../components/form/FormInputPassword";
import FormInputTextSelect from "../components/form/FormInputTextSelect";


export default {
    name: "users-update-page",
    components: {
        FormGroupTwo,
        FormGroupThree,
        FormInputText,
        FormInputPassword,
        FormInputSelect,
        Button,
        SectionHeader,
        Form,
        Headbar,
        FormInputTextSelect,
    },
    data() {
        return {
            original: null,
            user: {
                name: null,
                company: null,
                phone: null,
                email: null,
                address: null,
                password: null,
                role: null,
                vat_num: null,
            },
            is_saving: false,
            roleOptions: [],
            is_loading_roles: false,
            is_loading_original: false,
        }
    },
    validations: {
        user: {
            name: {required},
            company: {required},
            phone: {required},
            email: {required, email},
            address: {required},
            password: {},
            role: {required},
            vat_num: {},
        }
    },
    methods: {
        populate() {
            if (!this.original || !this.user)
                return;

            this.$v.user.name.$model = this.original.attributes.name;
            this.$v.user.company.$model = this.original.attributes.company;
            this.$v.user.phone.$model = this.original.attributes.phone;
            this.$v.user.email.$model = this.original.attributes.email;
            this.$v.user.vat_num.$model = this.original.attributes.vat_num;
            this.$v.user.address.$model = this.original.attributes.address;
            this.$v.user.role.$model = _.find(this.roleOptions, {id: this.original.relationships.roles.data[0].id});
        },
        async retrieveOriginalUser() {
            this.is_loading_original = false;

            await this.$axios.get(`profile`)
                .then(({data}) => {
                    this.is_loading_original = false;
                    this.original = data.data;
                })
                .catch(e => {
                    this.is_loading_original = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('users.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveRoleOptions() {
            this.is_loading_roles = false;
            await this.$axios.get('roles/list')
                .then(({data}) => {
                    this.roleOptions = data.data;
                    this.is_loading_roles = false;
                })
                .catch(e => {
                    this.is_loading_roles = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('roles.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        save() {
            this.$v.user.$touch();
            if (this.$v.user.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = {};

            payload.name = this.$v.user.name.$model;
            payload.company = this.$v.user.company.$model;
            payload.phone = this.$v.user.phone.$model;
            payload.address = this.$v.user.address.$model;

            if (this.original.attributes.email !== this.$v.user.email.$model)
                payload.email = this.$v.user.email.$model;

            if (this.original.attributes.vat_num !== this.$v.user.vat_num.$model)
                payload.vat_num = this.$v.user.vat_num.$model;

            if (this.$v.user.role.$model)
                payload.roles = [this.$v.user.role.$model.id];

            if (this.$v.user.password.$model) {
                payload.password = this.$v.user.password.$model;
            }

            this.$axios.patch(`profile/`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('profile.success_updated'),
                    type: 'success',
                });


                this.$store.dispatch('getUser');

                this.is_saving = false;
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('profile.error_update')),
                    type: 'error',
                });
            });
        },
    },

    computed: {
        isAgent() {
            return this.$store.getters.hasRole('agent')
        }
    },

    async mounted() {
        await this.retrieveOriginalUser();
        await this.retrieveRoleOptions();
        this.populate();
    },

    head() {
        return {
            title: {
                inner: this.$t('profile.user_account')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {

    &.agent-profile {
        @apply flex bg-white h-full px-8;

        .agent-profile-container {
            @apply mx-auto max-w-3xl;

            @screen xl{
                @apply max-w-6xl;
            }

            .headbar-container {
                @apply border-0 px-0 pb-0 pt-5;
            }

            main {
                @apply px-0;

                & > .form {
                    @apply border border-grey-light rounded-md;

                    &:first-of-type {
                        @apply ml-0 mr-0;
                    }

                    &:nth-last-of-type(2) {
                        @apply ml-auto;
                    }

                    &:last-of-type {
                        @apply ml-0;
                    }
                }
            }

            ::v-deep .left {
                @apply w-full;
            }

            .headbar {
                @apply flex flex-col w-full;
            }

            .agent-header {
                @apply flex flex-row items-center justify-between mb-4 mt-1 flex-wrap;

                & > h1 {
                    @apply font-bold text-2xl my-auto ml-16;

                    @screen sm {
                        @apply ml-32;
                    }
                }

                .home-button {
                    @apply text-primary text-3xl cursor-pointer bg-white;
                }

                & > button {
                    @apply mt-4;

                    @screen sm {
                        @apply mt-0;
                    }
                }
            }
        }
    }

    .headbar-fix {
        @apply my-0.5;
    }

    main {
        @apply flex flex-row flex-wrap;

        .form {
            @apply mb-8 w-full max-w-3xl mx-auto;

            @screen xl {
                width: calc(50% - 1rem);

                &:nth-child(odd) {
                    @apply mx-4;
                }

                &:nth-child(even) {
                    @apply ml-4 mr-0;
                }
            }

            .form-body {
                @apply px-8 pt-7 pb-2.5;
            }

            .mobile-number {
                @apply w-full;

                @screen xl {
                    @apply w-1/3;
                }
            }

            .email-address, .address {
                @apply w-full;

                @screen xl {
                    width: 66.75%;
                }
            }

            .vat-number {
                @apply w-full;
                @screen xl {
                    width: 33.25%;
                }
            }
        }
    }
}
</style>