<template>
  <div :class="{'error': item.attributes.is_unavailable}" class="item-card">
    <div class="card-title-container">
      <p class="card-title">{{ item.attributes.name }} - €{{ item.attributes.value }}</p>
      <div
          v-if="(!actions_only_on_error || (actions_only_on_error && item.attributes.is_unavailable)) && !dont_show_icons"
          class="actions-container">
        <font-awesome-icon v-if="!dont_show_clone" :icon="['fal', 'clone']" class="action" @click="cloneItem(item)"/>
        <font-awesome-icon v-if="!dont_show_edit" :icon="['fal', 'pencil']" class="action" @click="pushToEdit"/>
        <font-awesome-icon v-if="!dont_show_delete" :icon="['far', 'times-circle']" class="action delete-icon"
                           @click="toggleDelete(item)"/>
      </div>
    </div>

    <div v-if="item.attributes.options[0].type" class="multiple-section-container">
      <div class="section-container">
        <p class="section-title">Type</p>
        <p class="section-info">{{ item.attributes.options[0].type }}</p>
      </div>
    </div>

    <div class="multiple-section-container">
      <div v-if="item.attributes.options[0].width" class="section-container">
        <p class="section-title">Aperture Width</p>
        <p class="section-info">{{ item.attributes.options[0].width }} cm</p>
      </div>
      <div v-if="item.attributes.options[0].drop" class="section-container">
        <p class="section-title">Aperture Drop</p>
        <p class="section-info">{{ item.attributes.options[0].drop }} cm</p>
      </div>
      <div v-if="item.attributes.options[0].blind_type" class="section-container">
        <p class="section-title">Blind Type</p>
        <p class="section-info">{{ item.attributes.options[0].blind_type }}</p>
      </div>
      <div v-if="item.attributes.options[0].aperture_type" class="section-container">
        <p class="section-title">Aperture Type</p>
        <p class="section-info capitalize">{{ item.attributes.options[0].aperture_type }}</p>
      </div>
    </div>

    <div v-if="item.attributes.options[0].fixing_type" class="multiple-section-container">
      <div v-if="item.attributes.options[0].fixing_type" class="section-container">
        <p class="section-title">Fixing Type</p>
        <p class="section-info">{{ $t(`calculators.${item.attributes.options[0].fixing_type}`) }}</p>
      </div>
    </div>

    <div v-if="item.attributes.options[0].finish || item.attributes.options[0].finish_style"
         class="multiple-section-container">
      <div v-if="item.attributes.options[0].finish" class="section-container">
        <p class="section-title">Finish</p>
        <p class="section-info">{{ item.attributes.options[0].finish }}</p>
      </div>
      <div v-if="item.attributes.options[0].finish_style" class="section-container">
        <p class="section-title">Finish Style</p>
        <p class="section-info">{{ item.attributes.options[0].finish_style }}</p>
      </div>
    </div>

    <div v-if="(item.attributes.options[0].recess_type || item.attributes.options[0].overlap_width || item.attributes.options[0].overlap_drop || item.attributes.options[0].width_clearance)" class="multiple-section-container">
      <div v-if="item.attributes.options[0].recess_type" class="section-container">
        <p class="section-title">Recess Type</p>
        <p class="section-info">{{ item.attributes.options[0].recess_type }}</p>
      </div>
      <div v-if="item.attributes.options[0].overlap_width" class="section-container">
        <p class="section-title">Overlap Width</p>
        <p class="section-info">{{ item.attributes.options[0].overlap_width }} cm</p>
      </div>
      <div v-if="item.attributes.options[0].overlap_drop" class="section-container">
        <p class="section-title">Overlap Drop</p>
        <p class="section-info">{{ item.attributes.options[0].overlap_drop }} cm</p>
      </div>
      <div v-if="item.attributes.options[0].width_clearance" class="section-container">
        <p class="section-title">{{ $t('calculators.width_clearance') }}</p>
        <p class="section-info">{{ item.attributes.options[0].width_clearance }}</p>
      </div>
    </div>

    <div v-if="item.attributes.options[0].chain_side ||
    item.attributes.options[0].chain_type ||
    item.attributes.options[0].cord_side ||
    item.attributes.options[0].wand_side ||
    item.attributes.options[0].slat_qty ||
    item.attributes.options[0].runner_qty ||
    (item.attributes.type.includes('vertical') && item.attributes.options[0].controls_side || item.attributes.options[0].opening_type)"
         class="multiple-section-container">
      <div v-if="item.attributes.options[0].slat_qty" class="section-container">
        <p class="section-title">Slat Qty</p>
        <p class="section-info">{{ item.attributes.options[0].slat_qty }}</p>
      </div>
      <div v-if="item.attributes.options[0].runner_qty" class="section-container">
        <p class="section-title">Runner Qty</p>
        <p class="section-info">{{ item.attributes.options[0].runner_qty }}</p>
      </div>
      <div v-if="item.attributes.options[0].chain_side" class="section-container">
        <p class="section-title">Chain Side</p>
        <p class="section-info">{{ item.attributes.options[0].chain_side }}</p>
      </div>
      <div v-if="item.attributes.options[0].chain_type" class="section-container">
        <p class="section-title">Chain Type</p>
        <p class="section-info">{{ item.attributes.options[0].chain_type }}</p>
      </div>
      <div v-if="item.attributes.options[0].cord_side" class="section-container">
        <p class="section-title">Cord Side</p>
        <p class="section-info">{{ item.attributes.options[0].cord_side }}</p>
      </div>
      <div v-if="item.attributes.options[0].wand_side" class="section-container">
        <p class="section-title">Wand Side</p>
        <p class="section-info">{{ item.attributes.options[0].wand_side }}</p>
      </div>
      <template v-if="item.attributes.type.includes('vertical')">
        <div v-if="item.attributes.options[0].controls_side" class="section-container">
          <p class="section-title">Controls Side</p>
          <p class="section-info">{{ item.attributes.options[0].controls_side }}</p>
        </div>
        <div v-if="item.attributes.options[0].opening_type" class="section-container">
          <p class="section-title">Opening Type</p>
          <p class="section-info">{{ item.attributes.options[0].opening_type }}</p>
        </div>
      </template>
    </div>

    <div v-if="!item.attributes.type.includes('vertical') && (item.attributes.options[0].controls_side || item.attributes.options[0].control_type || item.attributes.options[0].opening_type)"
         class="multiple-section-container">
      <div v-if="item.attributes.options[0].control_type" class="section-container">
        <p class="section-title">Control Type</p>
        <p class="section-info">{{ item.attributes.options[0].control_type }}</p>
      </div>
      <div v-if="item.attributes.options[0].controls_side" class="section-container">
        <p class="section-title">Controls Side</p>
        <p class="section-info">{{ item.attributes.options[0].controls_side }}</p>
      </div>
      <div v-if="item.attributes.options[0].opening_type" class="section-container">
        <p class="section-title">Opening Type</p>
        <p class="section-info">{{ item.attributes.options[0].opening_type }}</p>
      </div>
    </div>

    <div v-if="item.attributes.options[0].tile_cut_width || item.attributes.options[0].tile_cut_height || (item.attributes.options[0].tile_cut_width_clearance && isRollerOrZebra)"
         class="multiple-section-container">
      <div v-if="item.attributes.options[0].tile_cut_width" class="section-container">
        <p class="section-title">Tile Cut Width</p>
        <p class="section-info">{{ item.attributes.options[0].tile_cut_width }} cm</p>
      </div>
      <div v-if="item.attributes.options[0].tile_cut_height" class="section-container">
        <p class="section-title">Tile Cut Height</p>
        <p class="section-info">{{ item.attributes.options[0].tile_cut_height }} cm</p>
      </div>
      <div v-if="item.attributes.options[0].tile_cut_width_clearance && isRollerOrZebra" class="section-container">
        <p class="section-title">Tile Cut Width Clearance</p>
        <p class="section-info">{{ item.attributes.options[0].tile_cut_width_clearance }}</p>
      </div>
    </div>

    <div v-if="item.attributes.options[0].tile_cut_width_clearance && !isRollerOrZebra" class="multiple-section-container">
      <div class="section-container">
        <p class="section-title">Tile Cut Width Clearance</p>
        <p class="section-info">{{ item.attributes.options[0].tile_cut_width_clearance }}</p>
      </div>
    </div>

    <div v-if="item.attributes.options[0].bottom_bar" class="multiple-section-container half">
      <div v-if="item.attributes.options[0].bottom_bar" class="section-container">
        <p class="section-title">Bottom Bar</p>
        <p class="section-info">{{ item.attributes.options[0].bottom_bar }}</p>
      </div>
    </div>

    <div
        v-if="item.attributes.options[0].fabric_type || item.attributes.options[0].fabric_colour || item.attributes.options[0].fabric_colour_options || item.attributes.options[0].top_tube_fabric_roll"
        class="multiple-section-container">
      <div v-if="item.attributes.options[0].fabric_type" class="section-container">
        <p class="section-title">Fabric Type</p>
        <p class="section-info">{{ item.attributes.options[0].fabric_type }}</p>
      </div>

      <div v-if="item.attributes.options[0].fabric_colour" class="section-container">
        <p class="section-title" v-if="!item.attributes.type.includes('venetian')">Fabric Colour</p>
        <p class="section-title" v-else>Slat Colour</p>

        <p class="section-info">{{ item.attributes.options[0].fabric_colour }}</p>
      </div>

      <div v-if="item.attributes.options[0].wind_stopper_set && item.attributes.type.includes('venetian')" class="section-container">
        <p class="section-title">Wind Stopper Set</p>
        <p class="section-info">{{ item.attributes.options[0].wind_stopper_set }}</p>
      </div>

      <div v-if="item.attributes.options[0].fabric_colour_options" class="section-container">
        <p class="section-title">Fabric Options</p>
        <p class="section-info">{{ item.attributes.options[0].fabric_colour_options }}</p>
      </div>
      <div v-if="item.attributes.options[0].top_tube_fabric_roll" class="section-container pl-6">
        <p class="section-title">Fabric Roll</p>
        <p class="section-info">{{ item.attributes.options[0].top_tube_fabric_roll }}</p>
      </div>
    </div>

    <div v-for="colour in item.attributes.options[0].fabric_colours_selected"
         v-if="item.attributes.options[0].fabric_colours_selected.length"
         class="multiple-section-container half">
      <div class="section-container">
        <p class="section-title">Fabric Colour</p>
        <p class="section-info">{{ colour.name }}</p>
      </div>

      <div v-if="item.attributes.options[0].fabric_colour_options == 'Two Colour' && colour.first_outer_slat_qty > 0"
           class="section-container">
        <p class="section-title">Outer Colour Slat Qty</p>
        <p class="section-info">{{ colour.first_outer_slat_qty }}R / {{ colour.first_outer_slat_qty }}L</p>
      </div>

      <div v-if="item.attributes.options[0].fabric_colour_options == 'Two Colour' && colour.middle_slat_qty > 0"
           class="section-container">
        <p class="section-title">Middle Colour Slat Qty</p>
        <p class="section-info">{{ colour.middle_slat_qty }}</p>
      </div>

      <div v-if="item.attributes.options[0].fabric_colour_options == 'Three Colour' && colour.first_outer_slat_qty > 0"
           class="section-container">
        <p class="section-title">First Outer Colour Slat Qty</p>
        <p class="section-info">{{ colour.first_outer_slat_qty }}R / {{ colour.first_outer_slat_qty }}L</p>
      </div>

      <div v-if="item.attributes.options[0].fabric_colour_options == 'Three Colour' && colour.second_outer_slat_qty > 0"
           class="section-container">
        <p class="section-title">Second Outer Colour Slat Qty</p>
        <p class="section-info">{{ colour.second_outer_slat_qty }}R / {{ colour.second_outer_slat_qty }}L</p>
      </div>

      <div v-if="item.attributes.options[0].fabric_colour_options == 'Three Colour' && colour.middle_slat_qty > 0"
           class="section-container">
        <p class="section-title">Middle Colour Slat Qty</p>
        <p class="section-info">{{ colour.middle_slat_qty }}</p>
      </div>
    </div>

    <div v-if="item.attributes.options[0].fabric_colour_zx|| item.attributes.options[0].fabric_colour_dt"
         class="multiple-section-container half">
      <div v-if="item.attributes.options[0].fabric_colour_zx" class="section-container">
        <p class="section-title">Fabrics & Colours - ZX</p>
        <p class="section-info">{{ item.attributes.options[0].fabric_colour_zx }}</p>
      </div>

      <div v-if="item.attributes.options[0].fabric_colour_dt" class="section-container">
        <p class="section-title">Fabrics & Colours - DT</p>
        <p class="section-info">{{ item.attributes.options[0].fabric_colour_dt }}</p>
      </div>
    </div>

    <div v-if="item.attributes.options[0].custom_colour_code" class="multiple-section-container">
      <div class="section-container">
        <p class="section-title">Custom Colour Code</p>
        <p class="section-info">{{ item.attributes.options[0].custom_colour_code }}</p>
      </div>
    </div>

    <div
        v-if="item.attributes.options[0].motor || item.attributes.options[0].controller_type || item.attributes.options[0].controller_types"
        class="multiple-section-container half">
      <div v-if="item.attributes.options[0].motor" class="section-container">
        <p class="section-title">Motor</p>
        <p class="section-info">{{ item.attributes.options[0].motor }}</p>
      </div>

      <div v-if="item.attributes.options[0].controller_type" class="section-container">
        <p class="section-title">Controller Type</p>
        <p class="section-info">{{ item.attributes.options[0].controller_type }}</p>
      </div>

      <div v-if="item.attributes.options[0].controller_types" class="section-container">
        <p class="section-title">Controller Types</p>
        <p v-for="(controller, index) in item.attributes.options[0].controller_types" :class="[index === item.attributes.options[0].controller_types.length - 1 ? '' : 'pb-2']" class="section-info">{{ controller.attributes.name }}</p>
      </div>
    </div>

    <div v-if="item.attributes.note" class="divider"/>

    <div v-if="item.attributes.note" class="multiple-section-container">
      <div class="section-container">
        <p class="section-title">Note</p>
        <p class="section-info">{{ item.attributes.note }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmModal from "@/components/modal/ConfirmModal";
import _ from "lodash";

export default {
  name: "ItemCard",
  props: {
    item: {
      type: Object,
      required: true
    },
    actions_only_on_error: {
      type: Boolean,
      required: false
    },
    cart_id: {
      type: Number,
      required: false
    },
    order_id: {
      type: Number,
      required: false
    },
    quote_id: {
      type: Number,
      required: false
    },
    is_selected: {
      type: Boolean,
      required: false,
    },
    dont_show_icons: {
      type: Boolean,
      required: false,
      default: false,
    },
    dont_show_edit: {
      type: Boolean,
      required: false,
      default: false,
    },
    dont_show_delete: {
      type: Boolean,
      required: false,
      default: false,
    },
    dont_show_clone: {
      type: Boolean,
      required: false,
      default: true,
    },
    cart: {
      type: Object,
      required: false,
      default: () => ({}),
    }
  },
  data() {
    return {
      is_deleting: false
    }
  },
  computed: {
    isRollerOrZebra() {
      return this.item.attributes.type.includes('roller') || this.item.attributes.type.includes('zebra')
    },
  },
  methods: {
    pushToEdit() {
      let query = {id: this.item.id};

      if (this.item.attributes.token) query.token = this.item.attributes.token;
      if (this.order_id) query.order = this.order_id;
      if (this.cart_id) query.cart = this.cart_id;
      if (this.quote_id) query.quote = this.quote_id;
      if (this.item.attributes.order) query.cart_order = this.item.attributes.order;

      if (this.item.attributes.type === 'zebra_blind')
        this.$router.push({name: 'zebra-blind', query: query})
      else if (this.item.attributes.type === 'zebra_blind_2')
        this.$router.push({name: 'zebra-blind-2', query: query})
      else if (this.item.attributes.type === 'roller_blind_1')
        this.$router.push({name: 'roller-blind', query: query})
      else if (this.item.attributes.type === 'roller_blind_2')
        this.$router.push({name: 'roller-blind-2', query: query})
      else if (this.item.attributes.type === 'roller_blind_3')
        this.$router.push({name: 'roller-blind-3', query: query})
      else if (this.item.attributes.type === 'vertical_90mm')
        this.$router.push({name: 'vertical-blind-90mm', query: query})
      else if (this.item.attributes.type === 'vertical_127mm')
        this.$router.push({name: 'vertical-blind-127mm', query: query})
      else if (this.item.attributes.type === 'curtain_blind')
        this.$router.push({name: 'sheer-curtain-blind', query: query})
      else if (this.item.attributes.type === 'curtain_blind_2')
        this.$router.push({name: 'sheer-curtain-blind-2', query: query})
      else if (this.item.attributes.type === 'venetian_blind')
        this.$router.push({name: 'venetian-blind', query: query})
      else if (this.item.attributes.type === 'pvc_pelmet_cover')
        this.$router.push({name: 'pvc-custom-cover', query: query})
    },
    cloneItem(item) {
      let currentOrder = 0

      if (!this.cart || !this.cart.relationships || !this.cart.relationships.blinds) return

      this.cart.relationships.blinds.data.forEach(blind => {
        if (blind.attributes.order > currentOrder)
          currentOrder = blind.attributes.order;
      })

      currentOrder += 1

      let payload = {
        order: currentOrder,
        note: item.attributes.note,
        value: item.attributes.value,
        options: [...item.attributes.options],
        is_unavailable: false
      }

      this.$axios.put(`carts/${this.$store.getters.cart}/blinds/${item.id}/attach`, payload).then(({data}) => {
        if (!this.$route.query.token) this.$store.commit('INCREMENT_CART_COUNT');

        this.$notify({
          text: this.$t('cart.item_duplicated'),
          type: 'success',
        });

        this.$emit('cloned');

        this.is_adding_item = false;
      })
    },
    toggleDelete() {
      this.$modal.show(
          ConfirmModal, {
            title: this.$t('cart.remove_blind'),
            message: this.$t('cart.are_you_sure_remove_blind'),
            confirmText: this.$t('yes'),
            cancelText: this.$t('no'),
            confirmClass: '--primary',
            cancelClass: '--secondary --outline'
          },
          {
            name: 'confirm-modal',
            adaptive: true,
            resizable: true,
            height: 'auto',
            scrollable: true,
            classes: 'modal',
          }, {
            'before-close': (e) => {
              if (e.params === true) {
                this.is_deleting = true;

                if (this.quote_id) {
                  this.$axios.put(`quotes/${this.quote_id}/blinds/detach`, {token: this.item.attributes.token})
                      .then(({data}) => {
                        if (!this.order_id)
                          this.$parent.getQuote();
                        else
                          this.$parent.getOrder();

                        this.is_deleting = false;
                      })
                      .catch(e => {
                        this.is_deleting = false;

                        this.$notify({
                          title: this.$t('error'),
                          text: this.$larerror(e.response.data, this.$t('quotes.error_remove_from_quote')),
                          type: 'error',
                        });
                      });
                } else if (this.cart_id) {
                  this.$axios.put(`carts/${this.cart_id}/blinds/detach`, {token: this.item.attributes.token})
                      .then(({data}) => {
                        this.$parent.getOrder();
                        this.is_deleting = false;
                      })
                      .catch(e => {
                        this.is_deleting = false;

                        this.$notify({
                          title: this.$t('error'),
                          text: this.$larerror(e.response.data, this.$t('cart.error_remove_from_cart')),
                          type: 'error',
                        });
                      });
                } else {
                  this.$axios.put(`carts/${this.$store.getters.cart}/blinds/detach`, {token: this.item.attributes.token})
                      .then(({data}) => {
                        this.$store.commit('DECREMENT_CART_COUNT');
                        this.$parent.getCart();
                        this.is_deleting = false;
                      })
                      .catch(e => {
                        this.is_deleting = false;

                        this.$notify({
                          title: this.$t('error'),
                          text: this.$larerror(e.response.data, this.$t('cart.error_remove_from_cart')),
                          type: 'error',
                        });
                      });
                }
              }
            }
          }
      );
    }
  }
}
</script>

<style lang="scss" scoped>
.pdf-content {
  .item-card {
    @apply pb-8 mb-4;

    .card-title-container {
      .card-title {
        @apply text-lg;
      }
    }

    .multiple-section-container {
      @apply mb-0;

      .section-container {
        @apply w-1/3;
        .section-title {
          @apply text-base;
        }

        .section-info {
          @apply text-base break-normal pr-4;
          word-break: break-word;
        }
      }
    }


    .divider {
      @apply w-0 border-0 mb-0;
    }
  }
}

.item-card {
  @apply w-full bg-white rounded-lg flex flex-col max-w-full p-6 pb-2 border-white border-3 h-full;
  box-shadow: 0px 10px 15px #00000012;

  @screen lg {
    @apply rounded-xl flex flex-col max-w-full;
  }

  &.error {
    @apply border-primary;
  }

  .card-title-container {
    @apply flex flex-row mb-2;

    .card-title {
      @apply font-bold text-base;
    }

    .actions-container {
      @apply ml-auto flex flex-row;

      .action {
        @apply text-lg ml-3 cursor-pointer text-grey-dark;

        &:hover {
          @apply text-grey-darker;
        }

        &.delete-icon {
          @apply text-primary;

          &:hover {
            @apply text-primary-over;
          }
        }
      }
    }
  }

  .multiple-section-container-wrap {
    @apply flex flex-col flex-wrap mb-2;

    @screen md {
      @apply flex-row mb-4;
    }

    &.full {
      @apply w-full;

      & > .section-container {
        @screen md {
          @apply ml-auto;
        }
      }
    }

    &.half {
      & > .section-container {
        @apply w-1/2;
      }
    }

    .section-container {
      @apply mt-4;

      @screen md {
        @apply pl-4;

        &:nth-child(n+3) {
          padding-top: 1rem;
        }

        &:nth-child(1),
        &:nth-child(2) {
          padding-top: 0;
        }
      }

      &:nth-child(odd) {
        @apply pl-0;
      }

      .section-title {
        @apply text-sm font-bold mb-1;
      }

      .section-info {
        @apply text-sm break-normal pr-4;
        word-break: break-word;
      }
    }
  }

  .multiple-section-container {
    @apply flex flex-col mb-2;

    @screen md {
      @apply flex-row mb-4;
    }

    &.full {
      @apply w-full;

      & > .section-container {
        @screen md {
          @apply w-1/3 ml-auto;
        }
      }
    }

    &.half {
      & > .section-container {
        @apply w-1/2;
      }
    }

    .section-container {
      @apply w-1/3 mt-4;

      &:first-of-type {
        @apply ml-0;
      }

      .section-title {
        @apply text-sm font-bold mb-1;
      }

      .section-info {
        @apply text-sm break-normal pr-4;
        word-break: break-word;
      }
    }
  }

  .divider {
    @apply w-full border border-grey-light mt-auto mb-4;
  }
}
</style>