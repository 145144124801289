<template>
    <div class="assign-fabric-modal">
        <ModalContainer :title="$t('blinds.add_fabric')" identifier="assign-fabric-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputSelect v-model="$v.fabric.$model" identifier="fabric"
                                 :label="$t('blinds.fabric')" :options="fabricOptions"
                                 :placeholder="$t('blinds.fabric')" :disabled="is_saving"
                                 :has-error="$v.fabric.$error" track-by="id"
                                 :display-custom-label="(row) => `${row.attributes.name} - ${row.attributes.code}`"
                                 class="select-container">
                    <template v-slot:errors>
                        <p v-if="!$v.fabric.required">
                            {{$t('validation.x_is_required',{x: $t('blinds.fabric')})}}
                        </p>
                    </template>
                </FormInputSelect>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('add')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import _ from 'lodash';
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import FormInputTextSelect from "../form/FormInputTextSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";

export default {
    name: "AssignFabricModal",
    components: {FormInputPassword, Button, FormInputSelect, FormInputTextSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    data() {
        return {
            fabric: null,
            fabricOptions: [],
            is_loading_fabrics: false,
            is_saving: false
        }
    },
    validations: {
        fabric: {required}
    },
    methods: {
        close(status) {
            this.$modal.hide('assign-fabric-modal', status);
        },
        save() {
            this.$v.fabric.$touch();
            if (this.$v.fabric.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            this.$axios.put(`blinds/${this.$route.params.id}/fabrics/${this.fabric.id}/attach`)
                .then(({data}) => {
                    this.is_saving = false;
                    this.close(true);
                })
                .catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('blinds.error_add_fabric')),
                        type: 'error',
                    });
                });
        },
        retrieveFabricOptions() {
            this.is_loading_fabrics = false;
            this.$axios.get('fabrics/list')
                .then(({data}) => {
                    this.fabricOptions = data.data;
                    this.is_loading_fabrics = false;
                })
                .catch(e => {
                    this.is_loading_fabrics = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('blinds.error_retrieve_fabrics')),
                        type: 'error',
                    });
                });
        },
    },

    mounted() {
        this.retrieveFabricOptions();
    }
}
</script>

<style lang="scss">
    .checkbox-container {
        @apply flex flex-row mb-8 mt-2;

        .pretty {
            &:last-of-type {
                @apply ml-8;
            }
        }
    }
</style>
