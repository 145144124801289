var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('Headbar',{scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',{staticClass:"select"},[_c('p',{staticClass:"label"},[_vm._v("Client")]),_c('Select',{staticClass:"select-dropdown",attrs:{"placeholder":_vm.$t('orders.company'),"custom-label":function (row) { return row.attributes.name + ' - ' + row.attributes.company; },"options":_vm.companyOptions,"allowEmpty":true,"allow-empty":true},on:{"input":_vm.onFilterUpdated},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})],1),_c('div',{staticClass:"select"},[_c('p',{staticClass:"label"},[_vm._v("Status")]),_c('Select',{staticClass:"select-dropdown",attrs:{"placeholder":_vm.$t('orders.status'),"custom-label":function (row) { return row.name; },"options":_vm.statusOptions,"allowEmpty":true,"allow-empty":true,"track-by":"name"},on:{"input":_vm.onFilterUpdated},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('Search',{staticClass:"search-desktop",attrs:{"placeholder":_vm.$t('orders.search_orders')},on:{"search":_vm.search}}),_c('button',{staticClass:"btn-search-mobile btn-icon-only",on:{"click":function($event){_vm.headbarExpanded = _vm.headbarExpanded === 'search' ? null : 'search'}}},[(_vm.headbarExpanded === 'search')?_c('font-awesome-icon',{attrs:{"icon":['far', 'times']}}):_c('font-awesome-icon',{attrs:{"icon":['far', 'search']}})],1)]},proxy:true},{key:"expanded",fn:function(){return [_c('div',{staticClass:"headbar-expanded-container"},[(_vm.headbarExpanded === 'search')?_c('Search',{attrs:{"placeholder":_vm.$t('orders.search_orders')},on:{"search":_vm.search}}):_vm._e()],1)]},proxy:true}])}),_c('main',[_c('vue-good-table',{attrs:{"mode":"remote","styleClass":"vgt-table vgt-custom","columns":_vm.columns,"rows":_vm.orders,"isLoading":_vm.is_loading_orders,"search-options":{
                enabled: false,
            },"pagination-options":{
                enabled: true,
                mode: 'records',
                dropdownAllowAll: false,
                perPage: 15,
                perPageDropdownEnabled: false,
                setCurrentPage: _vm.table_page,
                rowsPerPageLabel: _vm.$t('x_per_page', {x: _vm.$t('articles.articles')}),
            },"sort-options":{
              enabled: true,
              multipleColumns: true,
            },"totalRows":_vm.totalRecords},on:{"update:isLoading":function($event){_vm.is_loading_orders=$event},"update:is-loading":function($event){_vm.is_loading_orders=$event},"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'status')?_c('div',[(props.row.attributes.status.trim() == 'on_hold')?_c('p',{staticClass:"on-hold-badge",staticStyle:{"text-transform":"capitalize"}},[_vm._v("On Hold")]):_c('p',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(_vm.orderStatus(props.row.attributes.status.trim())))])]):(props.column.field === 'value')?_c('div',[_c('p',{staticStyle:{"text-transform":"capitalize"}},[_vm._v("€"+_vm._s(props.row.attributes.value.toFixed(2)))])]):(props.column.field === 'after')?_c('div',{staticClass:"td-after"},[(_vm.$store.getters.hasPermission('update orders'))?_c('Button',{attrs:{"className":"--secondary --outline --mini --big-text","onclick":function (){ return _vm.pushUpdate(props.row.id); }}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'pencil']}})],1):_vm._e(),(_vm.$store.getters.hasPermission('update orders'))?_c('Button',{attrs:{"className":"--secondary --outline --mini --big-text","onclick":function (){ return _vm.toggleCancel(props.row); }}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'ban']}})],1):_vm._e(),(_vm.$store.getters.hasPermission('destroy orders'))?_c('Button',{attrs:{"className":"--secondary --outline --mini --big-text","onclick":function (){ return _vm.toggleDelete(props.row); }}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'trash']}})],1):_vm._e()],1):_c('span',[_vm._v(_vm._s(props.formattedRow[props.column.field]))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }