<template>
    <div class="fabrics-create-modal">
        <ModalContainer :title="$t('fabrics.fabric_details')" identifier="fabrics-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                    <FormInputText v-model="$v.fabric.name.$model" identifier="name" :label="$t('fabrics.name')"
                                   :placeholder="$t('fabrics.name')" :disabled="is_saving"
                                   :has-error="$v.fabric.name.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.fabric.name.required">
                                {{$t('validation.x_is_required',{x: $t('fabrics.name')})}}
                            </p>
                        </template>
                    </FormInputText>
                <FormGroupTwo>
                    <FormInputText v-model="$v.fabric.code.$model" identifier="code" :label="$t('fabrics.code')"
                                   :placeholder="$t('fabrics.code')" :disabled="is_saving"
                                   :has-error="$v.fabric.code.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.fabric.code.required">
                                {{$t('validation.x_is_required',{x: $t('fabrics.code')})}}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputSelect v-model="$v.fabric.category.$model" identifier="category"
                                     :label="$t('articles.category')" :options="categoryOptions"
                                     :placeholder="$t('articles.category')" :disabled="is_saving"
                                     :has-error="$v.fabric.category.$error" track-by="id"
                                     :display-custom-label="(row) => `${row.attributes.name}`"
                                     class="select-container">
                        <template v-slot:errors>
                            <p v-if="!$v.fabric.category.required">
                                {{$t('validation.x_is_required',{x: $t('articles.category')})}}
                            </p>
                        </template>
                    </FormInputSelect>
                    <FormInputText v-model="$v.fabric.price.$model" identifier="price" :label="$t('fabrics.price') + ' (€)'"
                                   :placeholder="$t('fabrics.price')" :disabled="is_saving"
                                   :has-error="$v.fabric.price.$error" autocomplete="off">
                        <template v-slot:errors>
                            <p v-if="!$v.fabric.price.required">
                                {{$t('validation.x_is_required',{x: $t('fabrics.price')})}}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputText v-model="$v.fabric.multiplier.$model" identifier="multiplier" :label="$t('fabrics.multiplier')"
                                   :placeholder="$t('fabrics.multiplier')" :disabled="is_saving"
                                   :has-error="$v.fabric.multiplier.$error" autocomplete="off">
                        <template v-slot:errors>
                            <p v-if="!$v.fabric.multiplier.required">
                                {{$t('validation.x_is_required',{x: $t('fabrics.multiplier')})}}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputText v-model="$v.fabric.no_wastage_limit.$model" identifier="no-wastage-limit" :label="$t('fabrics.no_wastage_limit')"
                                   :placeholder="$t('fabrics.no_wastage_limit')" :disabled="is_saving"
                                   :has-error="$v.fabric.no_wastage_limit.$error" autocomplete="off">
                        <template v-slot:errors>
                            <p v-if="!$v.fabric.no_wastage_limit.required">
                                {{$t('validation.x_is_required',{x: $t('fabrics.no_wastage_limit')})}}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputText v-model="$v.fabric.wastage.$model" identifier="wastage" :label="$t('fabrics.wastage')"
                                   :placeholder="$t('fabrics.wastage')" :disabled="is_saving"
                                   :has-error="$v.fabric.wastage.$error" autocomplete="off">
                        <template v-slot:errors>
                            <p v-if="!$v.fabric.wastage.required">
                                {{$t('validation.x_is_required',{x: $t('fabrics.wastage')})}}
                            </p>
                        </template>
                    </FormInputText>
                </FormGroupTwo>

                <FormInputText v-model="$v.fabric.colors.$model" identifier="colors" :label="$t('fabrics.colors')"
                               :placeholder="$t('fabrics.colors')" :disabled="is_saving"
                               :has-error="$v.fabric.colors.$error" autocomplete="off" :useTextarea="true"
                               :largeTextarea="true">
                    <template v-slot:errors>
                        <p v-if="!$v.fabric.colors.required">
                            {{$t('validation.x_is_required',{x: $t('fabrics.colors')})}}
                        </p>
                    </template>
                </FormInputText>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import FormInputTextSelect from "../form/FormInputTextSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";
import FormInputCheck from "@/components/form/FormInputCheck";

export default {
    name: "FabricsCreateModal",
    components: {
        FormInputCheck,
        FormInputPassword, Button, FormInputSelect, FormInputTextSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    data() {
        return {
            fabric: {
                name: null,
                code: null,
                category: null,
                price: null,
                multiplier: 1,
                no_wastage_limit: null,
                wastage: null,
                colors: null
            },
            is_saving: false,
            categoryOptions: [],
            is_loading_categories: false,
        }
    },
    validations: {
        fabric: {
            name: {required},
            code: {required},
            category: {},
            price: {required},
            multiplier: {},
            no_wastage_limit: {},
            wastage: {},
            colors: {},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('fabrics-create-modal', status);
        },
        save() {
            this.$v.fabric.$touch();
            if (this.$v.fabric.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = Object.assign({}, this.$v.fabric.$model);

            if(this.fabric.category)
                payload.category_id = [this.$v.fabric.category.$model.id][0];

            if(this.fabric.colors)
                payload.colors = this.fabric.colors.split(', ');

            for (let key in payload) {
                if (payload[key] === null) {
                    delete payload[key];
                }
            }

            this.$axios.post(`fabrics`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('fabrics.success_created'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('fabrics.error_create')),
                    type: 'error',
                });
            });
        },
        retrieveCategoryOptions() {
            this.is_loading_categories = false;
            this.$axios.get('categories/list')
                .then(({data}) => {
                    this.categoryOptions = data.data;
                    this.is_loading_categories = false;
                })
                .catch(e => {
                    this.is_loading_categories = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('categories.error_retrieve')),
                        type: 'error',
                    });
                });
        },
    },

    mounted() {
        this.retrieveCategoryOptions();
    }
}
</script>

<style lang="scss" scoped>
    .checkbox-container {
        @apply flex flex-row mb-8 mt-2;

        .pretty {
            &:last-of-type {
                @apply ml-8;
            }
        }
    }
</style>
