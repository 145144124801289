export default {
    nav: {
        home: 'Home',
        reset_password: 'Reset Password',
        articles: 'Articles',
        blinds: 'Blinds',
        calculators: 'Calculators',
        cart: 'Cart',
        categories: 'Categories',
        fabrics: 'Fabrics',
        roles_permissions: 'Roles & Permissions',
        users: 'Users',
        reports: 'Reports',
        login: 'Login',
        logout: 'Log out',
        profile: 'My Profile',
        my_orders: 'My Orders',
        my_quotes: 'My Quotes',
        orders: 'Orders',
        quotes: 'Quotes',
    },
    articles: {
        actions: 'Actions',
        articles: 'articles',
        name: 'Name',
        description: 'Description',
        code: 'Code',
        category: 'Category',
        conversion_rate: 'Conversion Rate',
        price: 'Price',
        retail_price: 'Retail Price',
        shipping_cost: 'Shipping Cost',
        mark_up: 'Mark-up',
        vat: 'VAT',
        units: 'Units',
        unit: 'Unit',
        wastage: 'Wastage',
        multiplier: 'Multiplier',
        quantity: 'Quantity',
        on_width: 'On Width',
        on_drop: 'On Drop',
        ignore_when_motor_selected: 'Ignore when motor selected',
        add_article: 'Add Article',
        edit_article: 'Edit Article',
        delete_article: 'Delete Article',
        search_articles: 'Search Articles',
        update_article: 'Update Article',
        article_details: 'Article Details',
        error_retrieve: 'Failed to retrieve articles',
        error_delete: 'Failed to delete article',
        prompt_delete: 'Are you sure you want to delete this article?',
        success_created: 'Article has been created',
        error_create: 'Failed to create article',
        edit_category: 'Edit Article',
        success_updated: 'Article has been updated',
        error_update: 'Failed to update article',
        error_retrieve_units: 'Failed to retrieve units'
    },
    blinds: {
        actions: 'Actions',
        blinds: 'blinds',
        blind: 'Blind',
        users: 'Users',
        name: 'Name',
        type: 'Type',
        material_cutting_measurements: 'Material Cutting Measurements',
        measurements: 'Measurements',
        measurement_settings: 'Measurement Settings',
        top_tube_diameter: 'Top Tube Diameter',
        articles: 'Articles',
        fabrics: 'Fabrics',
        add_blind: 'Add Blind',
        edit_blind: 'Edit Blind',
        delete_blind: 'Delete Blind',
        search_blinds: 'Search Blinds',
        update_blind: 'Update Blind',
        blind_details: 'Blind Details',
        add_measurement: 'Add Measurement',
        edit_measurement: 'Edit Measurement',
        delete_measurement: 'Delete Measurement',
        search_measurements: 'Search Measurements',
        update_measurement: 'Update Measurement',
        measurement_details: 'Measurement Details',
        measurement: 'Measurement',
        error_add_measurement: 'Failed to add measurement',
        error_retrieve_measurements: 'Failed to retrieve measurements',
        with_measurements: 'With Measurements',
        without_measurements: 'Without Measurements',
        width_divider: 'Width Divider',
        article: 'Article',
        add_article: 'Add Article',
        remove_article: 'Remove Article',
        prompt_remove_article: 'Are you sure you want to remove this article?',
        remove_measurement: 'Remove Measurement',
        prompt_remove_measurement: 'Are you sure you want to remove this measurement?',
        fabric: 'Fabric',
        add_fabric: 'Add Fabric',
        remove_fabric: 'Remove Fabric',
        prompt_remove_fabric: 'Are you sure you want to remove this fabric?',
        error_retrieve: 'Failed to retrieve blinds',
        error_delete: 'Failed to delete blind',
        prompt_delete: 'Are you sure you want to delete this blind?',
        success_created: 'Blind has been created',
        error_create: 'Failed to create blind',
        success_updated: 'Blind has been updated',
        error_update: 'Failed to update blind',
        error_retrieve_articles: 'Failed to retrieve articles',
        error_retrieve_assigned_articles: 'Failed to retrieve assigned articles',
        error_retrieve_fabrics: 'Failed to retrieve fabrics',
        error_retrieve_assigned_fabrics: 'Failed to retrieve assigned fabrics',
        error_retrieve_blind_types: 'Failed to retrieve blind type options',
        error_add_article: 'Failed to add article',
        error_remove_article: 'Failed to remove article',
    },
    measurements: {
        back_bar: 'Back Bar',
        top_tube: 'Top Tube',
        brackets: 'Brackets',
        bottom_bar: 'Bottom Bar',
        fabric: 'Fabric',
        curtain_fabric: 'Curtain Fabric',
        fabric_cutting_clearance: 'Fabric Cutting Clearance',
        alu_back_bar_cutting_clearance: 'Alu Back Bar Cutting Clearance',
        alu_cover_cutting_clearance: 'Alu Cover Cutting Clearance',
        top_tube_cutting_clearance: 'Top Tube Gear Cutting Clearance',
        top_tube_batt_motor_cutting_clearance: 'Top Tube Batt Motor Cutting Clearance',
        top_tube_electric_motor_cutting_clearance: 'Top Tube Electric Motor Cutting Clearance',
        plastic_bottom_cutting_clearance: 'Plastic Bottom Cutting Clearance',
        aluminium_bottom_cutting_clearance: 'Aluminium Bottom Cutting Clearance',
        alum_outer_bottom_cutting_clearance: 'Alum Outer Bottom Cutting Clearance',
        alum_inner_bottom_cutting_clearance: 'Alum Inner Bottom Cutting Clearance',
        alum_rail_cutting_clearance: 'Alum Rail Cutting Clearance',
        three_way_tilt_rod_cutting_length: '3 Way Tilt Rod Cutting Length',
        overlap_width: 'Overlap Width',
        overlap_drop: 'Overlap Drop',
        inside_recess_clearance: 'Inside Recess Clearance',
        top_tube_fabric_wrap: 'Top Tube Fabric Wrap',
        bottom_bar_fabric_wrap: 'Bottom Bar Fabric Wrap',
        gear_and_top_tube: 'Gear / Top Tube Default',
        gear_and_top_tube_optional: 'Gear / Top Tube Optional',
        cover: 'Cover',
        metal_head_rail_cutting_clearance: 'Metal Head Rail Cutting Clearance',
        metal_bottom_cutting_clearance: 'Metal Bottom Cutting Clearance',
        wind_stop_set_cutting_clearance: 'Wind Stop Set Cutting Clearance',
    },
    fabrics: {
        add_fabric: 'Add Fabric',
        fabrics: 'fabrics',
        name: 'Name',
        code: 'Code',
        colors: 'Colors',
        price: 'Price',
        unit: 'Unit',
        wastage: 'Wastage (%)',
        no_wastage_limit: 'No wastage limit (cm)',
        multiplier: 'Multiplier',
        actions: 'Actions',
        delete_fabric: 'Delete Fabric',
        search_fabrics: 'Search Fabrics',
        edit_fabric: 'Edit Fabric',
        fabric_details: 'Fabric Details',
        error_retrieve: 'Failed to retrieve fabrics',
        prompt_delete: 'Are you sure you want to delete this fabric?',
        error_delete: 'Failed to delete fabric',
        success_created: 'Fabric has been created',
        success_updated: 'Fabric has been updated',
    },
    audits: {
        audits: 'Audits',
        user: 'User',
        event: 'Event',
        date_n_time: 'Date & Time',
        values: 'Values',
        old_values:'Old Values',
        new_values:'New Values',
        updated: 'Updated',
        created: 'Created',
        error_retrieve_audits: 'Failed to retrieve audits',
    },
    categories:{
        actions: 'Actions',
        categories: 'categories',
        name: 'Name',
        add_category: 'Add Category',
        search_categories: 'Search Categories',
        error_retrieve: 'Failed to retrieve categories',
        error_delete: 'Failed to delete category',
        prompt_delete: 'Are you sure you want to delete this category?',
        success_created: 'Category has been created',
        error_created: 'Failed to create category',
        edit_category: 'Edit Category',
        success_updated: 'Category has been updated',
        error_update: 'Failed to update category',
        category_details: 'Category Details',
        delete_category: 'Delete Category',
    },
    orders: {
        search_orders: 'Search Orders',
        update_order: 'Update Order',
        order_id: 'Order ID',
        order_details: 'Order Details',
        status: 'Status',
        value: 'Value',
        contact: 'Contact',
        company: 'Company',
        sales_person: 'Sales Person',
        date_time: 'Date & Time',
        actions: 'Actions',
        client: 'Client',
        message: 'Message',
        price_breakdown: 'Price Breakdown',
        order_cost_summary: 'Order Cost Summary',
        message_client: 'Message Client',
        message_cant_be_empty: 'Message can\'t be empty',
        cancel_order: 'Cancel Order',
        delete_order: 'Delete Order',
        prompt_cancel: 'Are you sure you want to cancel this order?',
        prompt_delete: 'Are you sure you want to delete this order?',
        error_retrieve: 'Failed to retrieve orders',
        error_cancel: 'Failed to cancel order',
        success_updated: 'Successfully updated order',
        error_message_sent: 'Failed to send message',
        success_message_sent: 'Message successfully sent',
        error_retrieve_statuses: 'Failed to retrieve statuses',
        error_retrieve_companies: 'Failed to retrieve companies',
        must_select_item_for_on_hold: 'You must select at least 1 item',
        blind_availability_updated: 'Blind availability updated',
        message_must_be_sent: 'Message must be sent',
        error_delete_order: 'Failed to delete order'
    },
    profile: {
        profile: 'profile',
        user_account: 'User Account',
        personal_information: 'Personal Information',
        address_information: 'Address Information',
        change_password: 'Change Password',
        success_updated: 'Your Account has been updated',
        error_update: 'Failed to update profile',
        my_profile: 'My Profile',
    },
    calculators: {
        add_to_cart: 'Add To Cart',
        add_to_quote: 'Add To Quote',
        update: 'Update',
        cart: 'Cart',
        quantity: 'Quantity',
        checkout: 'Checkout',
        top_tube_fabric_roll: 'Top Tube Fabric Roll',
        choose_your_blind: 'Choose your blind',
        choose_roller_blind_size: 'Choose roller blind size',
        choose_vertical_blind_slat_size: 'Choose vertical blind slat size',
        choose_zebra_blind_size: 'Choose zebra blind size',
        choose_sheer_curtain_blind_size: 'Choose curtain blind size',
        empty_cart: 'Your cart is currently empty. Return home to add blinds.',
        home: 'Home',
        place_order: 'Place Order',
        zebra_blind_generic: 'Zebra Blind',
        zebra_blind: 'Zebra Blind 20 - 183cm',
        zebra_blind_2: 'Zebra Blind 184 - 300cm',
        measurements: 'Measurements',
        width: 'Width',
        drop: 'Drop',
        overlap_width: 'Overlap Width',
        overlap_drop: 'Overlap Drop',
        outside_overlap_width: 'Outside Overlap Width',
        outside_overlap_drop: 'Outside Overlap Drop',
        aperture_width: 'Aperture Width',
        wind_stopper_set: 'Wind Stopper Set',
        aperture_drop: 'Aperture Drop',
        aperture_type: 'Aperture Type',
        recess_type: 'Recess Type',
        clearance: 'Clearance',
        width_clearance: 'Recess Width Clearance',
        inside_recess_width_clearance: 'Inside Recess Width Clearance',
        tile_cut_width: 'Tile Cut Width',
        tile_cut_height: 'Tile Cut Height',
        tile_cut_width_clearance: 'Tile Cut Width Clearance',
        chain_side: 'Chain Side',
        chain_type: 'Chain Type',
        controls_side: 'Controls Side',
        note: 'Note',
        fabrics: 'Fabrics',
        slats: 'Slats',
        colours: 'Colours',
        fabric_colours: 'Fabric Colours',
        fabric_colour_options: 'Fabric Colour Options',
        slat_colours: 'Slat Colours',
        custom_colour_code: 'Custom Colour Code',
        fixing_type: 'Fixing Type',
        oval_pvc_bottom_bar: 'PVC Bottom Bar',
        round_aluminium_bottom_bar: 'Aluminium Bottom Bar',
        aluminium_cover: 'Aluminum Cover',
        aluminium_back_bar: 'Aluminium Back Bar',
        brackets: 'Brackets',
        options: 'Options',
        roller_blind: 'Roller Blind',
        roller_blind_1: 'Roller 0 - 183cm',
        roller_blind_2: 'Roller 184 - 240cm',
        roller_blind_3: 'Roller 241- 300cm',
        sheer_curtain_blind_generic: 'Curtain Blind',
        sheer_curtain_blind: 'Sheer Curtain Blind Small',
        sheer_curtain_blind_2: 'Sheer Curtain Blind Large',

        vertical_blind: 'Vertical Blind',
        vertical_blind_90mm: 'Vertical Blind - 90mm Slat',
        vertical_blind_127mm: 'Vertical Blind - 127mm Slat',
        venetian_blind_25mm: 'Venetian Blind 25mm',
        pvc_custom_cover: 'PVC Custom Cover',
        control_type: 'Control Type',
        opening_type: 'Opening Type',
        cord_side: 'Cord Side',
        wand_side: 'Wand Side',
        fabric_colours_zx: 'Fabrics & Colours - ZX',
        fabric_colours_dt: 'Fabrics & Colours - DT',
        slat_qty: 'Slat Qty',
        left_slat_qty: 'Left Slat Qty',
        right_slat_qty: 'Right Slat Qty',
        type: 'Type',
        fabric_type: 'Fabric Type',
        aluminium_slat_colours: 'Aluminium Slat Colours',
        colour_slat_qty_position: 'Colour(s) Slat Quantity and Position',
        blind_type: 'Blind Type',
        finish: 'Finish',
        fabric: 'Fabric',
        internal_width: 'Internal Width',
        fabric_required: 'Fabric is required',
        fabric_zx_required: 'Fabric ZX is required',
        fabric_dt_required: 'Fabric DT is required',
        finish_style_required: 'Finish style is required',
        fabric_colour_required: 'Fabric colour is required',
        bottom_bar_required: 'Bottom bar is required',
        control_type_required: 'Control type is required',
        opening_type_required: 'Opening type is required',
        fixing_type_required: 'Fixing type option is required',
        no_calculators_assigned: 'No calculators assigned',
        error_failed_retrieve_blinds: 'Failed to retrieve blinds',
        error_failed_retrieve_blind: 'Failed to retrieve blind',
        error_failed_retrieve_fabrics: 'Failed to retrieve fabrics',
        error_failed_retrieve_articles: 'Failed to retrieve articles',
        pvc_internal_width_info: 'Internal Width of Pelmet is the External Size of Blind including Fixing Brackets with 5mm clearance',
        two_fabric_colours_required: 'Two fabric colours are required',
        three_fabric_colours_required: 'Three fabric colours are required',
        fabric_colour_options_required: 'Fabric colour slat quantity and options are required',
        outer_slat_qty_cannot_be_zero: 'Outer Colour slat quantity cannot be zero',
        first_outer_slat_qty_cannot_be_zero: 'First Outer Colour slat quantity cannot be zero',
        second_outer_slat_qty_cannot_be_zero: 'Second Outer Colour slat quantity cannot be zero',
        fabric_colour_slat_options_cannot_be_larger_than_total: 'Fabric colour slat options quantity total cannot be larger than the total slat quantity',
    },
    cart: {
        cart: 'Cart',
        save_as_quote: 'Save As Quote',
        checkout: 'Checkout',
        empty_cart_text: 'Your cart is currently empty. Return home to add blinds.',
        remove_blind: 'Remove Blind',
        are_you_sure_remove_blind: 'Are you sure you want to remove this blind?',
        place_order: 'Place Order',
        billing_details: 'Billing Details',
        client_invoicing_details: 'Client Invoicing Details',
        agent_invoicing_details: 'Agent Invoicing Details',
        sales_person: 'Sales Person',
        price_breakdown: 'Price Breakdown',
        order_cost_summary: 'Order Cost Summary',
        sub_total_1: 'Sub Total 1',
        sub_total_2: 'Sub Total 2',
        deposit: 'Deposit',
        installation: 'Installation',
        discount: 'Discount',
        balance: 'Balance',
        balance_inc_vat: 'Balance Inc. VAT',
        note: 'Note',
        order_placed_successfully: 'Order Placed Successfully',
        my_orders: 'My Orders',
        cancel_order: 'Cancel Order',
        are_you_sure_cancel_order: 'Are you sure you want to cancel this order?',
        order_details: 'Order Details',
        quote_details: 'Quote Details',
        item_added_to_cart: 'Item has been added to cart!',
        items_added_to_cart: 'Items have been added to cart!',
        item_duplicated: 'Item has been duplicated!',
        confirm_changes: 'Confirm Changes',
        error_add_to_cart: 'Failed to add item to cart',
        error_remove_from_cart: 'Failed to remove from cart',
        failed_to_place_order: 'Failed to place order',
        error_retrieve_orders: 'Failed to retrieve orders',
        error_retrieve_order: 'Failed to retrieve order',
        error_retrieve_cart: 'Failed to retrieve cart',
        error_cancel_order: 'Failed to cancel order',
        failed_to_place_quote: 'Failed to place quote',
        error_retrieve_quotes: 'Failed to retrieve quotes',
        error_retrieve_quote: 'Failed to retrieve quote',
        error_retrieve_statuses: 'Failed to retrieve quote statuses',
    },
    users: {
        users: 'users',
        add_user: 'Add User',
        edit_user: 'Edit User',
        search_users: 'Search Users',
        name: 'Full Name',
        company: 'Company',
        mobile_number: 'Mobile Number',
        contact_number: 'Contact Number',
        contact: 'Contact',
        address: 'Address',
        email: 'Email',
        email_address: 'Email Address',
        company_name: 'Company Name',
        vat_number: 'VAT Number',
        city: 'City',
        post_code: 'Post Code',
        password: 'Password',
        confirm_password: 'Confirm password',
        role: 'Role',
        roles: 'Roles',
        actions: 'Actions',
        installation: 'Installation',
        deposit: 'Deposit',
        discount: 'Discount',
        date: 'Date',
        prompt_delete: 'Are you sure you want to delete this user?',
        prompt_delete_subtext: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed massa metus, ornare nec dui aliquam, dapibus dictum leo.',
        success_created: 'User has been created',
        success_updated: 'User has been updated',
        error_create: 'Failed to create user',
        error_update: 'Failed to update user',
        error_delete: 'Failed to delete user',
        error_retrieve: 'Failed to retrieve users',
        user_details: 'User Details',
        assigned_calculators: 'Assigned Calculators',
        assigned_blinds: 'Assigned Blinds',
        blinds: 'Blinds',
        update_user: 'Update User',
        delete_user: 'Delete User',
        add_blind: 'Add Blind',
        blind: 'Blind',
        error_assign_blind: 'Failed to assign blind',
        error_retrieve_blinds: 'Failed to retrieve blinds',
        remove_blind: 'Remove Blind',
        prompt_remove_blind: 'Are you sure you want to detach this blind?',
        error_detach_blind: 'Failed to detach blind'
    },
    roles: {
        roles: 'roles',
        permissions: 'Permissions',
        permission: 'Permission',
        actions: 'Actions',
        name: 'Name',
        add_role: 'Add Role',
        edit_role: 'Edit Role',
        search_roles: 'Search Roles',
        prompt_delete: 'Are you sure you want to delete this role?',
        prompt_delete_subtext: '',
        error_retrieve: 'Failed to retrieve roles',
        error_create: 'Failed to create role',
        error_update: 'Failed to update role',
        error_delete: 'Failed to delete role',
        success_created: 'role has been created',
        success_updated: 'role has been updated',
        error_retrieve_fields: 'Failed to retrieve fields',
        role_details: 'Role Details',
        delete_role: 'Delete Role',
    },
    reports: {
        date_from: 'Date From',
        date_to: 'Date To',
        export_report: 'Export report',
        select_date_and_ward: 'Please select a date and ward',
        select_fields: 'Select fields',
        select_date: 'Please select a date',
        error_generate: 'Failed to generate report',
        error_fields: 'Please select fields'
    },
    permissions: {
        permission: 'permission',
        permissions: 'permissions',
        error_retrieve: 'Failed to retrieve permissions',
        error_save: 'Failed to save permission',
        details_saved: 'permission details have been saved',
        saved: 'permission has been saved',
    },
    prompts: {
        are_you_sure: 'are you sure?',
        do_you_want_to_delete_this_x: 'do you want to delete this {x}?',
    },
    validation: {
        x_is_required: '{x} is required',
        please_enter_x: 'please enter {x}',
        please_enter_longer_x: 'please enter a longer {x}',
        please_enter_shorter_x: 'please enter a shorter {x}',
        please_enter_valid_x: 'please enter a valid {x}',
        please_choose_x: 'please choose {x}',
        please_enter_number: 'please enter a number',
        please_enter_number_over: 'A number over {x} is required',
        please_enter_number_between_x: 'please enter a number between {x} and {y}',
        number_must_be_atleast: 'A number of {x} or over is required',
        please_enter_url: 'please enter a URL',
        minimum_width: 'A minimum width of {x}cm is required',
        minimum_drop: 'A minimum drop of {x}cm is required',
        minimum_tile_cut_width: 'A minimum tile cut width of {x}cm is required',
        minimum_tile_cut_height: 'A minimum tile cut height of {x}cm is required',
        minimum_quantity: 'A minimum quantity of {x} is required',
    },
    auth: {
        unauthorized: 'Unauthorized',
        unauthenticated: 'Unauthenticated',
        forgot_password_q: 'Forgot password?',
        forgot_password: 'Forgot password',
        forgot_password_text: 'Enter your email and we\'ll send you a link to reset your password.',
        password_has_been_updated: 'Your password has been updated!',
        email: 'Email',
        password: 'Password',
        password_is_required: 'Password is required',
        email_is_required: 'Email is required',
        reset_password: 'Reset Password',
        enter_valid_email: 'Enter a valid email',
        email_sent: 'Email successfully sent',
        return_to_login: 'Return to Login',
        enter_your_new_password: 'Enter your new password',
        retype_password: 'Re-type password',
        failed_login: 'Login Failed',
        failed_reset: 'Reset Failed',
        failed_get_cart: 'Failed to get cart',
        identity_verified: 'Your identity has been verified!',
        set_new_password: 'Set your new password!',
    },
    quotes: {
        save_as_quote: 'Save As Quote',
        status: 'Status',
        quote_id: 'Quote ID',
        date_issued: 'Date Issued',
        client: 'Client',
        contact_number: 'Contact No',
        value: 'Value',
        switch_to_order: 'Switch To Order',
        print: 'Print',
        edit: 'Edit',
        delete: 'Delete',
        search_quotes: 'Search quotes',
        are_you_sure_switch_to_order: 'Are you sure you want to switch this quote to an order?',
        are_you_sure_remove_blind: 'Are you sure you want to remove this blind from this quote?',
        delete_quote: 'Delete Quote',
        are_you_sure_delete_quote: 'Are you sure you want to delete the quote?',
        no_quotes_found: 'No Quotes Found',
        success_created: 'A quote has been created!',
        cancel_quote: 'Cancel Quote',
        are_you_sure_cancel_quote: 'Are you sure you want to cancel this quote?',
        error_create: 'Failed to create quote',
        error_delete: 'Failed to delete quote',
        error_remove_from_quote: 'Failed to remove blind from quote',
        error_add_to_quote: 'Failed to add blind to quote',
        success_switch_to_order: 'Quote has been switched to an order',
        error_switch_to_order: 'Failed to switch quote to an order',
        error_cancel_quote: 'Failed to cancel quote',
        error_retrieve_quote: 'Failed to retrieve quote',
        error_value_negative: 'Total value cannot be negative'
    },
    pagination: {
        first: 'First',
        prev: 'Prev',
        next: 'Next',
        last: 'Last'
    },
    cancel: 'Cancel',
    delete: 'Delete',
    remove: 'Remove',
    start: 'Start',
    finish: 'Finish',
    add: 'Add',
    update: 'Update',
    save: 'Save',
    print: 'Print',
    send: 'Send',
    search: 'Search',
    select: 'Select',
    page_not_found: 'Page not found',
    x_per_page: '{x} per page',
    error: 'Error',
    yes: 'Yes',
    no: 'No',
    accepted: 'Accepted',
    not_accepted: 'Not Accepted',
    warning: 'Warning',
}
