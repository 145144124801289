<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <h1 v-if="original">{{ original.attributes.name }}</h1>
            </template>
            <template v-slot:right>
                <Button v-if="$store.getters.hasPermission('update users')" className="--primary --small --wider"
                        :class="{spinner: is_saving}" :onclick="save">
                    {{ $t('save') }}
                </Button>
            </template>
        </Headbar>
        <main>
            <Form class="form" :disabled="!$store.getters.hasPermission('update users') || is_saving">
                <SectionHeader :title="$t('users.user_details')"></SectionHeader>
                <div class="form-body">
                    <FormGroupTwo>
                        <FormInputText v-model="$v.user.name.$model" identifier="name" :label="$t('users.name')"
                                       :placeholder="$t('users.name')" :disabled="is_saving" :maxlength="10"
                                       :has-error="$v.user.name.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.user.name.required">
                                    {{ $t('validation.x_is_required', {x: $t('users.name')}) }}
                                </p>
                            </template>
                        </FormInputText>
                        <FormInputText v-model="$v.user.company.$model" identifier="company"
                                       :label="$t('users.company')"
                                       :placeholder="$t('users.company')" :disabled="is_saving"
                                       :has-error="$v.user.company.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.user.company.required">
                                    {{ $t('validation.x_is_required', {x: $t('users.company')}) }}
                                </p>
                            </template>
                        </FormInputText>
                        <FormInputText v-model="$v.user.phone.$model" identifier="phone"
                                       :label="$t('users.contact_number')"
                                       :placeholder="$t('users.contact_number')" :disabled="is_saving"
                                       :has-error="$v.user.phone.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.user.phone.required">
                                    {{ $t('validation.x_is_required', {x: $t('users.contact_number')}) }}
                                </p>
                            </template>
                        </FormInputText>
                        <FormInputText v-model="$v.user.email.$model" identifier="email" :label="$t('users.email')"
                                       :placeholder="$t('users.email')" :disabled="is_saving"
                                       :has-error="$v.user.email.$error" autocomplete="off">
                            <template v-slot:errors>
                                <p v-if="!$v.user.email.required">
                                    {{ $t('validation.x_is_required', {x: $t('users.email')}) }}
                                </p>
                                <p v-else-if="!$v.user.email.email">
                                    {{ $t('auth.enter_valid_email') }}
                                </p>
                            </template>
                        </FormInputText>
                    </FormGroupTwo>

                    <FormGroupTwo>
                        <FormInputText v-model="$v.user.address.$model" identifier="address"
                                       :label="$t('users.address')"
                                       :placeholder="$t('users.address')" :disabled="is_saving"
                                       :has-error="$v.user.address.$error" autocomplete="off">
                            <template v-slot:errors>
                                <p v-if="!$v.user.address.required">
                                    {{ $t('validation.x_is_required', {x: $t('users.address')}) }}
                                </p>
                            </template>
                        </FormInputText>

                        <FormInputText v-model="$v.user.vat_num.$model" identifier="email"
                                       :label="$t('users.vat_number')"
                                       :placeholder="$t('users.vat_number')" :disabled="is_saving"
                                       :has-error="$v.user.vat_num.$error" autocomplete="off">
                            <template v-slot:errors>
                                <p v-if="!$v.user.vat_num.required">
                                    {{ $t('validation.x_is_required', {x: $t('users.vat_number')}) }}
                                </p>
                            </template>
                        </FormInputText>
                    </FormGroupTwo>

                    <FormGroupTwo>
                        <FormInputPassword v-model="$v.user.password.$model" identifier="password"
                                           :label="$t('users.password')"
                                           :placeholder="$t('users.password')" :disabled="is_saving" type="password"
                                           :has-error="$v.user.password.$error" autocomplete="off">
                            <template v-slot:errors>
                                <p v-if="!$v.user.password.required">
                                    {{ $t('validation.x_is_required', {x: $t('users.password')}) }}
                                </p>
                            </template>
                        </FormInputPassword>
                        <FormInputSelect v-model="$v.user.role.$model" identifier="role"
                                         :label="$t('users.role')" :options="roleOptions"
                                         :placeholder="$t('users.role')" :disabled="is_saving"
                                         :has-error="$v.user.role.$error" track-by="id"
                                         :display-custom-label="(row) => `${row.attributes.name}`">
                            <template v-slot:errors>
                                <p v-if="!$v.user.role.required">
                                    {{ $t('validation.x_is_required', {x: $t('users.role')}) }}
                                </p>
                            </template>
                        </FormInputSelect>
                    </FormGroupTwo>
                </div>
            </Form>
            <div class="table" :disabled="!$store.getters.hasPermission('update users') || is_saving">
                <SectionHeader :title="$t('users.blinds')" button="Add" :onclick="toggleAddBlind"></SectionHeader>
                <vue-good-table
                    mode="remote"
                    styleClass="vgt-table vgt-custom vgt-white"
                    :columns="columns"
                    :rows="assigned_blinds"
                    :isLoading.sync="is_loading_assigned_blinds"
                    :search-options="{
                        enabled: false,
                    }"
                    :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        dropdownAllowAll: false,
                        perPage: 15,
                        perPageDropdownEnabled: false,
                        rowsPerPageLabel: $t('x_per_page', {x: $t('blinds.blinds')}),
                   }"
                    :sort-options="{
                      enabled: true,
                      multipleColumns: true,
                    }"
                    :totalRows="totalRecords"
                    @on-page-change="onPageChange">
                    <template slot="table-row" slot-scope="props">
                        <div v-if="props.column.field === 'after'" class="td-after">
                            <font-awesome-icon :icon="['fal', 'minus-circle']"
                                               @click="toggleRemoveBlind(props.row.id)"/>
                        </div>
                        <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                    </template>
                </vue-good-table>
            </div>
        </main>
    </div>
</template>

<script>
import _ from 'lodash';
import Headbar from "../../components/headbar/Headbar";
import Form from "../../components/form/Form";
import SectionHeader from "../../components/SectionHeader";
import Button from "../../components/Button";
import FormInputText from "../../components/form/FormInputText";
import FormInputSelect from "../../components/form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import FormGroupTwo from "../../components/form/FormGroupTwo";
import FormInputPassword from "../../components/form/FormInputPassword";
import AssignFabricModal from "@/components/blinds/AssignFabricModal";
import AssignBlindModal from "@/components/users/AssignBlindModal";
import ConfirmModal from "@/components/modal/ConfirmModal";


export default {
    name: "users-update-page",
    components: {FormGroupTwo, FormInputText, FormInputPassword, FormInputSelect, Button, SectionHeader, Form, Headbar},
    data() {
        return {
            original: null,
            user: {
                name: null,
                company: null,
                phone: null,
                email: null,
                address: null,
                password: null,
                role: null,
                vat_num: null,
            },
            is_saving: false,
            roleOptions: [],
            assigned_blinds: [],
            is_loading_assigned_blinds: false,
            blinds_page: 1,
            columns: [
                {
                    label: this.$t('users.name'),
                    field: 'attributes.name',
                    sortable: false,
                },
                {
                    field: 'after',
                    sortable: false,
                },
            ],
            totalRecords: 0,
            is_loading_blinds: false,
            is_loading_roles: false,
            is_loading_original: false,
        }
    },
    validations: {
        user: {
            name: {required},
            company: {required},
            phone: {required},
            email: {required, email},
            address: {required},
            password: {},
            role: {required},
            vat_num: {},
        }
    },
    methods: {
        populate() {
            if (!this.original || !this.user)
                return;

            this.$v.user.name.$model = this.original.attributes.name;
            this.$v.user.company.$model = this.original.attributes.company;
            this.$v.user.phone.$model = this.original.attributes.phone;
            this.$v.user.email.$model = this.original.attributes.email;
            this.$v.user.address.$model = this.original.attributes.address;
            this.$v.user.role.$model = _.find(this.roleOptions, {id: this.original.relationships.roles.data[0].id});
            this.$v.user.vat_num.$model = this.original.attributes.vat_num;
        },

        async retrieveOriginalUser() {
            this.is_loading_original = false;
            await this.$axios.get(`users/${this.$route.params.id}`)
                .then(({data}) => {
                    this.is_loading_original = false;
                    this.original = data.data;
                })
                .catch(e => {
                    this.is_loading_original = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('users.error_retrieve')),
                        type: 'error',
                    });
                });
        },

        async retrieveRoleOptions() {
            this.is_loading_roles = false;
            await this.$axios.get('roles/list')
                .then(({data}) => {
                    this.roleOptions = data.data;
                    this.is_loading_roles = false;
                })
                .catch(e => {
                    this.is_loading_roles = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('roles.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        retrieveAssignedBlinds() {
            this.is_loading_assigned_blinds = false;
            this.$axios.get(`users/${this.$route.params.id}/blinds`)
                .then(({data}) => {
                    this.assigned_blinds = data.data;
                    this.is_loading_assigned_blinds = false;
                })
                .catch(e => {
                    this.is_loading_assigned_blinds = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('users.error_retrieve_blinds')),
                        type: 'error',
                    });
                });
        },
        onPageChange(params) {
            this.blinds_page = params.currentPage;
            this.retrieveAssignedBlinds();
        },
        toggleRemoveBlind(id) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('users.remove_blind'),
                    message: this.$t('users.prompt_remove_blind'),
                    confirmText: this.$t('remove'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.i_removing_blind = true;
                            this.$axios.delete(`users/${this.$route.params.id}/blinds/${id}/detach`)
                                .then(({data}) => {
                                    this.i_removing_blind = false;
                                    this.retrieveAssignedBlinds();
                                })
                                .catch(e => {
                                    this.i_removing_blind = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$t('users.error_detach_blind'),
                                        type: 'error'
                                    });
                                });
                        }
                    }
                }
            );
        },
        toggleAddBlind() {
            this.$modal.show(
                AssignBlindModal, {},
                {
                    name: 'assign-blind-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveAssignedBlinds();
                    }
                }
            );
        },
        save() {
            this.$v.user.$touch();
            if (this.$v.user.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = {};

            for (let key in this.user) {
                if (key !== 'role' && this.user[key] && this.user[key] !== this.original.attributes[key])
                    payload[key] = this.user[key];
                else if (key === 'role' && this.user[key] && this.user[key].id !== this.original.relationships.roles.data[0].id)
                    payload.roles = [this.user[key].id];
            }

            if (!Object.keys(payload).length) {
                if (this.user.vat_num === "") {
                    payload.vat_num = null;
                }

                else {
                    this.$router.push({name: 'users-index'});
                    return;
                }
            }


            // payload.name = this.$v.user.name.$model;
            // payload.company = this.$v.user.company.$model;
            // payload.phone = this.$v.user.phone.$model;
            // payload.address = this.$v.user.address.$model;
            //
            // if (this.original.attributes.email !== this.$v.user.email.$model)
            //     payload.email = this.$v.user.email.$model;
            //
            // if (this.$v.user.role.$model !== this.$v.user.role.$model)
            //     payload.roles = [this.$v.user.role.$model.id];
            //
            // if (this.$v.user.password.$model) {
            //     payload.password = this.$v.user.password.$model;
            // }

            this.$axios.patch(`users/${this.$route.params.id}`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('users.success_updated'),
                    type: 'success',
                });

                this.is_saving = false;
                this.$router.push({name: 'users-index'});
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('users.error_update')),
                    type: 'error',
                });
            });
        },
    },

    async mounted() {
        await this.retrieveOriginalUser();
        await this.retrieveRoleOptions();
        this.populate();
        this.retrieveAssignedBlinds();
    },

    head() {
        return {
            title: {
                inner: this.$t('users.update_user')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    main {
        @apply flex flex-row flex-wrap;

        .form {
            @apply mb-8 w-full;

            @screen lg {
                @apply mr-4;
                width: calc(50% - 1rem);
            }

            .form-body {
                @apply px-8 py-7;

                &.next-of-kin {
                    .next-of-kin-row-group {
                        .input-group {
                            @apply w-auto flex-1;
                        }

                        .button-remove {
                            @apply flex-none self-start mt-9;
                        }
                    }
                }
            }

            .row-add-container {
                @apply px-8 pb-8 mt-auto;
            }
        }

        .table {
            @apply w-full;

            @screen lg {
                @apply ml-4;
                width: calc(50% - 1rem);
            }

            &:nth-child(odd) {
                @screen lg {
                    @apply mr-4;
                }
            }

            &:nth-child(even) {
                @screen lg {
                    @apply ml-4;
                }
            }

            .td-after {
                @apply flex;

                svg {
                    @apply text-negative text-lg cursor-pointer ml-auto;

                    &:hover {
                        @apply text-primary-over
                    }
                }
            }
        }
    }
}
</style>