<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <Button v-if="$store.getters.hasPermission('store articles')" className="--primary --small"
                        :onclick="toggleCreate">
                    {{ $t('articles.add_article') }}
                </Button>
            </template>
            <template v-slot:right>
                <Select :placeholder="$t('articles.category')" :custom-label="(row) => row.attributes.name"
                        v-model="category"
                        :options="categoryOptions" :allowEmpty="true" :allow-empty="true" track-by="id"
                        @input="onFilterUpdated"
                        class="select-dropdown"/>
                <Search class="search-desktop" :placeholder="$t('articles.search_articles')" @search="search"/>
                <button class="btn-search-mobile btn-icon-only"
                        @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>
            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <Search v-if="headbarExpanded === 'search'" :placeholder="$t('articles.search_articles')" @search="search"/>
                </div>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                mode="remote"
                styleClass="vgt-table vgt-custom"
                :columns="columns"
                :rows="articles"
                :isLoading.sync="is_loading_articles"
                :search-options="{
                    enabled: false,
                }"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 15,
                    perPageDropdownEnabled: false,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('articles.articles')}),
                }"
                :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                }"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'attributes.description'">
                        <p v-if="props.row.attributes.description" style="text-transform: capitalize;">{{props.row.attributes.description}}</p>
                        <p v-else>N/A</p>
                    </div>
                    <div v-else-if="props.column.field === 'category'">
                        <p v-if="props.row.relationships.category && props.row.relationships.category.data && props.row.relationships.category.data.attributes" style="text-transform: capitalize;">{{props.row.relationships.category.data.attributes.name}}</p>
                        <p v-else>N/A</p>
                    </div>
                    <div v-else-if="props.column.field === 'attributes.price'">
                        <p>€ {{props.row.attributes.price.toFixed(2)}}</p>
                    </div>
                    <div v-else-if="props.column.field === 'after'" class="td-after">
                        <Button v-if="$store.getters.hasPermission('update articles')"
                                className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleUpdate(props.row)">
                            <font-awesome-icon :icon="['fal', 'pencil']"/>
                        </Button>
                        <Button v-if="$store.getters.hasPermission('destroy articles')"
                                className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import ArticlesCreateModal from "../../components/articles/ArticlesCreateModal";
import ArticlesUpdateModal from "../../components/articles/ArticlesUpdateModal";

export default {
    name: "articles-index-page",
    components: {ConfirmModal, Select, Button, Search, Headbar},
    data: function () {
        const columns = [
            {
                label: this.$t('articles.code'),
                field: 'attributes.code',
                sortable: false,
            },
            {
                label: this.$t('articles.name'),
                field: 'attributes.name',
                sortable: false,
            },
            {
                label: this.$t('articles.category'),
                field: 'category',
                sortable: false,
            },
            {
                label: this.$t('articles.price'),
                field: 'attributes.price',
                sortable: false,
            },
        ];

        if (this.$store.getters.hasAnyPermission(['update articles', 'destroy articles']))
            columns.push({
                label: this.$t('articles.actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false
            });

        return {
            columns: columns,
            articles: [],
            is_loading_articles: false,
            totalRecords: null,
            serverParams: {sorting: [], page: 1},
            searchTerm: null,
            is_deleting: false,
            categoryOptions: [],
            category: null,
            headbarExpanded: false
        }
    },
    methods: {
        toggleDelete(article) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('articles.delete_article'),
                    message: this.$t('articles.prompt_delete'),
                    confirmText: this.$t('delete'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.delete(`articles/${article.id}`)
                                .then(({data}) => {
                                    this.is_deleting = false;
                                    this.retrieveArticles();
                                })
                                .catch(e => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('articles.error_delete')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },
        toggleCreate() {
            this.$modal.show(
                ArticlesCreateModal, {},
                {
                    name: 'articles-create-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveArticles();
                    }
                }
            );
        },
        toggleUpdate(article) {
            this.$modal.show(
                ArticlesUpdateModal,
                {
                    article_id: article.id,
                },
                {
                    name: 'articles-update-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveArticles();
                    }
                }
            );
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.retrieveArticles();
        },
        onSortChange(params) {
            const sorts = [];

            if (!params || !params.length) {
                this.updateParams({sorting: []});
                return this.retrieveArticles();
            }

            params.forEach(p => {
                if (!p.type || p.type === 'none')
                    return;

                let sort_by = null;
                let sort_order = p.type;
                if (p.field === 'attributes.name')
                    sort_by = 'name';
                else if (p.field === 'attributes.code')
                    sort_by = 'code';
                else if (p.field === 'attributes.price')
                    sort_by = 'price';
                else
                    sort_by = p.field.split('.')[1];

                sorts.push({sort_order, sort_by})
            });

            this.updateParams({sorting: sorts});
            this.retrieveArticles();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length) {
                this.updateParams({term: searchTerm});
            } else this.removeParam('term');

            this.retrieveArticles();
        },
        onFilterUpdated() {
            const filters = [];

            if (this.category) {
                filters.push({
                    filter_by: 'category_id',
                    filter_value: this.category.id
                })

                this.$router.replace({query: {category: this.category.id}});
            }

            if (filters.length)
                this.updateParams({filters: filters})
            else
                this.removeParam('filters');

            this.retrieveArticles();
        },
        retrieveArticles() {
            this.is_loading_articles = true;

            const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));

            this.$axios.get('articles', {params: {...this.serverParams, sorting: encodedSorting}})
                .then(({data}) => {
                    this.articles = data.data;
                    this.totalRecords = data.meta.total;
                    this.serverParams.page = data.meta.current_page;
                    this.is_loading_articles = false;
                })
                .catch(e => {
                    this.is_loading_articles = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('articles.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveCategoryOptions() {
            this.is_loading_categories = false;
            await this.$axios.get('categories/list')
                .then(({data}) => {
                    this.categoryOptions = data.data;
                    this.is_loading_categories = false;
                })
                .catch(e => {
                    this.is_loading_categories = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('categories.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },

    mounted() {
        this.retrieveArticles();
        this.retrieveCategoryOptions();
    },

    head() {
        return {
            title: {
                inner: this.$t('nav.articles')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
    .page-container {
        .headbar-expanded-container {
            @apply mt-4 flex flex-col gap-y-4 items-center;

            @screen md {
                @apply hidden;
            }
        }

        .select-dropdown {
            @apply min-w-40;
        }

        .btn-search-mobile, .btn-filter-mobile {
            @apply block text-primary w-6;

            @screen md {
                @apply hidden;
            }

            &:active, &:focus {
                @apply outline-none;
            }
        }

        .search-desktop, .filter-desktop {
            @apply hidden;

            @screen md {
                @apply block;
            }
        }

        .search-container {
            @apply mr-0;
        }

        .filter-mobile {
            @apply max-w-xs;
        }

        .td-after {
            @apply flex flex-row;

            & > * {
                @apply mr-3;

                &:last-child {
                    @apply mr-0;
                }
            }
        }
    }
</style>
