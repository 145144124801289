<template>
  <loading-screen v-if="is_loading_orders"></loading-screen>
  <div v-else class="page-container">
    <div class="page-content-container">
      <div class="header-container">
        <div class="left">
          <router-link :to="{name: 'calculators-index'}">
            <font-awesome-icon :icon="['far', 'home-lg-alt']"/>
          </router-link>
          <h1>{{ $t('cart.my_orders') }}</h1>
        </div>
      </div>

      <div class="order-details-wrapper">
        <div class="order-details-container">
          <div class="title-row">
            <p>Order ID</p>
            <p>Ordered</p>
            <p>Status</p>
            <p>Client</p>
            <p>Sales Person</p>
            <p>Value</p>
          </div>

          <div v-if="orders.length <= 0" class="no-orders-found">
            <p>No Orders Found</p>
          </div>
          <div v-for="order in orders" v-else class="order-row">
            <div class="details">
              <p>{{ order.attributes.reference }}</p>
              <p>{{ order.attributes.confirmed_at | date }}</p>

              <p v-if="order.attributes.status !== 'on_hold'">{{ orderStatus(order.attributes.status) }}</p>
              <div v-else class="on-hold-label"><p><span>On Hold</span></p></div>

              <p>
                {{ order.attributes.client_details ? order.attributes.client_details.name : order.relationships.customer.data.attributes.company }}</p>
              <p>{{ order.relationships.customer.data.attributes.name }}</p>
              <p>€{{ order.attributes.value.toFixed(2) }}</p>
            </div>


            <div class="icons-container">
              <div class="ml-4">
                <template v-if="!$store.getters.hasRole('agent')">
                  <font-awesome-icon :class="{disabled: is_printing_order}" class="text-2xl text-grey-dark cursor-pointer"  :content="$t('quotes.print')" :icon="['fas','print']"/>
                  <tippy
                      :hide-on-click="true"
                      placement="bottom"
                      trigger="click"
                      arrow
                      content-class="content-wrapper"
                      interactive>
                    <div class="flex flex-col gap-x-4 gap-y-2">
                      <Button className="--primary --mini" :class="{disabled: is_printing_order}" :onclick="() => generateReportWithMeasurement(order)">
                        {{ $t('blinds.with_measurements') }}
                      </Button>
                      <Button className="--primary --mini" :class="{disabled: is_printing_order}" :onclick="() => generateReport(order)">
                        {{ $t('blinds.without_measurements') }}
                      </Button>
                    </div>
                  </tippy>
                </template>
                <template v-else>
                  <font-awesome-icon :class="{disabled: is_printing_order}" class="text-2xl text-grey-dark cursor-pointer" :content="$t('quotes.print')" :icon="['fas','print']" @click="() => generateReport(order)"/>
                </template>
              </div>
              <font-awesome-icon :icon="['far','info-circle']" @click="visitOrder(order)"/>
              <font-awesome-icon :class="{disabled: !acceptedStatuses.find(element => element.includes(order.attributes.status))}" :icon="['far','times-circle']" class="delete-icon"
                                 @click="toggleDelete(order)"/>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination-wrapper">
        <p :class="{disabled: current_page === 1}" @click="onPageChange(1)">{{ $t('pagination.first') }}</p>
        <p :class="{disabled: current_page <= 1}" @click="onPageChange(serverParams.page - 1)">
          {{ $t('pagination.prev') }}</p>

        <p v-if="current_page > 1" :class="{active: serverParams.page === current_page-1}"
           @click="onPageChange(current_page-1)">{{ current_page - 1 }}</p>

        <p :class="{active: serverParams.page === current_page}" @click="onPageChange(current_page)">
          {{ current_page }}</p>

        <p v-if="current_page < last_page" :class="{active: serverParams.page === current_page+1}"
           @click="onPageChange(current_page+1)">{{ current_page + 1 }}</p>

        <p :class="{disabled: current_page >= last_page}" @click="onPageChange(serverParams.page + 1)">
          {{ $t('pagination.next') }}</p>
        <p :class="{disabled: current_page === last_page}" @click="onPageChange(last_page)">
          {{ $t('pagination.last') }}</p>
      </div>
    </div>

    <vue-html2pdf
        ref="html2Pdf"
        :enable-download="true"
        :filename="`${order_details ? order_details.attributes.reference : ''}`"
        :float-layout="true"
        :manual-pagination="true"
        :pdf-quality="2"
        :show-layout="false"
        pdf-content-width="1120px"
        pdf-format="a4"
        pdf-orientation="landscape">
      <section slot="pdf-content" class="pdf-content">
        <div v-for="(array, index) in printing_pages">
          <div class="top-row">
            <h1 class="reference">{{ order_details.attributes.reference }} - Blinds Qty {{ blinds.length }} - Page
              {{ index + 1 }} / {{ printing_pages.length }} </h1>

            <div v-if="index === 0 && !isAgentOrder" class="sales-person-container">
              <p><span>SP:</span> {{ order_details.relationships.customer.data.attributes.name }}</p>
              <p><span>Company:</span> {{ order_details.relationships.customer.data.attributes.company }}</p>
            </div>
          </div>

          <div v-if="order_details && order_details.relationships && order_details.relationships.customer && order_details.relationships.customer.data && index === 0" class="order-details-container">
            <div class="billing-details-container">
              <div class="card-title-container">
                <h1 class="card-title">
                  {{ isAgentOrder ? $t('cart.agent_invoicing_details') : $t('cart.client_invoicing_details') }}</h1>
              </div>

              <div class="user-details-wrapper">
                <div class="user-details-container">
                  <div class="user-detail">
                    <p class="label">{{ $t('users.date') }}:</p>
                    <p>{{ $moment.utc(order_details.attributes.created_at).local().format('DD/MM/YYYY - HH:mm') }}</p>
                  </div>

                  <div class="user-detail">
                    <p class="label">{{ $t('users.name') }}:</p>
                    <p>{{
                        isAgentOrder ? order_details.relationships.customer.data.attributes.name : order_details.attributes.client_details.name
                      }}</p>
                  </div>

                  <div class="user-detail">
                    <p class="label">{{ $t('users.contact') }}:</p>
                    <p>{{
                        isAgentOrder ? order_details.relationships.customer.data.attributes.phone : order_details.attributes.client_details.phone
                      }}</p>
                  </div>
                </div>

                <div class="user-detail">
                  <p class="label">{{ $t('users.address') }}:</p>
                  <p>{{
                      isAgentOrder ? order_details.relationships.customer.data.attributes.address : order_details.attributes.client_details.address
                    }}</p>
                </div>

                <div class="user-detail">
                  <p class="label">{{ $t('users.email_address') }}:</p>
                  <p>{{
                      isAgentOrder ? order_details.relationships.customer.data.attributes.email : order_details.attributes.client_details.email
                    }}</p>
                </div>

                <div v-if="(isAgentOrder && order_details.relationships.customer.data.attributes.company || order_details.relationships.customer.data.attributes.vat_num) || (!isAgentOrder && order_details.attributes.client_details.company || order_details.attributes.client_details.vat_num)"
                     class="user-details-container">
                  <div v-if="(isAgentOrder && order_details.relationships.customer.data.attributes.company) || (!isAgentOrder && order_details.attributes.client_details.company)"
                       class="user-detail">
                    <p class="label">{{ $t('users.company_name') }}:</p>
                    <p>{{
                        isAgentOrder ? order_details.relationships.customer.data.attributes.company : order_details.attributes.client_details.company
                      }}</p>
                  </div>

                  <div v-if="(isAgentOrder && order_details.relationships.customer.data.attributes.vat_num) || (!isAgentOrder && order_details.attributes.client_details.vat_num)"
                       class="user-detail">
                    <p class="label">{{ $t('users.vat_number') }}:</p>
                    <p>{{
                        isAgentOrder ? order_details.relationships.customer.data.attributes.vat_num : order_details.attributes.client_details.vat_num
                      }}</p>
                  </div>
                </div>

                <div v-if="order_details.attributes.note" class="user-detail">
                  <p class="label">{{ $t('cart.note') }}:</p>
                  <p v-if="order_details.attributes.note">{{ order_details.attributes.note }}</p>
                </div>
              </div>
            </div>

            <div v-if="!isAgentOrder" class="price-breakdown-container">
              <div class="card-title-container">
                <h1 class="card-title">{{ $t('cart.order_cost_summary') }}</h1>
              </div>

              <div class="details-container">
                <div class="row">
                  <p class="label">{{ $t('cart.sub_total_1') }}</p>
                  <div class="price">€{{ blinds_total.toFixed(2) }}</div>
                </div>

                <div v-if="order_details.attributes.discount" class="row">
                  <p class="label">{{ $t('cart.discount') }} ({{ order_details.attributes.discount }}%)</p>
                  <div class="price">€{{ (blinds_total * (order_details.attributes.discount / 100)).toFixed(2) }}</div>
                </div>

                <div v-if="order_details.attributes.installation" class="row">
                  <p class="label">{{ $t('cart.installation') }}</p>
                  <div class="price">€{{ order_details.attributes.installation.toFixed(2) }}</div>
                </div>

                <div class="row">
                  <p class="label">{{ $t('cart.sub_total_2') }}</p>
                  <div class="price">
                    €{{ (blinds_total - (order_details.attributes.discount ? (blinds_total * (order_details.attributes.discount / 100)).toFixed(2) : 0) + order_details.attributes.installation).toFixed(2) }}
                  </div>
                </div>

                <div v-if="order_details.attributes.deposit" class="row bottom-border">
                  <p class="label">{{ $t('cart.deposit') }}</p>
                  <div class="price">€{{ order_details.attributes.deposit.toFixed(2) }}</div>
                </div>

                <div class="row">
                  <p class="label">{{ $t('cart.balance_inc_vat') }}</p>
                  <div class="price">€{{ order_details.attributes.value.toFixed(2) }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="item-cards-container">
            <div v-for="item in array" class="item-card-container">
              <item-card-printing :item="item" class="item-card-wrapper"/>
            </div>
          </div>

          <div v-if="index !== printing_pages.length-1" class="html2pdf__page-break"/>
        </div>
      </section>
    </vue-html2pdf>

    <vue-html2pdf
        ref="html2PdfMeasurements"
        :enable-download="true"
        :filename="`${order_details ? order_details.attributes.reference : ''}`"
        :float-layout="true"
        :manual-pagination="true"
        :pdf-quality="2"
        :show-layout="false"
        pdf-content-width="100%"
        pdf-format="a4"
        pdf-orientation="portrait">
      <section slot="pdf-content" class="pdf-content-measurements">
        <div v-for="(array, index) in printing_pages">
          <div class="top-row">
            <h1 class="reference">{{ order_details.attributes.reference }} - Blinds Qty {{ blinds.length }} - Page
              {{ index + 1 }} / {{ printing_pages.length }} </h1>

            <div v-if="index === 0 && !isAgentOrder" class="sales-person-container">
              <p><span>SP:</span> {{ order_details.relationships.customer.data.attributes.name }}</p>
              <p><span>Company:</span> {{ order_details.relationships.customer.data.attributes.company }}</p>
            </div>
          </div>

          <div v-if="order_details && order_details.relationships && order_details.relationships.customer && order_details.relationships.customer.data && index === 0" class="order-details-container">
            <div class="billing-details-container">
              <div class="card-title-container">
                <h1 class="card-title">
                  {{ isAgentOrder ? $t('cart.agent_invoicing_details') : $t('cart.client_invoicing_details') }}</h1>
              </div>

              <div class="user-details-wrapper">
                  <div class="user-detail">
                    <p class="label">{{ $t('users.date') }}:</p>
                    <p>{{ $moment.utc(order_details.attributes.created_at).local().format('DD/MM/YYYY - HH:mm') }}</p>
                  </div>

                  <div class="user-detail">
                    <p class="label">{{ $t('users.name') }}:</p>
                    <p>{{
                        isAgentOrder ? order_details.relationships.customer.data.attributes.name : order_details.attributes.client_details.name
                      }}</p>
                  </div>

                  <div class="user-detail">
                    <p class="label">{{ $t('users.contact') }}:</p>
                    <p>{{
                        isAgentOrder ? order_details.relationships.customer.data.attributes.phone : order_details.attributes.client_details.phone
                      }}</p>
                  </div>

                <div class="user-detail">
                  <p class="label">{{ $t('users.address') }}:</p>
                  <p>{{
                      isAgentOrder ? order_details.relationships.customer.data.attributes.address : order_details.attributes.client_details.address
                    }}</p>
                </div>

                <div class="user-detail">
                  <p class="label">{{ $t('users.email_address') }}:</p>
                  <p>{{
                      isAgentOrder ? order_details.relationships.customer.data.attributes.email : order_details.attributes.client_details.email
                    }}</p>
                </div>

                <div v-if="(isAgentOrder && order_details.relationships.customer.data.attributes.company || order_details.relationships.customer.data.attributes.vat_num) || (!isAgentOrder && order_details.attributes.client_details.company || order_details.attributes.client_details.vat_num)" class="flex flex-col">
                  <div v-if="(isAgentOrder && order_details.relationships.customer.data.attributes.company) || (!isAgentOrder && order_details.attributes.client_details.company)"
                       class="user-detail">
                    <p class="label">{{ $t('users.company_name') }}:</p>
                    <p>{{
                        isAgentOrder ? order_details.relationships.customer.data.attributes.company : order_details.attributes.client_details.company
                      }}</p>
                  </div>

                  <div v-if="(isAgentOrder && order_details.relationships.customer.data.attributes.vat_num) || (!isAgentOrder && order_details.attributes.client_details.vat_num)"
                       class="user-detail">
                    <p class="label">{{ $t('users.vat_number') }}:</p>
                    <p>{{
                        isAgentOrder ? order_details.relationships.customer.data.attributes.vat_num : order_details.attributes.client_details.vat_num
                      }}</p>
                  </div>
                </div>

                <div v-if="order_details.attributes.note" class="user-detail">
                  <p class="label">{{ $t('cart.note') }}:</p>
                  <p v-if="order_details.attributes.note">{{ order_details.attributes.note }}</p>
                </div>
              </div>
            </div>

            <div v-if="!isAgentOrder" class="price-breakdown-container">
              <div class="card-title-container">
                <h1 class="card-title">{{ $t('cart.order_cost_summary') }}</h1>
              </div>

              <div class="details-container">
                <div class="row">
                  <p class="label">{{ $t('cart.sub_total_1') }}</p>
                  <div class="price">€{{ blinds_total.toFixed(2) }}</div>
                </div>

                <div v-if="order_details.attributes.discount" class="row">
                  <p class="label">{{ $t('cart.discount') }} ({{ order_details.attributes.discount }}%)</p>
                  <div class="price">€{{ (blinds_total * (order_details.attributes.discount / 100)).toFixed(2) }}</div>
                </div>

                <div v-if="order_details.attributes.installation" class="row">
                  <p class="label">{{ $t('cart.installation') }}</p>
                  <div class="price">€{{ order_details.attributes.installation.toFixed(2) }}</div>
                </div>

                <div class="row">
                  <p class="label">{{ $t('cart.sub_total_2') }}</p>
                  <div class="price">
                    €{{ (blinds_total - (order_details.attributes.discount ? (blinds_total * (order_details.attributes.discount / 100)).toFixed(2) : 0) + order_details.attributes.installation).toFixed(2) }}
                  </div>
                </div>

                <div v-if="order_details.attributes.deposit" class="row bottom-border">
                  <p class="label">{{ $t('cart.deposit') }}</p>
                  <div class="price">€{{ order_details.attributes.deposit.toFixed(2) }}</div>
                </div>

                <div class="row">
                  <p class="label">{{ $t('cart.balance_inc_vat') }}</p>
                  <div class="price">€{{ order_details.attributes.value.toFixed(2) }}</div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="index > 0" class="item-cards-container">
            <div v-for="item in array" class="item-card-container">
              <item-card-printing-measurements :key="item.attributes.token" :item="item" class="item-card-wrapper"/>
            </div>
          </div>

          <div v-if="index !== printing_pages.length-1" class="html2pdf__page-break"/>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import Button from "../../../components/Button";
import ConfirmModal from "@/components/modal/ConfirmModal";
import LoadingScreen from "@/components/LoadingScreen";
import moment from "moment";
import _ from "lodash";
import VueHtml2pdf from 'vue-html2pdf';
import ItemCardPrinting from "@/components/calculators/ItemCardPrinting";
import ItemCardPrintingMeasurements from "@/components/calculators/ItemCardPrintingMeasurements.vue";

export default {
  name: "MyOrders",
  components: {ItemCardPrintingMeasurements, ItemCardPrinting, LoadingScreen, Button, VueHtml2pdf},
  data() {
    return {
      acceptedStatuses: ['processing', 'on_hold'],
      orders: [],
      order_details: null,
      isAgentOrder: false,
      blinds_total: 0,
      printing_pages: [],
      current_page: 1,
      last_page: 999,
      serverParams: {
        page: 1,
      },
      blindTypeOptions: [],
      is_loading_orders: false,
      is_printing_order: false
    }
  },
  methods: {
    async generateReport(order) {
      this.is_printing_order = true;
      this.blinds_total = 0;

      await this.$axios.get(`orders/${order.id}`)
          .then(async ({data}) => {
            this.order_details = data.data;

            if (data.data.relationships.cart_blinds.data.length > 0) {
              this.blinds = data.data.relationships.cart_blinds.data;
            } else if (data.data.relationships.quote_blinds.data.length > 0) {
              this.blinds = data.data.relationships.quote_blinds.data;
            }

            await this.blinds.sort((a, b) => a.attributes.order - b.attributes.order);

            if (this.blinds.length) {
              for (const blind of this.blinds) {
                blind.attributes.options = await JSON.parse(blind.attributes.options);
                this.blinds_total += blind.attributes.value;
              }
            }

            this.isAgentOrder = !data.data.attributes.client_details;

            this.printing_pages = [];
            let temp_blinds_printing_array = _.clone(this.blinds);

            for (let i = 0; i < this.blinds.length / 3; i++) {
              if (i === 0) {
                this.printing_pages.push([]);
              }

              this.printing_pages.push(temp_blinds_printing_array.splice(0, 3));
            }


            this.$nextTick(async () => {
              await this.$refs.html2Pdf.generatePdf()

              this.is_printing_order = false;
            });
          })
          .catch(e => {
            this.is_printing_order = false;

            this.$notify({
              title: this.$t('error'),
              text: this.$larerror(e.response.data, this.$t('quotes.error_retrieve_quote')),
              type: 'error',
            });
          });
    },
    async generateReportWithMeasurement(order) {
      this.is_printing_order = true;
      this.blinds_total = 0;

      await this.$axios.get(`orders/${order.id}`)
          .then(async ({data}) => {
            this.order_details = data.data;

            if (data.data.relationships.cart_blinds.data.length > 0) {
              this.blinds = data.data.relationships.cart_blinds.data;
            } else if (data.data.relationships.quote_blinds.data.length > 0) {
              this.blinds = data.data.relationships.quote_blinds.data;
            }

            await this.blinds.sort((a, b) => a.attributes.order - b.attributes.order);

            if (this.blinds.length) {
              for (const blind of this.blinds) {
                blind.attributes.options = await JSON.parse(blind.attributes.options);
                this.blinds_total += blind.attributes.value;
              }
            }

            this.isAgentOrder = !data.data.attributes.client_details;

            this.printing_pages = [];
            let temp_blinds_printing_array = _.clone(this.blinds);

            for (let i = 0; i < this.blinds.length / 2; i++) {
              if (i === 0 && temp_blinds_printing_array.slice(0, 2).find(blind => blind.attributes.type.includes('curtain_blind'))) {
                this.printing_pages.push([]);
              }

              this.printing_pages.push(temp_blinds_printing_array.splice(0, 2));
            }

            if (this.printing_pages && this.printing_pages[0] && this.printing_pages[0].length > 0) this.printing_pages.unshift([])

            this.$nextTick(async () => {
              await this.$refs.html2PdfMeasurements.generatePdf()

              this.is_printing_order = false;
            });
          })
          .catch(e => {
            this.is_printing_order = false;

            this.$notify({
              title: this.$t('error'),
              text: this.$larerror(e.response.data, this.$t('quotes.error_retrieve_quote')),
              type: 'error',
            });
          });
    },
    toggleDelete(order) {
      if (!this.acceptedStatuses.find(element => element.includes(order.attributes.status))) return;

      this.$modal.show(
          ConfirmModal, {
            title: this.$t('cart.cancel_order'),
            message: this.$t('cart.are_you_sure_cancel_order'),
            confirmText: this.$t('yes'),
            cancelText: this.$t('no'),
            confirmClass: '--primary',
            cancelClass: '--secondary --outline'
          },
          {
            name: 'confirm-modal',
            adaptive: true,
            resizable: true,
            height: 'auto',
            scrollable: true,
            classes: 'modal',
          }, {
            'before-close': (e) => {
              if (e.params === true) {
                this.$axios.patch(`orders/${order.id}`, {status: 'cancelled'}).then(({data}) => {
                  this.getOrders();
                }).catch(e => {
                  this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('cart.error_cancel_order')),
                    type: 'error',
                  });
                });
              }
            }
          }
      );
    },
    visitOrder(order) {
      this.$router.push({name: 'order-details', params: {id: order.id}});
    },
    orderStatus(statusValue) {
      return _.startCase(statusValue)
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      if (params <= 0 || params > this.last_page)
        return;

      this.updateParams({page: params});
      this.getOrders();
    },

    getOrders() {
      this.is_loading_orders = true;

      this.$axios.get(`users/${this.$store.getters.user.id}/orders`, {params: this.serverParams})
          .then(({data}) => {
            this.orders = data.data;
            this.last_page = data.meta.last_page;
            this.current_page = data.meta.current_page;
            this.is_loading_orders = false;
          })
          .catch(e => {
            this.is_loading_orders = false;

            this.$notify({
              title: this.$t('error'),
              text: this.$larerror(e.response.data, this.$t('cart.error_retrieve_orders')),
              type: 'error',
            });
          });
    },
  },
  filters: {
    date(value) {
      return moment(value).format('DD-MM-YYYY');
    },
    dateTime(value) {
      return moment(value).format('DD-MM-YYYY hh:mm:ss A');
    }
  },
  mounted() {
    this.getOrders();
  },
  head() {
    return {
      title: {
        inner: this.$t('nav.my_orders')
      },
    }
  }
}
</script>

<style lang="scss" scoped>
@media print {
  @page {
    size: landscape
  }
}

.pdf-content {
  @apply px-8 py-2;

  .top-row {
    @apply flex flex-row justify-between mb-4;

    .reference {
      @apply text-black font-bold text-2xl my-auto -mt-1;
    }

    .sales-person-container {
      @apply flex flex-row border-1 border-black rounded-md py-4 px-2 bg-grey-light mt-1;

      p {
        @apply text-sm px-2 -mt-3;

        &.title {
          @apply font-bold;
        }

        span {
          @apply font-bold;
        }
      }
    }
  }

  .item-cards-container {
    @apply flex flex-row -mx-2;

    .item-card-container {
      @apply w-1/3 px-2 mt-2;
    }
  }

  .order-details-container {
    @apply flex flex-row mb-2;

    .billing-details-container {
      @apply flex flex-col rounded-md border-1 border-black bg-grey-light mr-2;
      width: 60%;

      .card-title-container {
        @apply px-4 py-1 bg-black text-white;

        .card-title {
          @apply font-bold text-sm -mt-2 mb-2;
        }
      }

      .user-details-wrapper {
        @apply px-4 py-2;

        .user-details-container {
          @apply flex flex-row;
        }

        .user-detail {
          @apply flex flex-row mr-8;

          p {
            &:nth-child(even) {
              @apply ml-1;
            }
          }

          &:last-child {
            @apply mr-0;
          }

          .label {
            @apply font-bold mb-2 text-xs;
          }

          p {
            @apply mb-2 text-xs;
          }
        }
      }

      & > h1 {
        @apply font-bold text-lg mb-4;
      }
    }

    .price-breakdown-container {
      @apply ml-2 rounded-md border-1 border-black bg-grey-light;
      width: 40%;

      .card-title-container {
        @apply px-4 py-1 bg-black text-white;

        .card-title {
          @apply font-bold text-sm -mt-2 mb-2;
        }
      }

      .details-container {
        @apply flex flex-col flex-wrap max-h-40 px-4 py-2 -mx-4;

        .row {
          @apply flex flex-row text-xs justify-between mx-4 mb-4;

          .label {
            @apply font-bold mr-4;
          }

          &.bottom-border {
            @apply pb-4 mb-0 border-b border-black;
          }
        }
      }
    }
  }
}

.pdf-content-measurements {
  @apply px-8 py-2;

  .top-row {
    @apply flex flex-col justify-between mb-8;

    .reference {
      @apply text-black font-bold text-2xl mb-4 my-auto -mt-1;
    }

    .sales-person-container {
      @apply flex flex-row border-1 border-black rounded-md py-4 px-2 bg-grey-light mt-1;

      p {
        @apply text-sm px-2 -mt-3;

        &.title {
          @apply font-bold;
        }

        span {
          @apply font-bold;
        }
      }
    }
  }

  .item-cards-container {
    @apply flex flex-row -mx-2;

    .item-card-container {
      @apply w-1/2 px-2 mt-2;
    }
  }

  .order-details-container {
    @apply flex flex-col gap-x-4 gap-y-8 mb-2;

    .billing-details-container {
      @apply flex flex-col rounded-md border-1 border-black bg-grey-light mr-2;
      width: 100%;

      .card-title-container {
        @apply px-4 py-1 bg-black text-white;

        .card-title {
          @apply font-bold text-sm -mt-2 mb-2;
        }
      }

      .user-details-wrapper {
        @apply px-4 py-2;

        .user-details-container {
          @apply flex flex-row;
        }

        .user-detail {
          @apply flex flex-row mr-8;

          p {
            &:nth-child(even) {
              @apply ml-1;
            }
          }

          &:last-child {
            @apply mr-0;
          }

          .label {
            @apply font-bold mb-2 text-xs;
          }

          p {
            @apply mb-2 text-xs;
          }
        }
      }

      & > h1 {
        @apply font-bold text-lg mb-4;
      }
    }

    .price-breakdown-container {
      @apply rounded-md border-1 border-black bg-grey-light;
      width: 100%;

      .card-title-container {
        @apply px-4 py-1 bg-black text-white;

        .card-title {
          @apply font-bold text-sm -mt-2 mb-2;
        }
      }

      .details-container {
        @apply flex flex-col flex-wrap px-4 py-2 -mx-4;

        .row {
          @apply flex flex-row text-xs justify-between mx-4 mb-4;

          .label {
            @apply font-bold mr-4;
          }

          &.bottom-border {
            @apply pb-4 mb-0 border-b border-black;
          }
        }
      }
    }
  }
}


.page-container {
  @apply w-full h-full mx-auto bg-white px-8;

  & > .page-content-container {
    @apply flex flex-col max-w-6xl mx-auto w-full;

    & > .header-container {
      @apply flex flex-row pt-8 ml-4;

      & > .left {
        @apply flex flex-row w-full;

        & > a {
          @apply my-auto mr-4;

          & > svg {
            @apply text-primary text-3xl cursor-pointer;

            &:hover {
              @apply text-primary-over;
            }
          }
        }

        & > h1 {
          @apply font-bold text-2xl my-auto mx-auto pr-8;
        }
      }

      & > .right {
        @apply flex flex-row;

        & > .price-container {
          @apply border-2 border-grey-light rounded p-2 px-6 mr-6 text-black font-bold text-2xl;
        }
      }
    }

    & > .order-details-wrapper {
      @apply overflow-x-scroll;

      @screen lg {
        @apply overflow-x-auto;
      }

      & > .order-details-container {
        @apply flex flex-col mt-12;
        width: 68rem;

        @screen xl {
          @apply w-full;
        }

        & > .title-row {
          @apply flex flex-row mb-4 mx-12;
          width: 55rem;

          @screen xl {
            width: 82.5%;
          }

          & > p {
            @apply w-1/4 font-bold text-left text-base;
          }
        }

        & > .no-orders-found {
          & > p {
            @apply font-bold ml-12 mt-4 text-xl;
          }
        }

        & > .order-row {
          @apply flex flex-row w-full mb-4 bg-grey-form rounded-xl py-6;

          &:last-of-type {
            @apply mb-0;
          }

          & > .details {
            @apply flex flex-row ml-12;
            width: 55rem;

            @screen xl {
              @apply w-5/6;
            }

            & > p {
              @apply w-1/6 font-bold text-left text-base text-grey-dark capitalize;
            }

            & > .on-hold-label {
              @apply w-1/6 font-bold text-left text-base text-grey-dark h-full;

              & > p {
                @apply text-white bg-primary text-base px-2 rounded font-medium text-center h-full flex;
                width: fit-content;

                & > span {
                  @apply my-auto;
                }
              }
            }
          }

          & > .icons-container {
            @apply flex flex-row w-auto ml-auto;

            & > svg  {
              @apply text-2xl text-grey-dark cursor-pointer ml-4;

              &:hover {
                @apply text-grey-darker;
              }
            }

            & > .delete-icon {
              @apply text-primary mx-4 mr-8;

              &:hover {
                @apply text-primary-over;
              }

              &.disabled {
                @apply text-primary-dimmed cursor-not-allowed;
              }
            }
          }
        }
      }
    }

    .pagination-wrapper {
      @apply flex flex-row my-8 ml-auto;

      p {
        @apply mr-4 text-black text-xl font-bold;

        &:hover {
          @apply cursor-pointer text-primary;
        }

        &.active {
          @apply text-primary;
        }

        &.disabled {
          @apply text-grey cursor-not-allowed;
        }
      }
    }
  }
}
</style>