<template>
    <div class="icon-wrapper">
        <div class="svg-wrapper">
            <font-awesome-icon :icon="[iconType, iconName]"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "UserIcon",
    props: {
        iconType: {
            type: String,
            required: true,
        },
        iconName: {
            type: String,
            required: true,
        }
    }
}
</script>

<style lang="scss" scoped>
    .icon-wrapper{
        @apply flex bg-primary rounded-full h-7 w-7;

        .svg-wrapper{
            @apply content-center justify-center my-auto mx-auto fill-current text-black opacity-40;

            svg{
                @apply w-2.5;
            }
        }
    }

</style>