<template>
    <nav class="menubar-container">
        <button class="btn-mobile-menu" v-if="!$store.getters.menuExpanded" @click="$store.commit('TOGGLE_MENU_EXPANDED')">
            <font-awesome-icon :icon="['fal', 'bars']"/>
        </button>

        <button class="btn-mobile-menu" v-else @click="$store.commit('TOGGLE_MENU_EXPANDED')">
            <font-awesome-icon :icon="['far', 'times']"/>
        </button>

        <img src="../assets/logo.png" class="logo" @click="goToHome">

        <font-awesome-layers class="cart-icon" @click="goToCart" :class="{'hidden-icon': !$store.getters.cart}">
            <font-awesome-icon class="cart-icon" :icon="['fal', 'shopping-cart']" :class="{'hidden-icon': !$store.getters.cart}"/>
            <font-awesome-layers-text :value="this.$store.getters.cartCount" class="cart-icon-counter" v-show="this.$store.getters.cartCount > 0"></font-awesome-layers-text>
        </font-awesome-layers>

        <div class="user-wrapper" >
            <div class="user" @click="nav_open = !nav_open">
                <div class="avatar">
                    <user-icon iconType="fas" iconName="user"></user-icon>
                    <!--<avatar background-color="#C82026" color="#474747" :size="30" :username="$store.getters.user.attributes.name"></avatar>-->
                </div>
                <p>{{ $store.getters.user.attributes.name }}</p>
                <font-awesome-icon :icon="['far', 'angle-down']"/>
            </div>
            <nav :class="{'nav-open': nav_open}">
                <ul class="menu" @click="nav_open = false">
                    <li>
                        <router-link :to="{name: 'profile'}">{{ $t('nav.profile') }}</router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'my-orders'}">{{ $t('nav.my_orders') }}</router-link>
                    </li>
                    <li v-if="$store.getters.hasPermission('read quotes')">
                        <router-link :to="{name: 'quotes'}">{{ $t('nav.quotes') }}</router-link>
                    </li>
                    <li>
                        <a @click="logout">{{ $t('nav.logout') }}</a>
                    </li>
                </ul>
            </nav>
        </div>
    </nav>
</template>

<script>
import Avatar from 'vue-avatar'
import UserIcon from './UserIcon'

export default {
    name: "Menubar",
    components: {UserIcon, Avatar},
    data() {
        return {
            nav_open: false
        }
    },
    methods: {
        goToCart() {
            this.$router.push({name: 'cart'});
        },
        goToHome() {
            this.$store.commit('TOGGLE_MENU_EXPANDED', false);

            this.$router.push({name: 'calculators-index'});
        },
        async logout() {
            await this.$store.dispatch('logout');
            this.$router.push({name: 'login'});
        },
    }
}
</script>

<style lang="scss" scoped>
.menubar-container {
    @apply flex flex-row w-full bg-black h-14 text-white relative;
    z-index: 999999;
    grid-area: menubar;

    .btn-mobile-menu {
        @apply ml-6;

        @screen md {
            @apply hidden;
        }

        svg {
            @apply text-white text-xl;
        }

        &:active, &:focus {
            @apply outline-none;
        }
    }

    img.logo {
        @apply h-full w-auto py-2 absolute cursor-pointer;
        left: 50%;
    }

    .cart-icon {
        @apply text-xl my-auto mr-2 ml-auto cursor-pointer;

        &.hidden-icon {
            visibility: hidden;
        }

        .cart-icon-counter {
            @apply text-black font-bold text-xs bg-primary rounded-full;
            padding: 0.2rem 0.35rem;
            margin-left: 0.35rem;
            margin-top: -0.65rem;

            &.over-9 {
                padding: 0.25rem;
            }

            &.over-99 {
                padding: 0.45rem 0.25rem;
            }
        }
    }

    .user-wrapper {
        @apply py-4 cursor-pointer mr-2 relative;

        .user {
            @apply mx-4 flex flex-row h-10 pb-4 items-center;

            .avatar {
                @apply mr-3;
            }

            p {
                @apply max-w-xs truncate text-sm hidden mr-3;

                @screen md {
                    @apply block;
                }
            }

            svg {
                @apply text-2xl;
            }
        }

        nav {
            @apply absolute hidden bg-grey-darker;
            z-index: 999;
            right: 0px;
            width: max-content;

            @screen lg {
                right: 8px;
            }

            .menu {
                @apply p-0 list-none;

                li {
                    a {
                        @apply flex text-white cursor-pointer py-4 px-6 text-sm;
                    }

                    &:hover {
                        background: linear-gradient(90deg, theme('colors.primary') 2%, #666666 2%);
                    }
                }
            }

            &.nav-open {
                @apply block;
            }
        }

        @screen lg {
            &:hover, &:active, &:focus {
                nav {
                    @apply block;
                }
            }
        }
    }
}
</style>