<template>
    <div class="mobile-menu-container">
        <router-link v-if="$store.getters.hasPermission('read articles')" :to="{name: 'articles-index'}" class="nav-item"
                     @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'sliders-v-square']"/>
            </div>
            <p>{{$t('nav.articles')}}</p>
        </router-link>
        <router-link v-if="$store.getters.hasPermission('read blinds') && $store.getters.hasRole('agent') === false"  :to="{name: 'blinds-index'}" class="nav-item"
                     @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'blinds']"/>
            </div>
            <p>{{$t('nav.blinds')}}</p>
        </router-link>
        <router-link :to="{name: 'calculators-index'}" class="nav-item"
                     @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'calculator']"/>
            </div>
            <p>{{$t('nav.calculators')}}</p>
        </router-link>
        <router-link v-if="$store.getters.hasPermission('read categories')" :to="{name: 'categories-index'}" class="nav-item"
                     @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'list-alt']"/>
            </div>
            <p>{{$t('nav.categories')}}</p>
        </router-link>
        <router-link v-if="$store.getters.hasPermission('read fabrics')" :to="{name: 'fabrics-index'}" class="nav-item"
                     @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'booth-curtain']"/>
            </div>
            <p>{{$t('nav.fabrics')}}</p>
        </router-link>
        <router-link v-if="$store.getters.hasPermission('read orders') && $store.getters.hasRole('agent') === false" :to="{name: 'orders-index'}" class="nav-item"
                     @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'clipboard-list']"/>
            </div>
            <p>{{$t('nav.orders')}}</p>
        </router-link>
        <router-link  v-if="$store.getters.hasPermission('read roles')" :to="{name: 'roles-index'}" class="nav-item"
                      @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'key']"/>
            </div>
            <p>{{$t('nav.roles_permissions')}}</p>
        </router-link>
        <router-link v-if="$store.getters.hasPermission('read users')" :to="{name: 'users-index'}" class="nav-item"
                     @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'user-friends']"/>
            </div>
            <p>{{$t('nav.users')}}</p>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "MobileMenu"
}
</script>

<style lang="scss" scoped>
.mobile-menu-container {
    @apply flex-1 grid grid-cols-2 content-start gap-y-12 gap-x-8 h-auto p-8 bg-white;

    @screen sm {
        @apply grid-cols-3;
    }

    @screen md {
        @apply hidden;
    }

    .nav-item {
        @apply flex flex-col items-center;

        .icon-container {
            @apply p-2 rounded-lg border-2 border-grey-light flex flex-col items-center justify-center;
            height: 40px;
            width: 40px;

            svg {
                @apply text-black w-auto;

                height: 18px;
            }
        }

        p {
            @apply text-black text-sm font-bold mt-4 text-center;
        }

        &:hover, &:focus {
            .icon-container {
                @apply border-primary;
            }
        }

        &.router-link-active {
            .icon-container {
                @apply bg-primary border-primary;

                svg {
                    @apply text-white;
                }
            }
        }
    }
}
</style>