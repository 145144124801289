<template>
  <loading-screen v-if="is_loading_blinds"></loading-screen>
  <div v-else class="page-container">
    <div class="title-container">
      <router-link :to="{name: 'calculators-index'}">
        <font-awesome-icon :icon="['far', 'arrow-circle-left']"/>
      </router-link>
      <h1>{{ $t('calculators.choose_zebra_blind_size') }}</h1>
    </div>

    <div class="tiles-wrapper">
      <div class="tiles-container">
        <div class="tile-container" v-if="blinds.some(blind => blind.attributes.type === 'zebra_blind')">
          <div class="tile">
            <p class="title">{{ blinds[blinds.findIndex(blind => blind.attributes.type === 'zebra_blind')].attributes.name }}</p>
            <router-link :to="{name: 'zebra-blind', query: {id: blinds[blinds.findIndex(blind => blind.attributes.type === 'zebra_blind')].id, quote: this.$route.query.quote}}">
              <font-awesome-icon :icon="['far', 'arrow-circle-right']"/>
            </router-link>
          </div>
        </div>

        <div class="tile-container" v-if="blinds.some(blind => blind.attributes.type === 'zebra_blind_2')">
          <div class="tile">
            <p class="title">{{ blinds[blinds.findIndex(blind => blind.attributes.type === 'zebra_blind_2')].attributes.name }}</p>
            <router-link :to="{name: 'zebra-blind-2', query: {id: blinds[blinds.findIndex(blind => blind.attributes.type === 'zebra_blind_2')].id, quote: this.$route.query.quote}}">
              <font-awesome-icon :icon="['far', 'arrow-circle-right']"/>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingScreen from "@/components/LoadingScreen";
export default {
  name: "RollerBlindChoice",
  components: {LoadingScreen},
  data() {
    return {
      blinds: [],
      is_loading_blinds: false
    }
  },
  methods: {
    retrieveBlinds() {
      this.is_loading_blinds = true;
      this.$axios.get(`users/${this.$store.getters.user.id}/blinds`)
          .then(({data}) => {
            this.blinds = data.data;
            this.is_loading_blinds = false;
          })
          .catch(e => {
            this.is_loading_blinds = false;

            this.$notify({
              title: this.$t('error'),
              text: this.$t('blinds.error_retrieve_blind_types'),
              type: 'error',
            });
          });
    }
  },
  mounted() {
    this.retrieveBlinds();
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  @apply py-8;

  @screen lg {
    @apply py-16;
  }

  .title-container {
    @apply flex flex-row mx-auto max-w-4xl w-full items-start justify-center;

    @screen lg {
      @apply items-end
    }

    a {
      @apply text-primary text-2xl cursor-pointer mt-4 mr-auto pl-6;

      @screen md {
        @apply pl-5;
      }

      svg {

        &:hover {
          animation: spin 0.2s linear;
          animation-direction: reverse;

        }
      }
    }

    h1 {
      @apply font-bold text-center text-2xl mt-4 mr-auto pr-11;
      max-width: 16rem;

      @screen lg {
        @apply text-3xl max-w-full;
      }
    }
  }

  .tiles-wrapper {
    @apply max-w-4xl mt-8 mx-auto w-full;

    .tiles-container {
      @apply flex flex-row flex-wrap w-full;

      @screen lg {
        @apply justify-center;
      }

      .tile-container {
        @apply w-full py-5;

        @screen lg {
          @apply w-1/3 p-5;
        }

        .tile {
          @apply w-full bg-white rounded-xl flex flex-col h-24 mx-auto;
          box-shadow: 0px 10px 15px #00000012;
          max-width: 16rem;

          @screen lg {
            @apply rounded-xl flex flex-col h-28 max-w-full;
            box-shadow: 0px 10px 15px #00000012;
          }

          .title {
            @apply text-center text-lg font-bold mb-1.5 my-auto mt-4;

          }

          a {
            @apply text-primary text-2xl mx-auto cursor-pointer my-auto mb-8;

            svg {
              &:hover {
                animation: spin 0.2s linear;
              }
            }
          }

        }
      }


    }

  }

}
</style>