<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:right>
                <Search class="search-desktop" :term="searchTerm" :placeholder="$t('orders.search_orders')" @search="search"/>
                <button class="btn-search-mobile btn-icon-only"
                        @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>
            <template v-slot:right>
                <div class="select">
                    <p class="label">Client</p>
                    <Select :placeholder="$t('orders.company')" :custom-label="(row) => row.attributes.name + ' - ' + row.attributes.company"
                            v-model="company" :options="companyOptions" :allowEmpty="true" :allow-empty="true"
                            @input="onFilterUpdated" class="select-dropdown"/>
                </div>

                <div class="select">
                    <p class="label">Status</p>
                    <Select :placeholder="$t('orders.status')" :custom-label="(row) => row.name"
                            v-model="status" :options="statusOptions" :allowEmpty="true" :allow-empty="true" track-by="name"
                            @input="onFilterUpdated"
                            class="select-dropdown"/>
                </div>

                <Search class="search-desktop" :placeholder="$t('orders.search_orders')" @search="search"/>
                <button class="btn-search-mobile btn-icon-only"
                        @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>
            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <Search v-if="headbarExpanded === 'search'" :placeholder="$t('orders.search_orders')" @search="search"/>
                </div>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                mode="remote"
                styleClass="vgt-table vgt-custom"
                :columns="columns"
                :rows="orders"
                :isLoading.sync="is_loading_orders"
                :search-options="{
                    enabled: false,
                }"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 15,
                    perPageDropdownEnabled: false,
                    setCurrentPage: table_page,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('articles.articles')}),
                }"
                :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                }"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'status'">
                        <p v-if="props.row.attributes.status.trim() == 'on_hold'" class="on-hold-badge" style="text-transform: capitalize;">On Hold</p>
                        <p v-else style="text-transform: capitalize;">{{orderStatus(props.row.attributes.status.trim())}}</p>
                    </div>
                    <div v-else-if="props.column.field === 'value'">
                        <p style="text-transform: capitalize;">€{{props.row.attributes.value.toFixed(2)}}</p>
                    </div>
                    <div v-else-if="props.column.field === 'after'" class="td-after">
                        <Button v-if="$store.getters.hasPermission('update orders')"
                                className="--secondary --outline --mini --big-text"
                                :onclick="()=>pushUpdate(props.row.id)">
                            <font-awesome-icon :icon="['fal', 'pencil']"/>
                        </Button>

                        <Button v-if="$store.getters.hasPermission('update orders')"
                                className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleCancel(props.row)">
                            <font-awesome-icon :icon="['fal', 'ban']"/>
                        </Button>

                        <Button v-if="$store.getters.hasPermission('destroy orders')"
                                className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else>{{props.formattedRow[props.column.field]}}</span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import ArticlesCreateModal from "../../components/articles/ArticlesCreateModal";
import ArticlesUpdateModal from "../../components/articles/ArticlesUpdateModal";
import _ from "lodash";

export default {
    name: "orders-index-page",
    components: {ConfirmModal, Select, Button, Search, Headbar},
    data: function () {
        const columns = [
            {
                label: this.$t('orders.order_id'),
                field: 'attributes.reference',
                sortable: false,
            },
            {
                label: this.$t('orders.date_time'),
                field: row => this.$moment.utc(row.attributes.confirmed_at).local().format('DD/MM/YYYY - HH:mm'),
                sortable: false,
            },
            {
                label: this.$t('orders.status'),
                field: 'status',
                sortable: false,
            },
            {
                label: this.$t('orders.client'),
                field: row => row.attributes.client_details ? row.attributes.client_details.name : row.relationships.customer.data.attributes.company,
                sortable: false,
            },
            {
                label: this.$t('orders.contact'),
                field: row => row.attributes.client_details ? row.attributes.client_details.phone : row.relationships.customer.data.attributes.phone,
                sortable: false,
            },
            {
                label: this.$t('orders.sales_person'),
                field: row => row.relationships.customer.data.attributes.name,
                sortable: false,
            },
            {
                label: this.$t('orders.value'),
                field: 'value',
                sortable: false,
            },
        ];

        if (this.$store.getters.hasAnyPermission(['update orders', 'destroy orders']))
            columns.push({
                label: this.$t('orders.actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false
            });

        return {
            columns: columns,
            orders: [],
            is_loading_orders: false,
            is_loading_statuses: false,
            totalRecords: null,
            serverParams: {},
            searchTerm: null,
            is_deleting: false,
            statusOptions: [],
            companyOptions: [],
            status: null,
            company: null,
            headbarExpanded: false,
            table_page: 1,
        }
    },
    methods: {
        pushUpdate(id) {
            this.$router.push({name: 'orders-update', params: {id: id}});
        },
        toggleCancel(order) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('orders.cancel_order'),
                    message: this.$t('orders.prompt_cancel'),
                    confirmText: this.$t('delete'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.patch(`orders/${order.id}`, {status: 'cancelled'}).then(({data}) => {
                                this.retrieveOrders();
                            }).catch(e => {
                                this.$notify({
                                    title: this.$t('error'),
                                    text: this.$larerror(e.response.data.errors, this.$t('cart.error_cancel_order')),
                                    type: 'error',
                                });
                            });
                        }
                    }
                }
            );
        },
        toggleDelete(order) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('orders.delete_order'),
                    message: this.$t('orders.prompt_delete'),
                    confirmText: this.$t('delete'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.delete(`orders/${order.id}`).then(({data}) => {
                                this.retrieveOrders();
                            }).catch(e => {
                                this.$notify({
                                    title: this.$t('error'),
                                    text: this.$larerror(e.response.data.errors, this.$t('orders.error_delete_order')),
                                    type: 'error',
                                });
                            });
                        }
                    }
                }
            );
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.retrieveOrders();
        },
        onSortChange(params) {
            const sorts = [];

            if (!params || !params.length) {
                this.updateParams({sorting: []});
                return this.retrieveOrders();
            }

            params.forEach(p => {
                if (!p.type || p.type === 'none')
                    return;

                let sort_by = null;
                let sort_order = p.type;
                if (p.field === 'attributes.name')
                    sort_by = 'name';
                else if (p.field === 'attributes.description')
                    sort_by = 'description';
                else if (p.field === 'attributes.code')
                    sort_by = 'code';
                else if (p.field === 'category')
                    sort_by = 'category';
                else
                    sort_by = p.field.split('.')[1];

                sorts.push({sort_order, sort_by})
            });

            this.updateParams({sorting: sorts});
            this.retrieveOrders();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length) {
                this.updateParams({term: searchTerm});
            } else this.removeParam('term');

            this.onPageChange({currentPage: 1});
            this.retrieveOrders();
        },

        orderStatus(statusValue){
            return _.startCase(statusValue)
        },

        onFilterUpdated() {
            this.searchTerm = null;

            this.removeParam('term');

            const filters = [];

            if (this.status) {
                filters.push({
                    filter_by: 'status',
                    filter_value: this.status.column
                })
            }

            if (this.company) {
                filters.push({
                    filter_by: 'customer_id',
                    filter_value: this.company.id
                })
            }

            if (filters.length)
                this.updateParams({filters: filters})
            else
                this.removeParam('filters');

            this.retrieveOrders();
        },
        async retrieveCompanies() {
            this.is_loading_companies = false;
            await this.$axios.get('users/list')
                .then(({data}) => {
                    this.companyOptions = data.data;
                    this.is_loading_companies = false;
                })
                .catch(e => {
                    this.is_loading_companies = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('orders.error_retrieve_companies')),
                        type: 'error',
                    });
                });
        },
        async retrieveStatusOptions() {
            this.is_loading_statuses = false;
            await this.$axios.get('orders/list-statuses')
                .then(({data}) => {
                    this.statusOptions = data;
                    this.is_loading_statuses = false;
                })
                .catch(e => {
                    this.is_loading_statuses = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('orders.error_retrieve_statuses')),
                        type: 'error',
                    });
                });
        },
        retrieveOrders() {
            this.is_loading_orders = true;

            this.$axios.get('orders', {params: {...this.serverParams}})
                .then(({data}) => {
                    this.orders = data.data;

                    this.table_page = parseInt(data.meta.current_page);
                    this.$router.replace({ name: 'orders-index', query: { page: data.meta.current_page }});

                    this.totalRecords = data.meta.total;
                    this.is_loading_orders = false;
                })
                .catch(e => {
                    this.is_loading_orders = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('orders.error_retrieve')),
                        type: 'error',
                    });
                });
        },
    },
    async mounted() {
        await this.retrieveStatusOptions();
        await this.retrieveCompanies();

        if(this.$route.query.page) {
            this.updateParams({page: this.$route.query.page});
        }

        this.retrieveOrders();
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.orders')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
    .page-container {
        .label {
            @apply text-sm;
        }

        .on-hold-badge{
            @apply bg-primary p-2 rounded-md text-white text-center;
            width: fit-content;
        }

        .headbar-expanded-container {
            @apply mt-4 flex flex-col gap-y-4 items-center;

            @screen md {
                @apply hidden;
            }
        }

        .select-dropdown {
            @apply min-w-40;
        }

        .btn-search-mobile, .btn-filter-mobile {
            @apply block text-primary w-6;

            @screen md {
                @apply hidden;
            }

            &:active, &:focus {
                @apply outline-none;
            }
        }

        .search-desktop, .filter-desktop {
            @apply hidden;

            @screen md {
                @apply block;
            }
        }

        .search-container {
            @apply mr-0;
        }

        .filter-mobile {
            @apply max-w-xs;
        }

        .td-after {
            @apply flex flex-row;

            & > * {
                @apply mr-3;

                &:last-child {
                    @apply mr-0;
                }
            }
        }
    }
</style>
