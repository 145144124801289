<template>
    <div class="articles-update-modal">
        <ModalContainer :title="$t('articles.edit_article')" identifier="articles-update-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputText v-model="$v.article.name.$model" identifier="name" :label="$t('articles.name')"
                               :placeholder="$t('articles.name')" :disabled="is_saving"
                               :has-error="$v.article.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.article.name.required">
                            {{$t('validation.x_is_required',{x: $t('articles.name')})}}
                        </p>
                    </template>
                </FormInputText>
                <FormGroupTwo>
                    <FormInputText v-model="$v.article.description.$model" identifier="description" :label="$t('articles.description')"
                                   :placeholder="$t('articles.description')" :disabled="is_saving"
                                   :has-error="$v.article.description.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.article.description.required">
                                {{$t('validation.x_is_required',{x: $t('articles.description')})}}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputSelect v-model="$v.article.category.$model" identifier="category"
                                     :label="$t('articles.category')" :options="categoryOptions"
                                     :placeholder="$t('articles.category')" :disabled="is_saving"
                                     :has-error="$v.article.category.$error" track-by="id"
                                     :display-custom-label="(row) => `${row.attributes.name}`"
                                     class="select-container">
                        <template v-slot:errors>
                            <p v-if="!$v.article.category.required">
                                {{$t('validation.x_is_required',{x: $t('articles.category')})}}
                            </p>
                        </template>
                    </FormInputSelect>
                    <FormInputText v-model="$v.article.code.$model" identifier="code" :label="$t('articles.code')"
                                   :placeholder="$t('articles.code')" :disabled="is_saving"
                                   :has-error="$v.article.code.$error" autocomplete="off">
                        <template v-slot:errors>
                            <p v-if="!$v.article.code.required">
                                {{$t('validation.x_is_required',{x: $t('articles.code')})}}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputText v-model="$v.article.price.$model" identifier="price" :label="$t('articles.retail_price') + ' (€ incl. VAT)'"
                                   :placeholder="$t('articles.price')" :disabled="is_saving"
                                   :has-error="$v.article.price.$error" autocomplete="off">
                        <template v-slot:errors>
                            <p v-if="!$v.article.price.required">
                                {{$t('validation.x_is_required',{x: $t('articles.price')})}}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputSelect v-model="$v.article.unit.$model" identifier="unit"
                                     :label="$t('articles.unit')" :options="unitOptions"
                                     :placeholder="$t('articles.unit')" :disabled="is_saving"
                                     :has-error="$v.article.unit.$error" track-by="name"
                                     :display-custom-label="(row) => `${row.name}`"
                                     class="select-container">
                        <template v-slot:errors>
                            <p v-if="!$v.article.unit.required">
                                {{$t('validation.x_is_required',{x: $t('articles.unit')})}}
                            </p>
                        </template>
                    </FormInputSelect>
                    <FormInputText v-model="$v.article.multiplier.$model" identifier="multiplier" :label="$t('articles.multiplier')"
                                   :placeholder="$t('articles.multiplier')" :disabled="is_saving"
                                   :has-error="$v.article.multiplier.$error" autocomplete="off">
                        <template v-slot:errors>
                            <p v-if="!$v.article.multiplier.required">
                                {{$t('validation.x_is_required',{x: $t('articles.multiplier')})}}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputText v-if="article.unit && article.unit.column === 'per_unit'" v-model="$v.article.quantity.$model" identifier="quantity" :label="$t('articles.quantity')"
                                   :placeholder="$t('articles.quantity')" :disabled="is_saving"
                                   :has-error="$v.article.quantity.$error" autocomplete="off">
                        <template v-slot:errors>
                            <p v-if="!$v.article.quantity.required">
                                {{$t('validation.x_is_required',{x: $t('articles.quantity')})}}
                            </p>
                        </template>
                    </FormInputText>
                </FormGroupTwo>

                <div class="checkbox-container" v-if="article.unit && article.unit.column === 'per_cm'">
                    <p-check v-model="article.on_width" class="p-icon p-curve" color="primary" @change="inputCheckbox('width')"><font-awesome-icon class="icon mdi mdi-check" slot="extra" :icon="['far', 'check']"/>{{$t('articles.on_width')}}</p-check>
                    <p-check v-model="article.on_drop" class="p-icon p-curve" color="primary" @change="inputCheckbox('drop')"><font-awesome-icon class="icon mdi mdi-check" slot="extra" :icon="['far', 'check']"/>{{$t('articles.on_drop')}}</p-check>
                </div>

                <p-check v-model="article.is_ignored" class="ignore-check p-icon p-curve" color="primary"><font-awesome-icon class="icon mdi mdi-check" slot="extra" :icon="['far', 'check']"/>{{$t('articles.ignore_when_motor_selected')}}</p-check>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import _ from 'lodash';
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import FormInputTextSelect from "../form/FormInputTextSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";

export default {
    name: "ArticlesUpdateModal",
    components: {FormInputPassword, Button, FormInputSelect, FormInputTextSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    props: {
        article_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            original: null,
            article: {
                name: null,
                description: null,
                code: null,
                price: null,
                category: null,
                unit: null,
                multiplier: null,
                quantity: null,
                on_width: false,
                on_drop: false,
                is_ignored: false,
            },
            is_saving: false,
            categoryOptions: [],
            unitOptions: [],
            is_loading_categories: false,
            is_loading_units: false,
            is_loading_original: false,
        }
    },
    validations: {
        article: {
            name: {required},
            description: {},
            code: {required},
            price: {required},
            category: {},
            unit: {},
            multiplier: {required},
            quantity: {},
            on_width: {},
            on_drop: {},
            is_ignored: {},
        }
    },
    methods: {
        inputCheckbox(type) {
            if(type === 'width' && this.article.on_drop)
                this.article.on_drop = false;
            else if (type === 'drop' && this.article.on_width)
                this.article.on_width = false;
        },
        close(status) {
            this.$modal.hide('articles-update-modal', status);
        },
        save() {
            this.$v.article.$touch();
            if (this.$v.article.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = Object.assign({}, this.$v.article.$model);

            if(this.article.category)
                payload.category_id = [this.$v.article.category.$model.id][0];
            else
                payload.category_id = null;

            if(this.article.unit && this.article.unit.column)
                payload.unit = this.article.unit.column;

            this.$axios.patch(`articles/${this.article_id}`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('articles.success_updated'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: $t('articles.error_update'),
                    type: 'error',
                });
            });
        },
        populate() {
            if (!this.original || !this.article)
                return;

            this.$v.article.name.$model = this.original.attributes.name;

            if(this.original.attributes.description)
                this.$v.article.description.$model = this.original.attributes.description;

            this.$v.article.code.$model = this.original.attributes.code;
            this.$v.article.price.$model = this.original.attributes.price;

            if(this.original.relationships.category && this.original.relationships.category.data && this.original.relationships.category.data.id)
                this.$v.article.category.$model = _.find(this.categoryOptions, {id: this.original.relationships.category.data.id});

            this.$v.article.unit.$model = _.find(this.unitOptions, {column: this.original.attributes.unit})

            if(this.original.attributes.multiplier)
                this.$v.article.multiplier.$model = this.original.attributes.multiplier;

            if(this.original.attributes.quantity)
                this.$v.article.quantity.$model = this.original.attributes.quantity;

            this.$v.article.on_width.$model = this.original.attributes.on_width;
            this.$v.article.on_drop.$model = this.original.attributes.on_drop;

            this.$v.article.is_ignored.$model = this.original.attributes.is_ignored;
        },
        async retrieveOriginalArticle() {
            this.is_loading_original = false;
            await this.$axios.get(`articles/${this.article_id}`)
                .then(({data}) => {
                    this.is_loading_original = false;
                    this.original = data.data;
                })
                .catch(e => {
                    this.is_loading_original = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('articles.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveCategoryOptions() {
            this.is_loading_categories = false;
            await this.$axios.get('categories/list')
                .then(({data}) => {
                    this.categoryOptions = data.data;
                    this.is_loading_categories = false;
                })
                .catch(e => {
                    this.is_loading_categories = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('categories.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveUnitOptions() {
            this.is_loading_units = false;
            await this.$axios.get('articles/list-units')
                .then(({data}) => {
                    this.unitOptions = data;
                    this.is_loading_units = false;
                })
                .catch(e => {
                    this.is_loading_units = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('articles.error_retrieve_units')),
                        type: 'error',
                    });
                });
        }
    },

    async mounted() {
        await this.retrieveOriginalArticle();
        await this.retrieveCategoryOptions();
        await this.retrieveUnitOptions();
        this.populate();
    }
}
</script>

<style lang="scss">
    .checkbox-container {
        @apply flex flex-row mb-8 mt-2;

        .pretty {
            &:last-of-type {
                @apply ml-8;
            }
        }
    }

    .ignore-check {
        @apply mb-8;
    }
</style>
