<template>
  <div class="assign-measurement-modal">
    <ModalContainer :title="$t('blinds.add_measurement')" identifier="assign-measurement-modal" :closable="true">
      <Form class="form" @submit="save" :disabled="is_saving">
        <FormInputSelect v-model="$v.measurement.$model" identifier="measurement"
                         :label="$t('blinds.measurement')" :options="measurementOptions"
                         :placeholder="$t('blinds.measurement')" :disabled="is_saving"
                         :has-error="$v.measurement.$error" track-by="id"
                         :display-custom-label="(row) => `${row.name}`"
                         class="select-container">
          <template v-slot:errors>
            <p v-if="!$v.measurement.required">
              {{$t('validation.x_is_required',{x: $t('blinds.measurement')})}}
            </p>
          </template>
        </FormInputSelect>

        <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
          {{$t('add')}}
        </Button>
      </Form>
    </ModalContainer>
  </div>
</template>

<script>
import _ from 'lodash';
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import FormInputTextSelect from "../form/FormInputTextSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";
import {measurementsOptions} from "@/utils/MeasurementUtils";

export default {
  name: "AssignMeasurementModal",
  components: {FormInputPassword, Button, FormInputSelect, FormInputTextSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
  props: {
    measurements: {
      type: Array,
      default: () => ([]),
    }
  },
  data() {
    return {
      measurement: null,
      measurementOptions: [],
      is_loading_measurements: false,
      is_saving: false
    }
  },
  validations: {
    measurement: {required}
  },
  methods: {
    close(status) {
      this.$modal.hide('assign-measurement-modal', status);
    },
    save() {
      this.$v.measurement.$touch();
      if (this.$v.measurement.$anyError || this.is_saving)
        return;

      this.close({status: true, item: this.measurement});
    },
    retrieveMeasurementOptions() {
      this.measurementOptions = JSON.parse(JSON.stringify(measurementsOptions))
      this.measurementOptions = this.measurementOptions.filter(item1 => {
        return !this.measurements.some(item2 => item2.id === item1.id);
      });
    },
  },

  mounted() {
    this.retrieveMeasurementOptions();
  }
}
</script>

<style lang="scss">
.checkbox-container {
  @apply flex flex-row mb-8 mt-2;

  .pretty {
    &:last-of-type {
      @apply ml-8;
    }
  }
}
</style>
