<template>
    <loading-screen v-if="is_loading_quotes"></loading-screen>
    <div v-else class="page-container">
        <div class="page-content-container">
            <div class="header-container">
                <div class="left">
                    <router-link :to="{name: 'calculators-index'}">
                        <font-awesome-icon :icon="['far', 'home-lg-alt']"/>
                    </router-link>
                    <h1>{{ $t('nav.quotes') }}</h1>
                </div>
                <div class="right">
                    <FormInputSelect v-model="status" identifier="statuses" :options="statusOptions"
                                     :placeholder="$t('quotes.status')" display-label="name" class="select"
                                     @input="onFilterUpdated"/>

                    <Search class="search-desktop" :placeholder="$t('quotes.search_quotes')" @search="search"/>
                    <button class="btn-search-mobile btn-icon-only" @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                        <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                        <font-awesome-icon v-else :icon="['far', 'search']"/>
                    </button>
                </div>
            </div>
            <div class="headbar-expanded-container">
                <Search v-show="headbarExpanded === 'search'" :placeholder="$t('articles.search_articles')" @search="search"/>
            </div>

            <div class="no-quotes-found" v-if="quotes.length <= 0">
                <p>{{$t('quotes.no_quotes_found')}}</p>
            </div>

            <div v-else-if="quotes.length" class="quote-details-wrapper">
                <div class="quote-details-container">
                    <div class="title-row">
                        <p>{{$t('quotes.quote_id')}}</p>
                        <p>{{$t('quotes.date_issued')}}</p>
                        <p>{{$t('quotes.client')}}</p>
                        <p>{{$t('quotes.contact_number')}}</p>
                        <p>{{$t('quotes.value')}}</p>
                    </div>

                    <div class="quote-row" v-for="quote in quotes">
                        <div class="details">
                            <p>{{quote.attributes.reference}}</p>
                            <p>{{quote.attributes.created_at | date}}</p>
                            <p>{{quote.attributes.client_details.name}}</p>
                            <p>{{quote.attributes.client_details.phone}}</p>
                            <p>€{{quote.attributes.value.toFixed(2)}}</p>
                        </div>

                        <div class="icons-container">
<!--                            <font-awesome-icon :icon="['far','file-alt']" :content="$t('quotes.switch_to_order')" v-tippy="{ placement : 'top',  arrow: true }" @click="toggleChangeToOrder"/>-->
                            <font-awesome-icon @click="generateReport(quote)" :icon="['fas','print']" :content="$t('quotes.print')" v-tippy="{ placement : 'top',  arrow: true }" :class="{disabled: is_printing_quote}"/>
                            <font-awesome-icon @click="visitQuote(quote)" :icon="['far','pencil']" :content="$t('quotes.edit')" v-tippy="{ placement : 'top',  arrow: true }"/>
                            <font-awesome-icon @click="toggleCancel(quote)" class="delete-icon" :icon="['far','times-circle']" :content="$t('quotes.cancel_quote')" v-tippy="{ placement : 'top',  arrow: true }" :class="{disabled: quote.attributes.status === 'cancelled' || quote.attributes.status === 'switched_to_order' || quote.is_deleting}"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pagination-wrapper" v-if="quotes.length">
                <p @click="onPageChange(1)" :class="{disabled: current_page === 1}">{{$t('pagination.first')}}</p>
                <p @click="onPageChange(serverParams.page - 1)" :class="{disabled: current_page <= 1}">{{$t('pagination.prev')}}</p>

                <p v-if="current_page > 1" :class="{active: serverParams.page === current_page-1}" @click="onPageChange(current_page-1)">{{current_page-1}}</p>

                <p :class="{active: serverParams.page === current_page}" @click="onPageChange(current_page)">{{current_page}}</p>

                <p v-if="current_page < last_page" :class="{active: serverParams.page === current_page+1}" @click="onPageChange(current_page+1)">{{current_page+1}}</p>

                <p @click="onPageChange(serverParams.page + 1)" :class="{disabled: current_page >= last_page}">{{$t('pagination.next')}}</p>
                <p @click="onPageChange(last_page)" :class="{disabled: current_page === last_page}">{{$t('pagination.last')}}</p>
            </div>
        </div>

        <vue-html2pdf
            :float-layout="true"
            :show-layout="false"
            :enable-download="true"
            :filename="`${quote_details ? quote_details.attributes.reference : ''}`"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-orientation="landscape"
            pdf-content-width="1120px"
            ref="html2Pdf"
        >
            <section slot="pdf-content" class="pdf-content">
                <div v-for="(array, index) in printing_pages" class="printing-page">
                    <div class="top-row">
                        <h1 class="reference" v-if="quote_details">{{ quote_details.attributes.reference }} - Blinds Qty {{ quote_details.relationships.blinds.data.length }} - Page {{ index + 1 }} / {{ printing_pages.length }} </h1>

                        <div class="sales-person-container" v-if="index === 0 && quote_details && quote_details.relationships && quote_details.relationships.user && quote_details.relationships.user.data">
                            <p><span>SP:</span> {{ quote_details.relationships.user.data.attributes.name }}</p>
                            <p><span>Company:</span> {{ quote_details.relationships.user.data.attributes.company }}</p>
                        </div>
                    </div>

                    <div class="order-details-container" v-if="quote_details && quote_details.relationships && quote_details.relationships.user && quote_details.relationships.user.data && index === 0">
                        <div class="billing-details-container">
                            <div class="card-title-container">
                                <h1 class="card-title">{{$t('cart.client_invoicing_details')}}</h1>
                            </div>

                            <div class="user-details-wrapper">
                                <div class="user-details-container">
                                    <div class="user-detail">
                                        <p class="label">{{ $t('users.date') }}:</p>
                                        <p>{{ $moment.utc(quote_details.attributes.created_at).local().format('DD/MM/YYYY - HH:mm') }}</p>
                                    </div>

                                    <div class="user-detail" v-if="quote_details.attributes.client_details.name">
                                        <p class="label">{{ $t('users.name') }}:</p>
                                        <p>{{ quote_details.attributes.client_details.name }}</p>
                                    </div>

                                    <div class="user-detail" v-if="quote_details.attributes.client_details.phone">
                                        <p class="label">{{ $t('users.contact') }}:</p>
                                        <p>{{ quote_details.attributes.client_details.phone }}</p>
                                    </div>
                                </div>

                                <div class="user-detail" v-if="quote_details.attributes.client_details.address">
                                    <p class="label">{{ $t('users.address') }}:</p>
                                    <p>{{ quote_details.attributes.client_details.address }}</p>
                                </div>

                                <div class="user-detail" v-if="quote_details.attributes.client_details.email">
                                    <p class="label">{{ $t('users.email_address') }}:</p>
                                    <p>{{ quote_details.attributes.client_details.email }}</p>
                                </div>

                                <div class="user-details-container" v-if="quote_details.attributes.client_details.company || quote_details.attributes.client_details.vat_num">
                                    <div class="user-detail" v-if="quote_details.attributes.client_details.company">
                                        <p class="label" >{{ $t('users.company_name') }}:</p>
                                        <p>{{ quote_details.attributes.client_details.company }}</p>
                                    </div>

                                    <div class="user-detail" v-if="quote_details.attributes.client_details.vat_num">
                                        <p class="label" >{{ $t('users.vat_number') }}:</p>
                                        <p>{{ quote_details.attributes.client_details.vat_num }}</p>
                                    </div>
                                </div>

                                <div class="user-detail" v-if="quote_details.attributes.note">
                                    <p class="label">{{ $t('cart.note') }}:</p>
                                    <p>{{ quote_details.attributes.note }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="price-breakdown-container">
                            <div class="card-title-container">
                                <h1 class="card-title">{{$t('cart.order_cost_summary')}}</h1>
                            </div>

                            <div class="details-container">
                                <div class="row">
                                    <p class="label">{{$t('cart.sub_total_1')}}</p>
                                    <div class="price">€{{blinds_total.toFixed(2)}}</div>
                                </div>

                                <div class="row" v-if="quote_details.attributes.discount">
                                    <p class="label">{{$t('cart.discount')}} ({{quote_details.attributes.discount}}%)</p>
                                    <div class="price">€{{(blinds_total * (quote_details.attributes.discount / 100)).toFixed(2)}}</div>
                                </div>

                                <div class="row" v-if="quote_details.attributes.installation">
                                    <p class="label">{{$t('cart.installation')}}</p>
                                    <div class="price">€{{quote_details.attributes.installation.toFixed(2)}}</div>
                                </div>

                                <div class="row">
                                    <p class="label">{{$t('cart.sub_total_2')}}</p>
                                    <div class="price">€{{(blinds_total - (quote_details.attributes.discount ? (blinds_total * (quote_details.attributes.discount / 100)).toFixed(2) : 0) + quote_details.attributes.installation).toFixed(2)}}</div>
                                </div>

                                <div class="row bottom-border" v-if="quote_details.attributes.deposit">
                                    <p class="label">{{$t('cart.deposit')}}</p>
                                    <div class="price">€{{quote_details.attributes.deposit.toFixed(2)}}</div>
                                </div>

                                <div class="row">
                                    <p class="label">{{$t('cart.balance_inc_vat')}}</p>
                                    <div class="price">€{{quote_details.attributes.value.toFixed(2)}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="item-cards-container">
                        <div class="item-card-container" v-for="item in array">
                            <item-card-printing class="item-card-wrapper" :item="item"/>
                        </div>
                    </div>

                    <div v-if="index !== printing_pages.length-1" class="html2pdf__page-break"/>
                </div>
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
import Button from "../../../components/Button";
import Search from "../../../components/Search";
import ConfirmModal from "@/components/modal/ConfirmModal";
import LoadingScreen from "@/components/LoadingScreen";
import moment from "moment";
import VueHtml2pdf from 'vue-html2pdf';
import ItemCardPrinting from "@/components/calculators/ItemCardPrinting";
import _ from "lodash";
import FormInputSelect from "@/components/form/FormInputSelect";

export default {
    name: "MyOrders",
    components: {FormInputSelect, LoadingScreen, Button, Search, VueHtml2pdf, ItemCardPrinting},
    data() {
        return {
            acceptedStatuses: ['processing', 'on_hold'],
            quotes: [],
            quote_details: null,
            blinds_total: 0,
            printing_pages: [],
            statusOptions: [],
            status: null,
            current_page: 1,
            last_page: 999,
            serverParams: {
                page: 1,
                filters: [
                    {
                        filter_by: 'status',
                        filter_operator: '!=',
                        filter_value: 'switched_to_order'
                    }
                ]
            },
            is_loading_quotes: false,
            is_printing_quote: false,
            headbarExpanded: false
        }
    },
    methods: {
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length) {
                this.updateParams({term: searchTerm});
            } else this.removeParam('term');

            this.getQuotes(true);
        },
        onFilterUpdated() {
            const filters = [
                {
                    filter_by: 'status',
                    filter_operator: '!=',
                    filter_value: 'switched_to_order'
                }
            ]

            if (this.status) {
                filters.push({
                    filter_by: 'status',
                    filter_value: this.status.column
                })
            }

            if (filters.length)
                this.updateParams({filters: filters})
            else
                this.removeParam('filters');

            this.getQuotes();
        },
        toggleCancel(quote) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('quotes.cancel_quote'),
                    message: this.$t('quotes.are_you_sure_cancel_quote'),
                    confirmText: this.$t('yes'),
                    cancelText: this.$t('no'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.$axios.patch(`quotes/${quote.id}`, {status: 'cancelled'}).then(({data}) => {
                                this.getQuotes();
                            }).catch(e => {
                                this.$notify({
                                    title: this.$t('error'),
                                    text: this.$larerror(e.response.data.errors, this.$t('quotes.error_cancel_quote')),
                                    type: 'error',
                                });
                            });
                        }
                    }
                }
            );
        },
        async generateReport(quote) {
            this.is_printing_quote = true;
            this.blinds_total = 0;

            this.$axios.get(`quotes/${quote.id}`)
                .then(async ({data}) => {
                    this.quote_details = data.data;

                    await this.quote_details.relationships.blinds.data.sort((a, b) => a.attributes.order - b.attributes.order);

                    if (this.quote_details.relationships.blinds.data.length) {
                        await this.quote_details.relationships.blinds.data.forEach(blind => {
                            blind.attributes.options = JSON.parse(blind.attributes.options);
                            this.blinds_total += blind.attributes.value;
                        })
                    }

                    this.printing_pages = [];
                    let temp_blinds_printing_array = _.clone(this.quote_details.relationships.blinds.data);

                    for(let i = 0; i < this.quote_details.relationships.blinds.data.length / 3; i++) {
                        if(i === 0) {
                            this.printing_pages.push([]);
                        }

                        this.printing_pages.push(temp_blinds_printing_array.splice(0, 3));
                    }

                    await this.$refs.html2Pdf.generatePdf()

                    this.is_printing_quote = false;
                })
                .catch(e => {
                    this.is_printing_quote = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('quotes.error_retrieve_quote')),
                        type: 'error',
                    });
                });
        },
        visitQuote(quote) {
            this.$router.push({name: 'quote-details', params: {id: quote.id}});
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        onPageChange(params) {
            if (params <= 0 || params > this.last_page)
                return;

            this.updateParams({page: params});
            this.getQuotes();
        },
        getQuotesStatuses() {
            this.$axios.get(`quotes/list-statuses`)
                .then(({data}) => {
                    this.statusOptions = data;

                    this.statusOptions.splice(this.statusOptions.findIndex(e => e.column === 'switched_to_order'), 1);
                })
                .catch(e => {
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('quotes.error_retrieve_statuses')),
                        type: 'error',
                    });
                });
        },
        getQuotes(is_searching = false) {
            if (!is_searching)
                this.is_loading_quotes = true;

            this.$axios.get(`quotes`, {params: this.serverParams})
                .then(({data}) => {
                    this.quotes = data.data;
                    this.last_page = data.meta.last_page;
                    this.current_page = data.meta.current_page;
                    this.is_loading_quotes = false;
                })
                .catch(e => {
                    this.is_loading_quotes = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('cart.error_retrieve_orders')),
                        type: 'error',
                    });
                });
        }
    },
    filters: {
        date(value) {
            return moment(value).format('DD-MM-YYYY');
        },
        dateTime(value) {
            return moment(value).format('DD-MM-YYYY hh:mm:ss A');
        }
    },
    mounted() {
        this.getQuotesStatuses();
        this.getQuotes();
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.quotes')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
@media print {
    @page {
        size: landscape
    }
}

.pdf-content {
    @apply px-8 py-2;

    .printing-page {
        @apply pt-4;

        &:first-of-type {
            @apply pt-0;
        }
    }

    .top-row {
        @apply flex flex-row justify-between mb-4;

        .reference {
            @apply text-black font-bold text-2xl my-auto -mt-1;
        }

        .sales-person-container {
            @apply flex flex-row border-1 border-black rounded-md py-4 px-2 bg-grey-light mt-1;

            p {
                @apply text-sm px-2 -mt-3;

                &.title {
                    @apply font-bold;
                }

                span {
                    @apply font-bold;
                }
            }
        }
    }

    .item-cards-container {
        @apply flex flex-row -mx-2;

        .item-card-container {
            @apply w-1/3 px-2 mt-2;
        }
    }

    .order-details-container {
        @apply flex flex-row mb-2;

        .billing-details-container {
            @apply flex flex-col rounded-md border-1 border-black bg-grey-light mr-2;
            width: 60%;

            .card-title-container {
                @apply px-4 py-1 bg-black text-white;

                .card-title {
                    @apply font-bold text-sm -mt-2 mb-2;
                }
            }

            .user-details-wrapper {
                @apply px-4 py-2;

                .user-details-container {
                    @apply flex flex-row;
                }

                .user-detail {
                    @apply flex flex-row mr-8;

                    p {
                        &:nth-child(even) {
                            @apply ml-1;
                        }
                    }

                    &:last-child {
                        @apply mr-0;
                    }

                    .label {
                        @apply font-bold mb-2 text-xs;
                    }

                    p {
                        @apply mb-2 text-xs;
                    }
                }
            }

            & > h1 {
                @apply font-bold text-lg mb-4;
            }
        }

        .price-breakdown-container {
            @apply ml-2 rounded-md border-1 border-black bg-grey-light;
            width: 40%;

            .card-title-container {
                @apply px-4 py-1 bg-black text-white;

                .card-title {
                    @apply font-bold text-sm -mt-2 mb-2;
                }
            }

            .details-container {
                @apply flex flex-col flex-wrap max-h-40 px-4 py-2 -mx-4;

                .row {
                    @apply flex flex-row text-xs justify-between mx-4 mb-4;

                    .label {
                        @apply font-bold mr-4;
                    }

                    &.bottom-border {
                        @apply pb-4 mb-0 border-b border-black;
                    }
                }
            }
        }
    }
}

.page-container {
    @apply w-full h-full mx-auto bg-white px-8;

    & > .page-content-container {
        @apply flex flex-col max-w-6xl mx-auto w-full;

        & > .header-container {
            @apply flex flex-row pt-8 ml-4;

            & > .left {
                @apply flex flex-row w-full;

                & > a {
                    @apply my-auto mr-4;

                    & > svg {
                        @apply text-primary text-3xl cursor-pointer;

                        &:hover {
                            @apply text-primary-over;
                        }
                    }
                }

                & > h1 {
                    @apply font-bold text-2xl my-auto ml-2;
                }
            }

            & > .right {
                @apply flex flex-row;

                & > .select {
                    @apply w-40 mr-4 my-auto;
                }

                .search-desktop, .filter-desktop {
                    @apply hidden;

                    @screen md {
                        @apply block;
                    }
                }
            }

            .btn-search-mobile, .btn-filter-mobile {
                @apply block text-primary w-6;

                @screen md {
                    @apply hidden;
                }

                &:active, &:focus {
                    @apply outline-none;
                }
            }

            .search-container {
                @apply mr-0;
            }

            .filter-mobile {
                @apply max-w-xs;
            }

            .td-after {
                @apply flex flex-row;

                & > * {
                    @apply mr-3;

                    &:last-child {
                        @apply mr-0;
                    }
                }
            }
        }

        & > .headbar-expanded-container {
            @apply mt-4
        }

        & > .no-quotes-found{
            @apply w-full mt-8;

            & > p {
                @apply font-bold mt-4 text-xl mx-auto text-center;
            }
        }


        & > .quote-details-wrapper {
            @apply overflow-x-scroll;

            @screen lg {
                @apply overflow-x-auto;
            }

            & > .quote-details-container {
                @apply flex flex-col mt-12 w-260;

                @screen lg {
                    @apply w-full;
                }

                & > .title-row {
                    @apply flex flex-row w-200 mb-4 pl-12;

                    @screen lg {
                        @apply w-4/5;
                    }

                    & > p {
                        @apply w-1/5 font-bold text-left text-lg;
                    }
                }

                & > .quote-row {
                    @apply flex flex-row w-full mb-4 bg-grey-form rounded-xl py-6;

                    &:last-of-type {
                        @apply mb-0;
                    }

                    & > .details {
                        @apply flex flex-row w-200 pl-12;

                        @screen lg {
                            @apply w-4/5;
                        }

                        & > p {
                            @apply w-1/5 font-bold text-left text-lg text-grey-dark capitalize;
                        }
                    }

                    & > .icons-container {
                        @apply flex flex-row w-auto ml-auto mr-8;

                        & > svg {
                            @apply text-2xl text-grey-dark cursor-pointer ml-4;

                            &:hover {
                                @apply text-grey-darker;
                            }

                            &.disabled {
                                @apply text-grey;
                            }
                        }

                        & > .delete-icon {
                            @apply text-primary mx-4;

                            &:hover {
                                @apply text-primary-over;
                            }

                            &.disabled {
                                @apply text-primary-dimmed cursor-not-allowed;
                            }
                        }
                    }
                }
            }
        }

        .pagination-wrapper {
            @apply flex flex-row my-8 ml-auto;

            p{
                @apply mr-4 text-black text-xl font-bold;

                &:hover {
                    @apply cursor-pointer text-primary;
                }

                &.active{
                    @apply text-primary;
                }

                &.disabled {
                    @apply text-grey cursor-not-allowed;
                }
            }
        }
    }
}
</style>