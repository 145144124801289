import { render, staticRenderFns } from "./FabricsCreateModal.vue?vue&type=template&id=7fd83170&scoped=true&"
import script from "./FabricsCreateModal.vue?vue&type=script&lang=js&"
export * from "./FabricsCreateModal.vue?vue&type=script&lang=js&"
import style0 from "./FabricsCreateModal.vue?vue&type=style&index=0&id=7fd83170&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fd83170",
  null
  
)

export default component.exports