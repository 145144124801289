<template>
  <loading-screen v-if="is_loading_blind || is_loading_fabrics || is_loading_articles"></loading-screen>
  <div v-else class="page-container">
    <div v-if="blind_details" class="header-container">
      <div class="title-container">
        <router-link :to="{name: 'calculators-index'}">
          <font-awesome-icon :icon="['far', 'arrow-circle-left']"/>
        </router-link>
        <h1>{{ blind_details.attributes.name }}</h1>
      </div>
      <div v-if="!$route.query.token && !$route.query.quote && !$route.query.cart_order" class="lg:ml-auto pt-4 lg:pt-0 -mt-1 mr-6 w-32">
        <FormInputText v-model="$v.blind_quantity.$model" :has-error="$v.blind_quantity.$error"
                       :label="$t('calculators.quantity')"
                       :min="1"
                       :placeholder="$t('calculators.quantity')" borderColor="primary" identifier="blind_quantity"
                       type="number">
          <template v-slot:errors>
            <p v-if="!$v.blind_quantity.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.quantity')}) }}
            </p>
            <p v-else-if="!$v.blind_quantity.minValue">
              {{ $t('validation.minimum_quantity', {x: '1'}) }}
            </p>
          </template>
        </FormInputText>
      </div>
      <div :class="[$route.query.token ? 'pt-4 lg:ml-auto lg:pt-0' : '']" class="price-container">
        <div class="price">
          <p class="px-4" style="min-width: 9rem;">€{{ totalCost }}<span v-if="blind_quantity > 1 && totalCost"> (Total: €{{totalCostWithQuantity}})</span></p>
        </div>
        <Button :class="{'spinner-black': is_adding_item}" :onclick="addToCart" className="--primary --small"
                type="submit">
          {{ $route.query.token ? $t('calculators.update') : ($route.query.quote ? $t('calculators.add_to_quote') : $t('calculators.add_to_cart')) }}
        </Button>
      </div>
    </div>
    <div class="form-container">
      <h1 class="form-title">{{ $t('calculators.measurements') }}</h1>

      <FormGroupFour class="form main-form">
        <FormInputText v-model="$v.blind.width.$model" :has-error="$v.blind.width.$error" :label="$t('calculators.aperture_width') + ' (cm)'"
                       :max="240"
                       :placeholder="$t('calculators.aperture_width')" borderColor="primary" identifier="width"
                       type="number" @input="updateMiddleOptionSlatQty">
          <template v-slot:errors>
            <p v-if="!$v.blind.width.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.aperture_width')}) }}
            </p>
          </template>
        </FormInputText>

        <FormInputText v-model="$v.blind.drop.$model" :has-error="$v.blind.drop.$error" :label="$t('calculators.aperture_drop') + ' (cm)'"
                       :placeholder="$t('calculators.aperture_drop')"
                       borderColor="primary" identifier="drop" type="number">
          <template v-slot:errors>
            <p v-if="!$v.blind.drop.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.aperture_drop')}) }}
            </p>
          </template>
        </FormInputText>
        <FormInputText v-model="slatQtyUI" :disabled="true" :label="$t('calculators.slat_qty')" :placeholder="$t('calculators.slat_qty')"
                       class="slat-qty-container" identifier="slat-qty" type="number"/>
        <FormInputSelect v-model="$v.blind.aperture_type.$model"
                         :has-error="$v.blind.aperture_type.$error" :display-custom-label="(row) => row.charAt(0).toUpperCase() + row.slice(1)"
                         :label="$t('calculators.aperture_type')" :options="apertureTypeOptions"
                         :placeholder="$t('calculators.aperture_type')" identifier="aperture_type"
                         track-by="type">
          <template v-slot:errors>
            <p v-if="!$v.blind.aperture_type.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.aperture_type')}) }}
            </p>
          </template>
        </FormInputSelect>
      </FormGroupFour>

      <FormGroupFour class="form main-form">
        <FormInputSelect v-model="$v.blind.recess_type.$model" :has-error="$v.blind.recess_type.$error" :label="$t('calculators.recess_type')"
                         :options="recessTypeOptions"
                         :placeholder="$t('calculators.recess_type')" identifier="recess-type"
                         type="number" @input="onSelectRecessType">
          <template v-slot:errors>
            <p v-if="!$v.blind.recess_type.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.recess_type')}) }}
            </p>
          </template>
        </FormInputSelect>


        <FormInputSelect v-if="blind.recess_type === 'Inside recess'"  v-model="$v.blind.width_clearance.$model" :disabled="blind.recess_type === 'Outside recess'"
                         :has-error="$v.blind.width_clearance.$error"
                         :label="$t('calculators.width_clearance')"
                         :options="clearanceOptions" :placeholder="$t('calculators.width_clearance')"
                         identifier="width-clearance"
                         type="number">
          <template v-slot:errors>
            <p v-if="!$v.blind.width_clearance.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.width_clearance')}) }}
            </p>
          </template>
        </FormInputSelect>

        <FormInputText v-if="blind.recess_type === 'Outside recess'"  v-model="$v.blind.overlap_width.$model" :has-error="$v.blind.overlap_width.$error"
                       :label="$t('calculators.outside_overlap_width') + ' (cm)'"
                       :max="240"
                       :placeholder="$t('calculators.outside_overlap_width')" borderColor="primary" identifier="width"
                       type="number">
          <template v-slot:errors>
            <p v-if="!$v.blind.overlap_width.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.outside_overlap_width')}) }}
            </p>
          </template>
        </FormInputText>

        <FormInputText v-if="blind.recess_type === 'Outside recess'" v-model="$v.blind.overlap_drop.$model" :has-error="$v.blind.overlap_drop.$error"
                       :label="$t('calculators.outside_overlap_drop') + ' (cm)'"
                       :max="240"
                       :placeholder="$t('calculators.outside_overlap_drop')" borderColor="primary" identifier="width"
                       type="number">
          <template v-slot:errors>
            <p v-if="!$v.blind.overlap_drop.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.outside_overlap_drop')}) }}
            </p>
          </template>
        </FormInputText>

        <FormInputSelect v-model="$v.blind.controls_side.$model" :has-error="$v.blind.controls_side.$error" :label="$t('calculators.controls_side')"
                         :options="controlsSideOptions"
                         :placeholder="$t('calculators.controls_side')" identifier="controls-side"
                         type="number">
          <template v-slot:errors>
            <p v-if="!$v.blind.controls_side.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.controls_side')}) }}
            </p>
          </template>
        </FormInputSelect>

      </FormGroupFour>

      <div class="divider"></div>

      <div class="form-wrapper form">
        <FormInputText v-model="note" :label="$t('calculators.note')" :largeTextarea="true"
                       :placeholder="$t('calculators.note')" :useTextarea="true" identifier="note"></FormInputText>
      </div>
    </div>

    <div class="form-container cards">
      <h1 class="form-title">{{ $t('calculators.control_type') }}</h1>
      <div class="item-container">
        <div v-for="type in controlTypeOptions" class="item-card-container">
          <div :class="{'selected': control_type_selected === type}" class="item-card"
               @click="onControlTypeSelect(type)">
            <h1 class="item-card-title">{{ type.name }}</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="form-container cards">
      <h1 class="form-title">{{ $t('calculators.opening_type') }}</h1>
      <div class="item-container">
        <div v-for="type in openingTypeOptions" class="item-card-container">
          <div :class="[{'selected': opening_type_selected === type}, {'disabled': type.type === 'open_middle' && is_wand_control_chosen}]" class="item-card item-card-image"
               @click="onOpeningTypeSelect(type)">
            <h1 class="item-card-title">{{ type.name }}</h1>
            <img :src="require(`../../assets/blinds/${type.img}`)">
          </div>
        </div>
      </div>
    </div>

    <div class="form-container cards">
      <h1 class="form-title">{{ $t('calculators.fabrics') }}</h1>
      <div class="item-container">
        <div v-for="fabric in fabrics" class="item-card-container">
          <div :class="{'selected': fabric_selected === fabric}" class="item-card" @click="onFabricSelect(fabric)">
            <h1 class="item-card-title">{{ fabric.attributes.name }}</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="form-container cards">
      <h1 class="form-title">{{ $t('calculators.fabric_colour_options') }}</h1>
      <div class="item-container">
        <div v-for="fabric_colour_option in fabricColourOptions" class="item-card-container">
          <div :class="{'selected': fabric_colour_option_selected === fabric_colour_option}" class="item-card"
               @click="onFabricColourOptionSelect(fabric_colour_option)">
            <h1 class="item-card-title">{{ fabric_colour_option }}</h1>
          </div>
        </div>
      </div>
    </div>

    <div v-if="fabric_selected && fabric_colour_option_selected" class="form-container cards">
      <h1 class="form-title">{{ $t('calculators.fabric_colours') }}</h1>
      <div class="item-container">
        <div v-for="colour in JSON.parse(fabric_selected.attributes.colors)" class="item-card-container">
          <div :class="{'selected': colours_selected.find(c => c.name === colour)}" class="item-card"
               @click="onColourSelect(colour)">
            <h1 class="item-card-title">{{ colour }}</h1>
          </div>
        </div>
      </div>
    </div>

    <div v-if="colours_selected.length && fabric_colour_option_selected !== 'One Colour'" class="form-container cards">
      <h1 class="form-title">{{ $t('calculators.colour_slat_qty_position') }}</h1>

      <div v-for="colour in colours_selected" class="colour-container">
        <h2>{{ colour.name }}</h2>

        <div v-if="fabric_colour_option_selected === 'Two Colour'" class="item-container">
          <div class="item-card-container">
            <div :class="{'selected': colour.option_selected == 'Outer Colour'}" class="item-card item-card-image"
                 @click="onColourOptionsSelect(colour, 'Outer Colour')">
              <h1 class="item-card-title">Outer Colour</h1>
              <img :src="require(`../../assets/blinds/first-outer.svg`)">
            </div>
          </div>
          <div class="item-card-container">
            <div :class="{'selected': colour.option_selected == 'Middle Colour'}" class="item-card item-card-image"
                 @click="onColourOptionsSelect(colour, 'Middle Colour')">
              <h1 class="item-card-title">Middle Colour</h1>
              <img :src="require(`../../assets/blinds/middle.svg`)">
            </div>
          </div>
          <FormGroupTwo v-if="colour.option_selected == 'Outer Colour'" class="slat-qty-grp-input">
            <FormInputText v-model="colour.first_outer_slat_qty" :label="$t('calculators.left_slat_qty')" :placeholder="$t('calculators.left_slat_qty')"
                           class="slat-qty-input" identifier="slat_qty"
                           tippyText="One slat is 10.3cm" @input="slatQtyUpdated(colour)"/>
            <FormInputText v-model="colour.first_outer_slat_qty" :label="$t('calculators.right_slat_qty')" :placeholder="$t('calculators.right_slat_qty')"
                           class="slat-qty-input" identifier="slat_qty"
                           tippyText="One slat is 10.3cm" @input="slatQtyUpdated(colour)"/>
          </FormGroupTwo>
          <FormInputText v-else-if="colour.option_selected == 'Middle Colour'" v-model="colour.middle_slat_qty"
                         :disabled="true" :label="$t('calculators.slat_qty')" :placeholder="$t('calculators.slat_qty')"
                         class="slat-qty-input" identifier="slat_qty" tippyText="One slat is 10.3cm"/>
        </div>

        <div v-if="fabric_colour_option_selected === 'Three Colour'" class="item-container">
          <div class="item-card-container">
            <div :class="{'selected': colour.option_selected == 'First Outer Colour'}" class="item-card item-card-image"
                 @click="onColourOptionsSelect(colour, 'First Outer Colour')">
              <h1 class="item-card-title">First Outer Colour</h1>
              <img :src="require(`../../assets/blinds/first-outer.svg`)">
            </div>
          </div>
          <div class="item-card-container">
            <div :class="{'selected': colour.option_selected == 'Second Outer Colour'}" class="item-card item-card-image"
                 @click="onColourOptionsSelect(colour, 'Second Outer Colour')">
              <h1 class="item-card-title">Second Outer Colour</h1>
              <img :src="require(`../../assets/blinds/second-outer.svg`)">
            </div>
          </div>
          <div class="item-card-container">
            <div :class="{'selected': colour.option_selected == 'Middle Colour'}" class="item-card item-card-image"
                 @click="onColourOptionsSelect(colour, 'Middle Colour')">
              <h1 class="item-card-title">Middle Colour</h1>
              <img :src="require(`../../assets/blinds/middle.svg`)">
            </div>
          </div>
          <FormGroupTwo v-if="colour.option_selected == 'First Outer Colour'"
                        class="slat-qty-grp-input slat-qty-grp-input-sml">
            <FormInputText v-model="colour.first_outer_slat_qty" :label="$t('calculators.left_slat_qty')" :placeholder="$t('calculators.left_slat_qty')"
                           class="slat-qty-input" identifier="slat_qty"
                           tippyText="One slat is 10.3cm" @input="slatQtyUpdated(colour)"/>
            <FormInputText v-model="colour.first_outer_slat_qty" :label="$t('calculators.right_slat_qty')" :placeholder="$t('calculators.right_slat_qty')"
                           class="slat-qty-input" identifier="slat_qty"
                           tippyText="One slat is 10.3cm" @input="slatQtyUpdated(colour)"/>
          </FormGroupTwo>
          <FormGroupTwo v-else-if="colour.option_selected == 'Second Outer Colour'"
                        class="slat-qty-grp-input slat-qty-grp-input-sml">
            <FormInputText v-model="colour.second_outer_slat_qty" :label="$t('calculators.left_slat_qty')" :placeholder="$t('calculators.left_slat_qty')"
                           class="slat-qty-input" identifier="slat_qty"
                           tippyText="One slat is 10.3cm" @input="slatQtyUpdated(colour)"/>
            <FormInputText v-model="colour.second_outer_slat_qty" :label="$t('calculators.right_slat_qty')" :placeholder="$t('calculators.right_slat_qty')"
                           class="slat-qty-input" identifier="slat_qty"
                           tippyText="One slat is 10.3cm" @input="slatQtyUpdated(colour)"/>
          </FormGroupTwo>
          <FormInputText v-else-if="colour.option_selected == 'Middle Colour'" v-model="colour.middle_slat_qty"
                         :disabled="true" :label="$t('calculators.slat_qty')" :placeholder="$t('calculators.slat_qty')"
                         class="slat-qty-input" identifier="slat_qty" tippyText="One slat is 10.3cm"/>
        </div>
      </div>
    </div>

    <div v-if="motors.length || controller_types.length" class="form-container cards">
      <h1 class="form-title">Home Automation</h1>
      <div class="category-container">
        <h2>Motors</h2>

        <div class="item-container">
          <div v-for="motor in motors" class="item-card-container">
            <div :class="{'selected': motor === motor_selected, 'disabled': motor.disabled}" class="item-card"
                 @click="onMotorSelected(motor)">
              <h1 class="item-card-title">{{ motor.attributes.name }}</h1>
            </div>
          </div>
        </div>

        <div v-if="controller_types.length > 0" class="divider"></div>

        <h2>Controller Type</h2>

        <div class="item-container">
          <div v-for="controller_type in controller_types" class="item-card-container">
            <div :class="{'selected': controller_type === controller_type_selected}" class="item-card"
                 @click="onControllerTypeSelected(controller_type)">
              <h1 class="item-card-title">{{ controller_type.attributes.name }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormInputText from "@/components/form/FormInputText";
import FormGroupTwo from "../../components/form/FormGroupTwo";
import Button from "@/components/Button";
import FormInputSelect from "@/components/form/FormInputSelect";
import FormGroupFour from "@/components/form/FormGroupFour";
import FormGroupThree from "@/components/form/FormGroupThree";
import LoadingScreen from "@/components/LoadingScreen";
import {minValue, required, requiredIf} from "vuelidate/lib/validators";
import {createArrayPayloadFromBlind} from "@/utils/BlindUtils";

export default {
  name: "SheerCurtain",
  components: {LoadingScreen, FormGroupThree, FormGroupFour, FormInputSelect, Button, FormInputText, FormGroupTwo},
  data() {
    return {
      blind: {
        width: null,
        drop: null,
        overlap_width: null,
        overlap_drop: null,
        aperture_type: null,
        recess_type: null,
        width_clearance: null,
        controls_side: null,
      },
      blind_quantity: 1,
      note: null,
      opening_type: null,

      topTubeFabricRollOptions: ['Outside Recess Roll', 'Inside Recess Roll'],
      apertureTypeOptions: ['door', 'window'],
      recessTypeOptions: ['Outside recess', 'Inside recess'],
      clearanceOptions: ['0.5 cm', '1 cm', '1.5 cm', '2 cm'],
      controlsSideOptions: ['Left', 'Right'],
      controlTypeOptions: [{id: 1, name: 'Wand Control', type: 'wand_control'}, {
        id: 2,
        name: 'Cord Control',
        type: 'cord_control'
      }],
      openingTypeOptions: [{
        name: 'Opening to the right',
        type: 'open_right',
        img: 'right_opening.svg'
      }, {name: 'Opening to the left', type: 'open_left', img: 'left_opening.svg'}, {
        name: 'Middle opening',
        type: 'open_middle',
        img: 'middle_opening.svg'
      }],
      fabric: {
        attributes: {
          name: 'Standard Fabric',
          code: 'Tex1',
          price: {
            currency: 'USD',
            value: 1
          },
          conversion_rate: 0.857,
          shipping_cost: 0.1,
          markup: 0.1,
          vat: 0.18,
          multiplier: 0.01,
        }
      },
      fabrics: [],
      fabric_selected: null,
      fabric_colour_option_selected: null,
      fabricColourOptions: ['One Colour', 'Two Colour', 'Three Colour'],
      colours_selected: [],
      custom_colour_code: null,
      articles: [],
      motors: [],
      motor_selected: null,
      controller_types: [],
      controller_type_selected: null,
      per_cm_items: [],
      per_slat_items: [],
      per_runner_items: [],
      per_unit_items: [],

      control_type_selected: null,
      opening_type_selected: null,

      blind_details: null,
      is_loading_blind: false,
      is_loading_fabrics: false,
      is_loading_articles: false,
      is_adding_item: false,
      is_wand_control_chosen: false,

      width_divider: null,
    }
  },
  validations: {
    blind: {
      width: {
        required,
      },
      drop: {
        required,
      },
      aperture_type: {required},
      recess_type: {required},
      width_clearance: {
        required: requiredIf(function () {
          return this.blind.recess_type === 'Inside recess';
        })
      },
      controls_side: {required},
      overlap_width: {
        required: requiredIf(function () {
          return this.blind.recess_type === 'Outside recess';
        })
      },
      overlap_drop: {
        required: requiredIf(function () {
          return this.blind.recess_type === 'Outside recess';
        })
      },
    },
    blind_quantity: {required: required, minValue: minValue(1)},
    custom_colour_code: {
      required: requiredIf(function () {
        return this.colour_selected === 'Custom Colour';
      })
    }
  },
  watch: {
    blind: {
      handler(newValue, oldValue) {
        if (newValue.recess_type === 'Outside recess') {
          this.blind.width_clearance = null;
        }
        if (newValue.recess_type === 'Inside recess') {
          this.blind.overlap_drop = null;
          this.blind.overlap_width = null;
        }
      },
      deep: true
    }
  },
  methods: {
    onControlTypeSelect(type) {
      if (type.type === 'wand_control') {
        this.is_wand_control_chosen = true
        if (this.opening_type_selected && this.opening_type_selected.type === 'open_middle') this.opening_type_selected = null;
      } else {
        this.is_wand_control_chosen = false
      }
      this.control_type_selected = type;
    },
    onOpeningTypeSelect(type) {
      if (this.is_wand_control_chosen && type.type === 'open_middle') return;
      this.opening_type_selected = type;
      this.updateMiddleOptionSlatQty();
    },
    onFabricSelect(fabric) {
      this.fabric_selected = fabric;
    },
    onSelectRecessType(recess) {
    },
    onFabricColourOptionSelect(fabric_colour_option) {
      this.fabric_colour_option_selected = fabric_colour_option;
      this.colours_selected = [];
    },
    onColourSelect(colour) {
      if (this.colours_selected.find(c => c.name === colour)) {
        this.colours_selected.splice(this.colours_selected.findIndex(c => c.name === colour), 1);
        return;
      }

      if (this.fabric_colour_option_selected && !this.colours_selected.find(c => c.name === colour) && (this.fabric_colour_option_selected === 'One Colour' && this.colours_selected.length < 1) || (this.fabric_colour_option_selected === 'Two Colour' && this.colours_selected.length < 2) || (this.fabric_colour_option_selected === 'Three Colour' && this.colours_selected.length < 3))
        this.colours_selected.push({
          name: colour,
          first_outer_slat_qty: null,
          second_outer_slat_qty: null,
          middle_slat_qty: null
        });
      else if ((this.fabric_colour_option_selected === 'One Colour' && this.colours_selected.length === 1) || (this.fabric_colour_option_selected === 'Two Colour' && this.colours_selected.length === 2) || (this.fabric_colour_option_selected === 'Three Colour' && this.colours_selected.length === 3)) {
        this.colours_selected.pop();
        this.colours_selected.push({
          name: colour,
          first_outer_slat_qty: null,
          second_outer_slat_qty: null,
          middle_slat_qty: null
        });
      }
    },
    onColourOptionsSelect(colour, option) {
      if (this.colours_selected.find(c => c.option_selected === option)) {
        this.$set(this.colours_selected.find(c => c.option_selected === option), 'option_selected', null);

        this.clearColourOptions();
      }

      this.$set(this.colours_selected.find(c => c.name === colour.name), 'option_selected', option);
    },
    clearColourOptions() {
      this.colours_selected.forEach(colour => {
        colour.first_outer_slat_qty = null;
        colour.second_outer_slat_qty = null;
        colour.middle_slat_qty = null;
      })
    },
    slatQtyUpdated(colour) {
      if (this.fabric_colour_option_selected === 'Two Colour' && colour.first_outer_slat_qty) {
        colour.first_outer_slat_qty = Math.ceil(colour.first_outer_slat_qty);

        if (this.colours_selected.findIndex(c => c.name === colour.name) === 0) {
          this.colours_selected[1].middle_slat_qty = this.slatQtyUI - (colour.first_outer_slat_qty * 2);

          if (this.colours_selected[1].middle_slat_qty < 0)
            this.colours_selected[1].middle_slat_qty = 0;
        } else {
          this.colours_selected[0].middle_slat_qty = this.slatQtyUI - (colour.first_outer_slat_qty * 2);

          if (this.colours_selected[0].middle_slat_qty < 0)
            this.colours_selected[0].middle_slat_qty = 0;
        }
      } else if (this.fabric_colour_option_selected === 'Three Colour' && this.colours_selected.find(c => c.first_outer_slat_qty > 0) && this.colours_selected.find(c => c.second_outer_slat_qty > 0)) {
        if (colour) {
          colour.first_outer_slat_qty = Math.ceil(colour.first_outer_slat_qty);
          colour.second_outer_slat_qty = Math.ceil(colour.second_outer_slat_qty);
        }

        if (this.colours_selected.find(c => c.first_outer_slat_qty > 0) && this.colours_selected.find(c => c.second_outer_slat_qty > 0)) {
          if (this.colours_selected.find(c => c.option_selected === 'Middle Colour'))
            this.colours_selected.find(c => c.option_selected === 'Middle Colour').middle_slat_qty = this.slatQtyUI - (this.colours_selected.find(c => c.first_outer_slat_qty > 0).first_outer_slat_qty * 2 + this.colours_selected.find(c => c.second_outer_slat_qty > 0).second_outer_slat_qty * 2);
          else
            this.colours_selected.find(c => c.option_selected === undefined).middle_slat_qty = this.slatQtyUI - (this.colours_selected.find(c => c.first_outer_slat_qty > 0).first_outer_slat_qty * 2 + this.colours_selected.find(c => c.second_outer_slat_qty > 0).second_outer_slat_qty * 2);
        }

        if (this.colours_selected.find(c => c.option_selected === 'Middle Colour').middle_slat_qty < 0)
          this.colours_selected.find(c => c.option_selected === 'Middle Colour').middle_slat_qty = 0;
      }
    },
    updateMiddleOptionSlatQty() {
      if (this.colours_selected.find(c => c.option_selected === 'Middle Colour') !== undefined) {
        if (this.fabric_colour_option_selected === 'Two Colour') {
          this.slatQtyUpdated(this.colours_selected.find(c => c.option_selected === 'Outer Colour'));
        } else if (this.fabric_colour_option_selected === 'Three Colour') {
          this.slatQtyUpdated();
        }
      }
    },
    onCoverSelect(cover) {
      this.cover_option_selected = cover;
    },
    onBottomBarSelect(option) {
      this.bottom_bar_selected = option;
    },
    onMotorSelected(motor) {
      if (motor === this.motor_selected)
        this.motor_selected = null;
      else
        this.motor_selected = motor;
    },
    onControllerTypeSelected(controller_type) {
      if (controller_type === this.controller_type_selected)
        this.controller_type_selected = null;
      else
        this.controller_type_selected = controller_type;
    },
    async retrieveBlind() {
      this.is_loading_blind = true;

      await this.$axios.get(`blinds/${this.$route.query.id}`).then(({data}) => {
        this.blind_details = data.data;
        this.width_divider = data.data.attributes.width_divider
        if (!this.width_divider) this.width_divider = 10.33
        this.is_loading_blind = false;
      }).catch(e => {
        this.is_loading_blind = false;

        this.$notify({
          text: this.$larerror(e.response.data.errors, this.$t('calculators.error_failed_retrieve_blind')),
          type: 'error',
        });
      });
    },
    async retrieveFabrics() {
      this.is_loading_fabrics = true;

      await this.$axios.get(`blinds/${this.$route.query.id}/fabrics`).then(({data}) => {
        this.fabrics = data.data.sort((a, b) => a.attributes.order - b.attributes.order);
        this.is_loading_fabrics = false;
      }).catch(e => {
        this.is_loading_fabrics = false;

        this.$notify({
          text: this.$larerror(e.response.data.errors, this.$t('calculators.error_failed_retrieve_fabrics')),
          type: 'error',
        });
      });
    },
    async retrieveArticles() {
      this.is_loading_articles = true;

      await this.$axios.get(`blinds/${this.$route.query.id}/articles/list`).then(({data}) => {
        this.articles = data.data;

        this.articles.forEach(article => {
          if (article.attributes.unit === 'per_cm') {
            this.per_cm_items.push(article);
          }

          if (article.attributes.unit === 'per_unit') {
            this.per_unit_items.push(article);
          }

          if (article.attributes.unit === 'per_slat') {
            this.per_slat_items.push(article);
          }

          if (article.attributes.unit === 'per_runner') {
            this.per_runner_items.push(article);
          }

          if (article.relationships.category.data && article.relationships.category.data.attributes && article.relationships.category.data.attributes.name === 'Motors')
            this.motors.push(article);

          if (article.relationships.category.data && article.relationships.category.data.attributes && article.relationships.category.data.attributes.name === 'Controller Types')
            this.controller_types.push(article);
        });

        this.is_loading_articles = false;
      }).catch(e => {
        this.is_loading_articles = false;

        this.$notify({
          text: this.$larerror(e.response.data.errors, this.$t('calculators.error_failed_retrieve_articles')),
          type: 'error',
        });
      });
    },
    async getOrder() {
      let order = 0;

      if (!this.$route.query.token) {
        if (this.$route.query.quote) {
          let order = 0;

          await this.$axios.get(`/quotes/${this.$route.query.quote}`).then(({data}) => {
            if (data.data.relationships.blinds.data.length)
              data.data.relationships.blinds.data.forEach(blind => {
                if (blind.attributes.order > order)
                  order = blind.attributes.order;
              })
          });

          return order += 1;
        } else if (this.cart) {
          this.cart.relationships.blinds.data.forEach(blind => {
            if (blind.attributes.order > order)
              order = blind.attributes.order;
          })
        }

        return order += 1;
      } else {
        return order = this.$route.query.cart_order ? this.$route.query.cart_order : 0;
      }
    },
    async updateCartOrder(payload) {
      await this.$axios.put(`carts/${this.$route.query.cart}/blinds/detach`, {token: this.$route.query.token})
          .then(({data}) => {
          })
          .catch(e => {
            this.$notify({
              title: this.$t('error'),
              text: this.$larerror(e.response.data, this.$t('cart.error_remove_from_cart')),
              type: 'error',
            });
          });

      await this.$axios.put(`carts/${this.$route.query.cart}/blinds/${this.$route.query.id}/attach`, payload).then(({data}) => {
        this.is_adding_item = false;
      }).catch(e => {
        this.is_adding_item = false;

        this.$notify({
          text: this.$larerror(e.response.data.errors, this.$t('cart.error_add_to_cart')),
          type: 'error',
        });
      });
    },
    async updateQuote(payload) {
      this.is_adding_item = false;

      if (this.$route.query.token)
        await this.$axios.put(`quotes/${this.$route.query.quote}/blinds/detach`, {token: this.$route.query.token})
            .then(({data}) => {
            })
            .catch(e => {
              this.$notify({
                title: this.$t('error'),
                text: this.$larerror(e.response.data, this.$t('quotes.error_remove_from_quote')),
                type: 'error',
              });
            });

      await this.$axios.put(`quotes/${this.$route.query.quote}/blinds/${this.$route.query.id}/attach`, payload).then(({data}) => {
        this.is_adding_item = false;
      }).catch(e => {
        this.is_adding_item = false;

        this.$notify({
          text: this.$larerror(e.response.data.errors, this.$t('quotes.error_add_to_quote')),
          type: 'error',
        });
      });
    },
    async getCart() {
      this.is_loading_cart = true;

      await this.$axios.get(`carts/${this.$store.getters.cart}`)
          .then(({data}) => {
            this.cart = data.data;
            this.is_loading_cart = false;
          })
          .catch(e => {
            this.is_loading_cart = false;

            this.$notify({
              title: this.$t('error'),
              text: this.$larerror(e.response.data, this.$t('cart.error_retrieve_cart')),
              type: 'error',
            });
          });
    },
    async addToCart() {
      this.$v.blind.$touch();
      if (this.$v.blind.$anyError || this.is_adding_item)
        return;

      if (this.apertureWidthWithOverlap > 600) {
        this.$notify({
          text: 'Width cannot be larger than 600cm',
          type: 'error',
        });
        return;
      }

      if (this.apertureWidthWithOverlap > 450 && (this.opening_type_selected && this.opening_type_selected.type !== 'open_middle')) {
        this.$notify({
          text: 'Width larger than 450cm must have a middle opening',
          type: 'error',
        });
        return;
      }

      if (this.apertureWidthWithOverlap < 15) {
        this.$notify({
          text: 'Width cannot be smaller than 15cm',
          type: 'error',
        });
        return;
      }

      if (this.blind.drop <= 0) {
        this.$notify({
          text: 'Drop cannot be smaller than 1cm',
          type: 'error',
        });
        return;
      }

      if (!this.fabric_selected) {
        this.$notify({
          text: this.$t('calculators.fabric_required'),
          type: 'error',
        });
        return;
      }

      if (!this.opening_type_selected) {
        this.$notify({
          text: this.$t('calculators.opening_type_required'),
          type: 'error',
        });
        return;
      }

      if (!this.control_type_selected) {
        this.$notify({
          text: this.$t('calculators.control_type_required'),
          type: 'error',
        });
        return;
      }

      if (!this.fabric_colour_option_selected) {
        this.$notify({
          text: this.$t('calculators.fabric_colour_options_required'),
          type: 'error',
        });
        return;
      }

      if (this.fabric_colour_option_selected === 'One Colour' && !this.colours_selected.length) {
        this.$notify({
          text: this.$t('calculators.fabric_colour_required'),
          type: 'error',
        });
        return;
      }

      if (this.fabric_colour_option_selected === 'Two Colour' && this.colours_selected.length < 2) {
        this.$notify({
          text: this.$t('calculators.two_fabric_colours_required'),
          type: 'error',
        });
        return;
      }

      if (this.fabric_colour_option_selected === 'Three Colour' && this.colours_selected.length < 3) {
        this.$notify({
          text: this.$t('calculators.three_fabric_colours_required'),
          type: 'error',
        });
        return;
      }

      if (this.fabric_colour_option_selected === 'Two Colour') {
        if (this.colours_selected.find(c => c.option_selected === null) || this.colours_selected.find(c => c.option_selected === undefined)) {
          this.$notify({
            text: this.$t('calculators.fabric_colour_options_required'),
            type: 'error',
          });
          return;
        }

        if (this.colours_selected.find(c => c.option_selected === 'Outer Colour') && this.colours_selected.find(c => c.option_selected === 'Outer Colour').first_outer_slat_qty === 0) {
          this.$notify({
            text: this.$t('calculators.outer_slat_qty_cannot_be_zero'),
            type: 'error',
          });
          return;
        }

        if ((this.colours_selected.find(c => c.option_selected === 'Outer Colour').first_outer_slat_qty * 2 + this.colours_selected.find(c => c.option_selected === 'Middle Colour').middle_slat_qty) > this.slatQtyUI) {
          this.$notify({
            text: this.$t('calculators.fabric_colour_slat_options_cannot_be_larger_than_total'),
            type: 'error',
          });
          return;
        }
      }

      if (this.fabric_colour_option_selected === 'Three Colour') {
        if (this.colours_selected.find(c => c.option_selected === null) || this.colours_selected.find(c => c.option_selected === undefined)) {
          this.$notify({
            text: this.$t('calculators.fabric_colour_options_required'),
            type: 'error',
          });
          return;
        }

        if (this.colours_selected.find(c => c.option_selected === 'First Outer Colour') && this.colours_selected.find(c => c.option_selected === 'First Outer Colour').first_outer_slat_qty === 0) {
          this.$notify({
            text: this.$t('calculators.first_outer_slat_qty_cannot_be_zero'),
            type: 'error',
          });
          return;
        }

        if (this.colours_selected.find(c => c.option_selected === 'Second Outer Colour') && this.colours_selected.find(c => c.option_selected === 'Second Outer Colour').second_outer_slat_qty === 0) {
          this.$notify({
            text: this.$t('calculators.second_outer_slat_qty_cannot_be_zero'),
            type: 'error',
          });
          return;
        }

        if ((this.colours_selected.find(c => c.option_selected === 'First Outer Colour').first_outer_slat_qty * 2 + this.colours_selected.find(c => c.option_selected === 'Second Outer Colour').second_outer_slat_qty * 2 + this.colours_selected.find(c => c.option_selected === 'Middle Colour').middle_slat_qty) > this.slatQtyUI) {
          this.$notify({
            text: this.$t('calculators.fabric_colour_slat_options_cannot_be_larger_than_total'),
            type: 'error',
          });
          return;
        }
      }

      if (this.$store.getters.cart)
        await this.getCart();

      let order = await this.getOrder();

      let payload = {
        order: order,
        note: this.note,
        value: this.totalCost,
        options: [
          {
            width: this.blind.width,
            drop: this.blind.drop,
            overlap_drop: this.blind.overlap_drop,
            overlap_width: this.blind.overlap_width,
            aperture_type: this.blind.aperture_type,
            slat_qty: this.slatQtyUI,
            runner_qty: this.opening_type_selected.type === 'open_middle' ? parseInt(this.slatQtyUI) + 2 : parseInt(this.slatQtyUI) + 1,
            recess_type: this.blind.recess_type,
            width_clearance: this.blind.width_clearance,
            controls_side: this.blind.controls_side,
            control_type: this.control_type_selected.name,
            opening_type: this.opening_type_selected.name,
            fabric_type: this.fabric_selected.attributes.name,
            fabric_colour: this.colour_selected,
            custom_colour_code: this.custom_colour_code,
            fabric_colours_selected: this.colours_selected,
            fabric_colour_options: this.fabric_colour_option_selected,
          }
        ],
        is_unavailable: false
      }

      this.colours_selected.forEach(c => {
        if (c.middle_slat_qty > 0)
          payload.options[0].middle_slat_qty = c.middle_slat_qty;
      })

      this.colours_selected.forEach(c => {
        if (c.first_outer_slat_qty > 0)
          payload.options[0].second_outer_slat_qty = c.first_outer_slat_qty;
      })

      this.colours_selected.forEach(c => {
        if (c.second_outer_slat_qty > 0)
          payload.options[0].second_outer_slat_qty = c.second_outer_slat_qty;
      })

      if (this.motor_selected)
        payload.options[0].motor = this.motor_selected.attributes.name;

      if (this.controller_type_selected)
        payload.options[0].controller_type = this.controller_type_selected.attributes.name;

      this.is_adding_item = true;

      if (this.$route.query.cart && this.$route.query.order) {
        await this.updateCartOrder(payload);

        this.$router.push({path: `/calculators/my-orders/${this.$route.query.order}/order-details`});

        return;
      }

      if (this.$route.query.quote) {
        await this.updateQuote(payload);

        if (this.$route.query.order) this.$router.push({path: `/calculators/my-orders/${this.$route.query.order}/order-details`});
        else this.$router.push({path: `/calculators/quotes/${this.$route.query.quote}/quote-details`});

        return;
      }

      if (!this.$store.getters.cart) {
        await this.$store.dispatch('createCart');
      }

      if (this.$route.query.token) {
        this.$axios.put(`carts/${this.$store.getters.cart}/blinds/detach`, {token: this.$route.query.token})
            .then(({data}) => {
            })
            .catch(e => {
              this.$notify({
                title: this.$t('error'),
                text: this.$larerror(e.response.data, this.$t('cart.error_remove_from_cart')),
                type: 'error',
              });
            });
      }

      if (!this.$route.query.token && !this.$route.query.quote && !this.$route.query.cart_order && this.blind_quantity > 1) {
        let tempBlinds = createArrayPayloadFromBlind(payload, this.blind_quantity)
        this.$axios.put(`carts/${this.$store.getters.cart}/blinds/${this.$route.query.id}/attach/many`, {blinds: tempBlinds}).then(({data}) => {

          this.$store.commit('SET_CART_COUNT', (Number(this.$store.getters.cartCount) + Number(this.blind_quantity)).toString())

          this.$notify({
            text: this.$t('cart.items_added_to_cart'),
            type: 'success',
          });

          this.blind = {
            width: null,
            drop: null,
            overlap_drop: null,
            overlap_width: null,
            aperture_type: null,
            recess_type: null,
            width_clearance: null,
            controls_side: null,
          }

          this.note = null;
          this.motor_selected = null;
          this.controller_type_selected = null;
          this.control_type_selected = null
          this.opening_type_selected = null;
          this.fabric_selected = null;
          this.fabric_colour_option_selected = null;
          this.colours_selected = [];
          this.blind_quantity = 1;

          this.$v.blind.$reset();
          this.is_adding_item = false;
        }).catch(e => {
          this.is_adding_item = false;

          this.$notify({
            text: this.$larerror(e.response.data.errors, this.$t('cart.error_add_to_cart')),
            type: 'error',
          });
        });
        return
      }

      this.$axios.put(`carts/${this.$store.getters.cart}/blinds/${this.$route.query.id}/attach`, payload).then(({data}) => {

        if (!this.$route.query.token) {
          this.$store.commit('INCREMENT_CART_COUNT');
        }

        if (this.$route.query.token) {
          this.$router.push({name: 'cart'});
        }

        this.$notify({
          text: this.$t('cart.item_added_to_cart'),
          type: 'success',
        });

        this.blind = {
          width: null,
          drop: null,
          overlap_drop: null,
          overlap_width: null,
          aperture_type: null,
          recess_type: null,
          width_clearance: null,
          controls_side: null,
        }

        this.note = null;
        this.motor_selected = null;
        this.controller_type_selected = null;
        this.control_type_selected = null
        this.opening_type_selected = null;
        this.fabric_selected = null;
        this.fabric_colour_option_selected = null;
        this.colours_selected = [];
        this.blind_quantity = 1;

        this.$v.blind.$reset();

        this.is_adding_item = false;
      }).catch(e => {
        this.is_adding_item = false;

        this.$notify({
          text: this.$larerror(e.response.data.errors, this.$t('cart.error_add_to_cart')),
          type: 'error',
        });
      });
    },
    populate() {
      if (this.$route.query.quote) {
        this.$axios.put(`quotes/${this.$route.query.quote}/blinds`, {token: this.$route.query.token})
            .then(({data}) => {
              const details = JSON.parse(data.options)[0];

              this.blind.width = details.width;
              this.blind.drop = details.drop;
              this.blind.aperture_type = details.aperture_type;
              this.blind.recess_type = details.recess_type;
              this.blind.width_clearance = details.width_clearance;
              this.blind.chain_side = details.chain_side;
              this.blind.controls_side = details.controls_side;

              if (details.overlap_width != null)
                this.blind.overlap_width = details.overlap_width;

              if (details.overlap_drop != null)
                this.blind.overlap_drop = details.overlap_drop;

              if (data.note)
                this.note = data.note;

              this.fabrics.forEach(fabric => {
                if (fabric.attributes.name === details.fabric_type)
                  this.fabric_selected = fabric;
              })

              if (details.fabric_colour_options)
                this.fabric_colour_option_selected = details.fabric_colour_options;

              if (details.fabric_colours_selected) {
                this.colours_selected = details.fabric_colours_selected;
              }

              JSON.parse(this.fabric_selected.attributes.colors).forEach(colour => {
                if (colour === details.fabric_colour)
                  this.colour_selected = details.fabric_colour;
              })

              if (details.custom_colour_code)
                this.custom_colour_code = details.custom_colour_code;

              this.controlTypeOptions.forEach(option => {
                if (option.name === details.control_type)
                  this.control_type_selected = option;
              })

              this.openingTypeOptions.forEach(option => {
                if (option.name === details.opening_type)
                  this.opening_type_selected = option;
              })

              if (details.motor)
                this.motors.forEach(option => {
                  if (option.attributes.name === details.motor)
                    this.motor_selected = option;
                })

              if (details.controller_type)
                this.controller_types.forEach(option => {
                  if (option.attributes.name === details.controller_type)
                    this.controller_type_selected = option;
                })

            })
            .catch(e => {
              this.$notify({
                title: this.$t('error'),
                text: this.$larerror(e.response.data, this.$t('cart.error_remove_from_cart')),
                type: 'error',
              });
            });
      } else if (this.$route.query.cart) {
        this.$axios.put(`carts/${this.$route.query.cart}/blinds`, {token: this.$route.query.token})
            .then(({data}) => {
              const details = JSON.parse(data.options)[0];

              this.blind.width = details.width;
              this.blind.drop = details.drop;
              this.blind.aperture_type = details.aperture_type;
              this.blind.recess_type = details.recess_type;
              this.blind.width_clearance = details.width_clearance;
              this.blind.chain_side = details.chain_side;
              this.blind.controls_side = details.controls_side;

              if (details.overlap_width != null)
                this.blind.overlap_width = details.overlap_width;

              if (details.overlap_drop != null)
                this.blind.overlap_drop = details.overlap_drop;

              if (data.note)
                this.note = data.note;

              this.fabrics.forEach(fabric => {
                if (fabric.attributes.name === details.fabric_type)
                  this.fabric_selected = fabric;
              })

              if (details.fabric_colour_options)
                this.fabric_colour_option_selected = details.fabric_colour_options;

              if (details.fabric_colours_selected) {
                this.colours_selected = details.fabric_colours_selected;
              }

              JSON.parse(this.fabric_selected.attributes.colors).forEach(colour => {
                if (colour === details.fabric_colour)
                  this.colour_selected = details.fabric_colour;
              })

              if (details.custom_colour_code)
                this.custom_colour_code = details.custom_colour_code;

              this.controlTypeOptions.forEach(option => {
                if (option.name === details.control_type)
                  this.control_type_selected = option;
              })

              this.openingTypeOptions.forEach(option => {
                if (option.name === details.opening_type)
                  this.opening_type_selected = option;
              })

              if (details.motor)
                this.motors.forEach(option => {
                  if (option.attributes.name === details.motor)
                    this.motor_selected = option;
                })

              if (details.controller_type)
                this.controller_types.forEach(option => {
                  if (option.attributes.name === details.controller_type)
                    this.controller_type_selected = option;
                })

            })
            .catch(e => {
              this.$notify({
                title: this.$t('error'),
                text: this.$larerror(e.response.data, this.$t('cart.error_remove_from_cart')),
                type: 'error',
              });
            });
      } else {
        this.$axios.put(`carts/${this.$store.getters.cart}/blinds`, {token: this.$route.query.token})
            .then(({data}) => {
              const details = JSON.parse(data.options)[0];

              this.blind.width = details.width;
              this.blind.drop = details.drop;
              this.blind.aperture_type = details.aperture_type;
              this.blind.recess_type = details.recess_type;
              this.blind.width_clearance = details.width_clearance;
              this.blind.chain_side = details.chain_side;
              this.blind.controls_side = details.controls_side;


              if (details.overlap_width != null)
                this.blind.overlap_width = details.overlap_width;

              if (details.overlap_drop != null)
                this.blind.overlap_drop = details.overlap_drop;

              if (data.note)
                this.note = data.note;

              this.fabrics.forEach(fabric => {
                if (fabric.attributes.name === details.fabric_type)
                  this.fabric_selected = fabric;
              })

              if (details.fabric_colour_options)
                this.fabric_colour_option_selected = details.fabric_colour_options;

              if (details.fabric_colours_selected) {
                this.colours_selected = details.fabric_colours_selected;
              }

              JSON.parse(this.fabric_selected.attributes.colors).forEach(colour => {
                if (colour === details.fabric_colour)
                  this.colour_selected = details.fabric_colour;
              })

              if (details.custom_colour_code)
                this.custom_colour_code = details.custom_colour_code;

              this.controlTypeOptions.forEach(option => {
                if (option.name === details.control_type)
                  this.control_type_selected = option;
              })

              this.openingTypeOptions.forEach(option => {
                if (option.name === details.opening_type)
                  this.opening_type_selected = option;
              })

              if (details.motor)
                this.motors.forEach(option => {
                  if (option.attributes.name === details.motor)
                    this.motor_selected = option;
                })

              if (details.controller_type)
                this.controller_types.forEach(option => {
                  if (option.attributes.name === details.controller_type)
                    this.controller_type_selected = option;
                })

            })
            .catch(e => {
              this.$notify({
                title: this.$t('error'),
                text: this.$larerror(e.response.data, this.$t('cart.error_remove_from_cart')),
                type: 'error',
              });
            });
      }
    }
  },
  computed: {
    runnerQty() {
      return (parseFloat(this.blind.width) + (this.blind.overlap_width ? parseFloat(this.blind.overlap_width) : 0)) / this.width_divider;
    },
    slatQty() {
      return this.runnerQty - 1;
    },
    slatQtyUI() {
      let total = 0;

      if (this.runnerQty >= 1)
        total = Math.round(this.runnerQty - 1).toString();
      else
        total = Math.round(this.runnerQty).toString();

      if (this.opening_type_selected && this.opening_type_selected.type === 'open_middle' && total % 2 !== 0)
        total++;

      return total;
    },
    articlesCost() {
      let total = 0;

      if (this.motor_selected)
        total += this.motor_selected.attributes.price;

      if (this.controller_type_selected)
        total += this.controller_type_selected.attributes.price;

      return total;
    },
    apertureWidthWithOverlap() {
      return (this.blind.width ? parseFloat(this.blind.width) : 0) + (this.blind.overlap_width ? parseFloat(this.blind.overlap_width) : 0)
    },
    apertureDropWithOverlap() {
      return (this.blind.drop ? parseFloat(this.blind.drop) : 0) + (this.blind.overlap_drop ? parseFloat(this.blind.overlap_drop) : 0)
    },
    blindCost() {
      let total = 0;

      this.per_cm_items.forEach(item => {
        if (item.attributes.on_width)
          total += item.attributes.price * item.attributes.multiplier * this.apertureWidthWithOverlap;
      })

      this.per_slat_items.forEach(item => {
        total += item.attributes.price * this.slatQty * item.attributes.multiplier;
      })

      this.per_runner_items.forEach(item => {
        total += item.attributes.price * this.runnerQty * item.attributes.multiplier;
      })

      this.per_unit_items.forEach(item => {
        total += item.attributes.price * item.attributes.quantity * item.attributes.multiplier;
      })

      return total;
    },
    totalCostWithQuantity() {
      return this.blind_quantity * this.totalCost
    },
    fabricCost() {
      if (this.fabric_selected)
        return this.fabric_selected.attributes.price * this.slatQty * this.fabric_selected.attributes.multiplier * this.apertureDropWithOverlap;
      else
        return 0;
    },
    totalCost() {
      if (this.fabric_selected && this.control_type_selected) {
        let total = 0;

        total = this.blindCost + this.fabricCost + this.articlesCost;

        if (this.control_type_selected.name === 'Cord Control')
          total *= 1.05;

        return Math.ceil(total);

      } else return 0;
    }
  },
  async mounted() {
    await this.retrieveBlind();
    await this.retrieveArticles();
    await this.retrieveFabrics();

    if (this.$route.query.token) this.populate();
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  @apply px-8 max-w-6xl mx-auto bg-white flex flex-col;

  & > .header-container {
    @apply flex flex-col sticky top-0 bg-white py-8;
    z-index: 99999;

    @screen lg {
      @apply flex-row
    }

    .title-container {
      @apply flex flex-row items-center;

      a {
        @apply text-primary text-2xl cursor-pointer;

        svg {

          &:hover {
            animation: spin 0.2s linear;
            animation-direction: reverse;

          }
        }
      }

      h1 {
        @apply font-bold text-center text-2xl ml-6;

        @screen lg {
          @apply text-3xl max-w-full ml-6;
        }
      }
    }

    .price-container {
      @apply flex flex-col items-center lg:mt-4;

      @screen lg {
        @apply mt-0 flex-row;
      }

      .price {
        @apply bg-white border-2 border-grey-light rounded py-2 w-full lg:w-5/12 lg:mr-8;

        @screen lg {
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;
        }

        p {
          @apply text-center text-black text-xl font-bold ;
          white-space: nowrap;
        }
      }

      button {
        @apply w-full lg:w-7/12 mt-4 lg:mt-0 h-12;
      }
    }
  }

  .note-field {
    @apply w-3/4;
  }

  & > .form-container {
    @apply bg-grey-form border-2 border-grey-form rounded-xl mb-8 p-6;

    @screen md {
      @apply p-8;
    }

    @screen lg {
      @apply p-10;
    }

    .form-title {
      @apply text-xl font-bold mb-8;
    }

    h2 {
      @apply text-sm text-black font-bold mb-8;
    }

    .colour-container {
      @apply border-b border-grey-light pb-8 mb-8;

      &:last-of-type {
        @apply border-0 pb-0 mb-0;
      }
    }

    .form {
      @apply -mb-4;

      &.main-form {
        @apply mb-2;

        .input-group {
          .input {
            width: 50% !important;
            border-color: theme('colors.primary') !important;
          }
        }
      }
    }

    .divider {
      @apply mx-auto border-t-2 border-grey-light rounded-md my-8 w-full;

      @screen lg {
        @apply mx-auto;
      }
    }

    .form-wrapper {
      @apply flex flex-row w-full;

      .textboxes-container {
        @apply flex flex-col w-1/4 mr-2;

        .input-group {
          @apply w-full;
        }
      }
    }

    .custom-colour-field {
      @apply mb-0 mt-8 max-w-sm;
    }
  }

  & > .title {
    @apply font-bold my-4;
  }

  .item-container {
    @apply flex flex-row flex-wrap -m-4;

    .slat-qty-input {
      @apply w-40 mx-auto my-8;

      @screen lg {
        @apply m-auto;
      }
    }

    .slat-qty-grp-input {
      @apply w-full flex-row flex-nowrap mx-auto my-8;

      @screen lg {
        @apply w-120;
      }

      .slat-qty-input {
        @apply w-full mx-4 my-0;
      }
    }

    .slat-qty-grp-input-sml {
      @screen lg {
        @apply w-100;
      }

      .slat-qty-input {
        @apply w-full mx-2 my-0;
      }
    }

    .item-card-container {
      @apply w-1/2 p-4;

      @screen md {
        @apply w-1/4;
      }

      @screen lg {
        @apply w-1/5;
      }

      .item-card {
        @apply w-full h-full min-h-24 bg-white rounded-xl flex flex-col max-w-full px-6 cursor-pointer border-white border-3;
        box-shadow: 0px 10px 15px #00000012;

        &.item-card-image {
          @apply h-full py-4;

          & > img {
            @apply h-12 mt-4;
          }
        }

        &:hover {
          @apply border-primary;
        }

        &.selected {
          @apply border-primary;
        }

        &.disabled {
          @apply cursor-not-allowed bg-grey-light border-grey-light;

          &:hover {
            @apply border-grey-light;
          }
        }

        .item-card-title {
          @apply text-center m-auto text-sm font-bold;

          @screen lg {
            @apply text-base;
          }
        }

        .price {
          @apply text-center m-auto text-sm font-bold;

          @screen lg {
            @apply text-base;
          }
        }
      }
    }
  }
}
</style>