import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import ArticlesIndex from "../views/articles/Index";
import BlindsIndex from "../views/blinds/Index";
import BlindsUpdate from "../views/blinds/Update";
import CategoriesIndex from "../views/categories/Index";
import CategoriesCreate from "../views/categories/Create";
import CategoriesUpdate from "../views/categories/Update";
import FabricsIndex from "../views/fabrics/Index";
import OrdersIndex from "../views/orders/Index";
import OrdersUpdate from "../views/orders/Update";
import RolesIndex from "../views/roles/Index";
import UsersIndex from "../views/users/Index";
import UsersUpdate from "../views/users/Update";
import ReportsIndex from "../views/reports/Index";
import Login from "../views/Login";
import NotFound from "../views/NotFound";
import Profile from "../views/Profile";
import {i18n} from '../locale/i18n';
import Reset from "@/views/Reset";
import CalculatorsIndex from "@/views/calculators/Index";
import ZebraBlind from "@/views/calculators/ZebraBlind";
import RollerBlind from "@/views/calculators/RollerBlind";
import RollerBlind2 from "@/views/calculators/RollerBlind2";
import RollerBlind3 from "@/views/calculators/RollerBlind3";
import RollerBlindChoice from "../views/calculators/RollerBlindChoice";
import VerticalBlindSlatChoice from "../views/calculators/VerticalBlindSlatChoice";
import VerticalBlind90mm from "@/views/calculators/VerticalBlind90mm";
import VerticalBlind127mm from "@/views/calculators/VerticalBlind127mm";
import VenetianBlind from "@/views/calculators/VenetianBlind";
import Cart from "../views/calculators/Cart";
import Checkout from "../views/calculators/Checkout";
import MyOrders from "@/views/calculators/orders/MyOrders";
import OrderDetails from "@/views/calculators/orders/OrderDetails";
import SheerCurtain from "@/views/calculators/SheerCurtain";
import PVCCustomCover from "@/views/calculators/PVCCustomCover";
import MyQuotes from "@/views/calculators/quotes/Quotes";
import QuoteDetails from "../views/calculators/quotes/QuoteDetails";
import SheerCurtain2 from "@/views/calculators/SheerCurtain2.vue";
import ZebraBlind2 from "@/views/calculators/ZebraBlind2.vue";
import SheerCurtainChoice from "@/views/calculators/SheerCurtainChoice.vue";
import ZebraBlindChoice from "@/views/calculators/ZebraBlindChoice.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/articles'
    },
    {
        path: '/articles',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'articles-index',
                component: ArticlesIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read articles'],
                },
            }
        ]
    },
    {
        path: '/blinds',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'blinds-index',
                component: BlindsIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read blinds'],
                },
            },
            {
                path: ':id',
                name: 'blinds-update',
                component: BlindsUpdate,
                meta: {
                    auth: true,
                    any_permissions: ['read blinds', 'update blinds'],
                }
            }
        ]
    },
    {
        path: '/categories',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'categories-index',
                component: CategoriesIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read categories'],
                },
            },
            {
                path: 'add',
                name: 'categories-create',
                component: CategoriesCreate,
                meta: {
                    auth: true,
                    all_permissions: ['store categories'],
                },
            },
            {
                path: ':id',
                name: 'categories-update',
                component: CategoriesUpdate,
                meta: {
                    auth: true,
                    all_permissions: ['read categories', 'update categories'],
                },
            },
        ]
    },
    {
        path: '/fabrics',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'fabrics-index',
                component: FabricsIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read fabrics'],
                },
            }
        ]
    },
    {
        path: '/calculators',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'calculators-index',
                component: CalculatorsIndex,
            },
            {
                path: 'cart',
                name: 'cart',
                component: Cart,
            },
            {
                path: 'checkout',
                name: 'checkout',
                component: Checkout,
            },
            {
                path: 'quotes',
                name: 'quotes',
                component: MyQuotes,
            },
            {
                path: 'quotes/:id/quote-details',
                name: 'quote-details',
                component: QuoteDetails,
            },
            {
                path: 'my-orders',
                name: 'my-orders',
                component: MyOrders,
            },
            {
                path: 'my-orders/:id/order-details',
                name: 'order-details',
                component: OrderDetails,
            },
            {
                path: 'zebra-blind-choice',
                name: 'zebra-blind-choice',
                component: ZebraBlindChoice,
            },
            {
                path: 'zebra-blind',
                name: 'zebra-blind',
                component: ZebraBlind,
            },
            {
                path: 'zebra-blind-2',
                name: 'zebra-blind-2',
                component: ZebraBlind2,
            },
            {
                path: 'roller-blind-choice',
                name: 'roller-blind-choice',
                component: RollerBlindChoice
            },
            {
                path: 'roller-blind',
                name: 'roller-blind',
                component: RollerBlind,
            },
            {
                path: 'roller-blind-2',
                name: 'roller-blind-2',
                component: RollerBlind2,
            },
            {
                path: 'roller-blind-3',
                name: 'roller-blind-3',
                component: RollerBlind3,
            },
            {
                path: 'sheer-curtain-blind',
                name: 'sheer-curtain-blind',
                component: SheerCurtain,
            },
            {
                path: 'sheer-curtain-choice',
                name: 'sheer-curtain-choice',
                component: SheerCurtainChoice
            },
            {
                path: 'sheer-curtain-blind-2',
                name: 'sheer-curtain-blind-2',
                component: SheerCurtain2,
            },
            {
                path: 'vertical-blind-90mm',
                name: 'vertical-blind-90mm',
                component: VerticalBlind90mm,
            },
            {
                path: 'vertical-blind-127mm',
                name: 'vertical-blind-127mm',
                component: VerticalBlind127mm,
            },
            {
                path: 'venetian-blind',
                name: 'venetian-blind',
                component: VenetianBlind,
            },
            {
                path: 'vertical-blind-slat-choice',
                name: 'vertical-blind-slat-choice',
                component: VerticalBlindSlatChoice,
            },
            {
                path: 'pvc-custom-cover',
                name: 'pvc-custom-cover',
                component: PVCCustomCover
            }
        ]
    },
    {
        path: '/orders',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'orders-index',
                component: OrdersIndex,
                meta: {
                    auth: true,
                    all_permissions: [],
                },
            },
            {
                path: ':id',
                name: 'orders-update',
                component: OrdersUpdate,
                meta: {
                    auth: true,
                    all_permissions: [],
                },
            },
        ]
    },
    {
        path: '/roles',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'roles-index',
                component: RolesIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read roles'],
                },
            },
        ]
    },
    {
        path: '/users',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'users-index',
                component: UsersIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read users'],
                },
            },
            {
                path: ':id',
                name: 'users-update',
                component: UsersUpdate,
                meta: {
                    auth: true,
                    any_permissions: ['read users', 'update users'],
                }
            }
        ]
    },
    {
        path: '/reports',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'reports-index',
                component: ReportsIndex,
                meta: {
                    auth: true,
                },
            },
        ]
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            auth: true,
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            auth: 'guest',
        }
    },
    {
        path: '/password/reset',
        name: 'reset',
        component: Reset,
        meta: {
            auth: 'guest',
        }
    },
    {
        path: '*',
        name: 'notfound',
        component: NotFound,
        meta: {
            auth: false
        }
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});


router.beforeEach(async (to, from, next) => {
    window.scrollTo(0, 0);

    if (!to.meta)
        next();

    // check auth
    if (to.meta.auth) {
        const routeAuth = to.meta.auth;

        if (routeAuth === true) {
            // user must be logged in
            if (!store.getters.token) {
                Vue.notify({
                    type: 'error',
                    text: i18n.t('auth.unauthenticated')
                });

                return next({name: 'login'});
            }
        } else if (routeAuth.toLowerCase() === 'guest') {
            // user must be logged out
            if (store.getters.token)
                return next({path: '/'});
        }
    }

    // check permission
    if (to.meta.any_permissions) {
        // check that the user has some of the required permissions

        if (!store.getters.hasAnyPermission(to.meta.any_permissions)) {
            Vue.notify({
                type: 'error',
                text: i18n.t('auth.unauthorized')
            });

            return next({path: '/'});
        }
    }

    // check permission
    if (to.meta.all_permissions) {
        // check that the user has all the required permissions

        let permissions = to.meta.all_permissions;
        if (!store.getters.hasAllPermissions(permissions)) {
            Vue.notify({
                type: 'error',
                text: i18n.t('auth.unauthorized')
            });

            return next({path: '/'});
        }
    }

    return next();
});

export default router;
