<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <Button v-if="$store.getters.hasPermission('store fabrics')" className="--primary --small"
                        :onclick="toggleCreate">
                    {{ $t('fabrics.add_fabric') }}
                </Button>
            </template>
            <template v-slot:right>
                <Search class="search-desktop" :placeholder="$t('fabrics.search_fabrics')" @search="search"/>
                <button class="btn-search-mobile btn-icon-only"
                        @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>
            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <Search v-if="headbarExpanded === 'search'" :placeholder="$t('fabrics.search_fabrics')" @search="search"/>
                </div>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                mode="remote"
                styleClass="vgt-table vgt-custom"
                :columns="columns"
                :rows="fabrics"
                :isLoading.sync="is_loading_fabrics"
                :search-options="{
                    enabled: false,
                }"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 15,
                    perPageDropdownEnabled: false,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('fabrics.fabrics')}),
                }"
                :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                }"
                :totalRows="totalRecords"
                @on-page-change="onPageChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'attributes.price'">
                        <p>€ {{props.row.attributes.price.toFixed(2)}}</p>
                    </div>
                    <div v-else-if="props.column.field === 'attributes.colors'">
                        <p v-if="props.row.attributes.colors">{{JSON.parse(props.row.attributes.colors).join(', ')}}</p>
                    </div>
                    <div v-else-if="props.column.field === 'after'" class="td-after">
                        <Button v-if="$store.getters.hasPermission('update fabrics')"
                                className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleUpdate(props.row)">
                            <font-awesome-icon :icon="['fal', 'pencil']"/>
                        </Button>
                        <Button v-if="$store.getters.hasPermission('destroy fabrics')"
                                className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import ArticlesCreateModal from "../../components/articles/ArticlesCreateModal";
import ArticlesUpdateModal from "../../components/articles/ArticlesUpdateModal";
import FabricsCreateModal from "@/components/fabrics/FabricsCreateModal";
import FabricsUpdateModal from "@/components/fabrics/FabricsUpdateModal";

export default {
    name: "fabrics-index-page",
    components: {ConfirmModal, Select, Button, Search, Headbar},
    data: function () {
        const columns = [
            {
                label: this.$t('fabrics.code'),
                field: 'attributes.code',
                sortable: false,
            },
            {
                label: this.$t('fabrics.name'),
                field: 'attributes.name',
                sortable: false,
            },
            {
                label: this.$t('fabrics.colors'),
                field: 'attributes.colors',
                sortable: false,
            },
            {
                label: this.$t('fabrics.price'),
                field: 'attributes.price',
                sortable: false,
            },
        ];

        if (this.$store.getters.hasAnyPermission(['update fabrics', 'destroy fabrics']))
            columns.push({
                label: this.$t('fabrics.actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false
            });

        return {
            columns: columns,
            fabrics: [],
            is_loading_fabrics: false,
            totalRecords: null,
            serverParams: {sorting: []},
            searchTerm: null,
            is_deleting: false,
            categoryOptions: [],
            category: null,
            headbarExpanded: false
        }
    },
    methods: {
        toggleDelete(fabric) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('fabrics.delete_fabric'),
                    message: this.$t('fabrics.prompt_delete'),
                    confirmText: this.$t('delete'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.delete(`fabrics/${fabric.id}`)
                                .then(({data}) => {
                                    this.is_deleting = false;
                                    this.retrieveFabrics();
                                })
                                .catch(e => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('articles.error_delete')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },
        toggleCreate() {
            this.$modal.show(
                FabricsCreateModal, {},
                {
                    name: 'fabrics-create-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveFabrics();
                    }
                }
            );
        },
        toggleUpdate(fabric) {
            this.$modal.show(
                FabricsUpdateModal,
                {
                    fabric_id: fabric.id,
                },
                {
                    name: 'fabrics-update-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveFabrics();
                    }
                }
            );
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.retrieveFabrics();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length) {
                this.updateParams({term: searchTerm});
            } else this.removeParam('term');

            this.retrieveFabrics();
        },
        retrieveFabrics() {
            this.is_loading_fabrics = true;

            this.$axios.get('fabrics', {params: {...this.serverParams}})
                .then(({data}) => {
                    this.fabrics = data.data;
                    this.totalRecords = data.meta.total;
                    this.is_loading_fabrics = false;
                })
                .catch(e => {
                    this.is_loading_fabrics = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('fabrics.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveCategoryOptions() {
            this.is_loading_categories = false;
            await this.$axios.get('categories/list')
                .then(({data}) => {
                    this.categoryOptions = data.data;
                    this.is_loading_categories = false;
                })
                .catch(e => {
                    this.is_loading_categories = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('categories.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },

    mounted() {
        this.retrieveFabrics();
    },

    head() {
        return {
            title: {
                inner: this.$t('nav.fabrics')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
    .page-container {
        .headbar-expanded-container {
            @apply mt-4 flex flex-col gap-y-4 items-center;

            @screen md {
                @apply hidden;
            }
        }

        .select-dropdown {
            @apply min-w-40;
        }

        .btn-search-mobile, .btn-filter-mobile {
            @apply block text-primary w-6;

            @screen md {
                @apply hidden;
            }

            &:active, &:focus {
                @apply outline-none;
            }
        }

        .search-desktop, .filter-desktop {
            @apply hidden;

            @screen md {
                @apply block;
            }
        }

        .search-container {
            @apply mr-0;
        }

        .filter-mobile {
            @apply max-w-xs;
        }

        .td-after {
            @apply flex flex-row;

            & > * {
                @apply mr-3;

                &:last-child {
                    @apply mr-0;
                }
            }
        }
    }
</style>
