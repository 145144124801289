<template>
  <p-check
    :value="value"
    :checked="checked === null ? value : checked"
    :disabled="disabled"
    :name="group"
    color="primary"
    :class="`checkbox ${className}`"
    @input="(x) => $emit('input', x)"
    @change="(x) => $emit('input', x) && $emit('change', x)"
  >
    <font-awesome-icon
      v-if="checkmark"
      class="icon"
      slot="extra"
      :icon="['far', 'check']"
    />
    {{ label ? label : '' }}
  </p-check>
</template>

<script>
export default {
  name: 'FormInputCheck',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    group: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: 'p-icon p-curve',
    },
    checkmark: {
      type: Boolean,
      default: true,
    },
    checked: {
      type: Boolean,
      default: null,
    },
  },
};
</script>
