<template>
    <loading-screen v-if="is_loading_cart && is_loading_user && !cart"></loading-screen>
    <div v-else-if="!is_loading_cart && !is_loading_user && cart" class="page-container">
        <div class="page-content-container">
            <div class="header-container" v-if="!order_details">
                <div class="left">
                    <router-link :to="{name: 'cart'}"><font-awesome-icon :icon="['far', 'arrow-circle-left']"/></router-link>
                    <h1>{{ $t('cart.checkout') }}</h1>
                </div>

                <div class="right">
                    <p class="price-container">€{{cartTotal}}</p>
                    <button class="button --primary" :disabled="!cart.relationships.blinds.data.length" @click="placeOrder">{{ $t('cart.place_order') }}</button>
                </div>
            </div>

            <div class="sales-person-container" v-if="!order_details && !isAgent">
                <h1>{{$t('cart.sales_person')}}</h1>
                <Form class="form-container" @submit.prevent="placeOrder">
                    <FormGroupTwo>
                        <FormInputText v-model="sales_person.company" identifier="company_name" :label="$t('users.company_name')" :placeholder="$t('users.company_name')" disabled/>
                        <FormInputText v-model="sales_person.name" identifier="name" :label="$t('users.name')" :placeholder="$t('users.name')" disabled/>
                    </FormGroupTwo>
                    <FormInputText v-model="sales_person.email" identifier="email_address" :label="$t('users.email_address')" :placeholder="$t('users.email_address')" disabled/>
                </Form>
            </div>

            <div class="billing-details-container" v-if="!order_details">
                <h1>{{isAgent ? $t('cart.agent_invoicing_details') : $t('cart.client_invoicing_details')}}</h1>
                <Form class="form-container" @submit.prevent="placeOrder">
                    <div class="form-wrapper">
                        <FormGroupTwo>
                            <FormInputText v-model="$v.billing_details.name.$model" identifier="name" :label="$t('users.name')" :placeholder="$t('users.name')" :class="{error: $v.billing_details.name.$error}" :disabled="isAgent"/>
                            <FormInputText v-model="$v.billing_details.phone.$model" identifier="phone" :label="$t('users.contact_number')" :placeholder="$t('users.contact_number')" :class="{error: $v.billing_details.phone.$error}" :disabled="isAgent"/>
                        </FormGroupTwo>

                        <FormInputText v-model="$v.billing_details.address.$model" identifier="address" :label="$t('users.address')" :placeholder="$t('users.address')" :class="{error: $v.billing_details.address.$error}" :disabled="isAgent"/>
                        <FormInputText v-model="$v.billing_details.email.$model" identifier="email_address" :label="$t('users.email_address')" :placeholder="$t('users.email_address')" :class="{error: $v.billing_details.email.$error}" :disabled="isAgent"/>

                        <FormGroupTwo v-if="!isAgent">
                            <FormInputText v-model="discount" identifier="discount" :label="`${$t('cart.discount')} (%)`" :placeholder="$t('cart.discount')" type="number"/>
                            <FormInputText v-model="installation" identifier="installation" :label="`${$t('users.installation')} (€)`" :placeholder="$t('users.installation')" type="number"/>
                        </FormGroupTwo>
                    </div>

                    <div class="form-wrapper">
                        <FormGroupTwo>
                            <FormInputText v-model="billing_details.company" identifier="company_name" :label="$t('users.company_name')" :placeholder="$t('users.company_name')" :disabled="isAgent"/>
                            <FormInputText v-model="billing_details.vat_num" identifier="vat_number" :label="$t('users.vat_number')" :placeholder="$t('users.vat_number')" :disabled="isAgent"/>
                        </FormGroupTwo>

                        <FormInputText v-model="note" identifier="note" :label="$t('calculators.note')" :placeholder="$t('calculators.note')" :useTextarea="true" :largeTextarea="true"></FormInputText>

                        <FormGroupTwo v-if="!isAgent">
                            <FormInputText v-model="deposit" identifier="deposit" :label="`${$t('users.deposit')} (€)`" :placeholder="$t('users.deposit')" type="number"/>
                        </FormGroupTwo>
                    </div>
                </Form>
            </div>

            <div v-else class="order-placed-container">
                <div class="order-placed-message-container">
                    <p>{{$t('cart.order_placed_successfully')}}</p>
                    <font-awesome-icon :icon="['fal','check-circle']"/>

                    <div class="buttons-container">
                        <router-link tag="button" class="button --secondary --outline --wide" :to="{name: 'calculators-index'}">Return Home</router-link>
                        <router-link tag="button" class="button --primary --wide" :to="{name: 'my-orders'}">My Orders</router-link>
                    </div>
                </div>
            </div>

            <div class="order-details-container" v-if="order_details">
                <div class="title-row">
                    <p>Order ID</p>
                    <p>Ordered</p>
                    <p>Status</p>
                    <p>Value</p>
                </div>

                <div class="details-row">
                    <p>{{order_details.attributes.reference}}</p>
                    <p>{{order_details.attributes.created_at | date}}</p>
                    <p>{{order_details.attributes.status}}</p>
                    <p>€{{order_details.attributes.value}}</p>
                </div>
            </div>

            <div class="form-container cards">
                <div class="item-container">
                    <div class="item-card-container" v-for="item in cart.relationships.blinds.data">
                        <item-card :item="item" :cart="cart" :dont_show_clone="false" @cloned="getCart"></item-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "../../components/Button";
import ConfirmModal from "@/components/modal/ConfirmModal";
import Form from "@/components/form/Form";
import FormGroupTwo from "@/components/form/FormGroupTwo";
import FormInputText from "@/components/form/FormInputText";
import {required, email} from "vuelidate/lib/validators";
import ItemCard from "@/components/calculators/ItemCard";
import moment from 'moment';
import LoadingScreen from "@/components/LoadingScreen";
import FormGroupThree from "@/components/form/FormGroupThree";

export default {
    name: "Checkout",
    components: {FormGroupThree, LoadingScreen, ItemCard, FormInputText, FormGroupTwo, Form, Button},

    data() {
        return {
            cart: null,
            billing_details: {
                name: null,
                phone: null,
                address: null,
                email: null,
                company: null,
                vat_num: null,
            },
            sales_person: {
                name: null,
                phone: null,
                email: null
            },
            note: null,
            deposit: null,
            installation: null,
            discount: null,
            user: null,
            order_details: null,
            is_loading_user: false,
            is_loading_cart: false
        }
    },
    validations: {
        billing_details: {
            name: {required},
            phone: {required},
            address: {required},
            email: {email},
            company: {},
            vat_num: {},
        },
        note: {},
        deposit: {},
        installation: {},
    },
    filters: {
        date(value) {
            return moment(value).format('DD-MM-YYYY');
        },
        dateTime(value) {
            return moment(value).format('DD-MM-YYYY hh:mm:ss A');
        }
    },
    computed: {
        isAgent() {
            return !this.$store.getters.hasRole('administrator') && !this.$store.getters.hasRole('B&D Sales');
        },
        cartTotal() {
            let total = 0;

            this.cart.relationships.blinds.data.forEach(blind => {
                total += blind.attributes.value;
            })

            return total;
        }
    },
    methods: {
        populate() {
            if(this.isAgent) {
                this.billing_details.name = this.$store.getters.user.attributes.name;
                this.billing_details.email = this.$store.getters.user.attributes.email;
                this.billing_details.address = this.user.attributes.address;
                this.billing_details.company = this.user.attributes.company;
                this.billing_details.phone = this.user.attributes.phone;
                this.billing_details.vat_num = this.user.attributes.vat_num;
            } else {
                this.sales_person.company = this.user.attributes.company;
                this.sales_person.name = this.$store.getters.user.attributes.name;
                this.sales_person.email = this.$store.getters.user.attributes.email;
            }
        },
        toggleDelete(item) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('cart.remove_blind'),
                    message: this.$t('cart.are_you_sure_remove_blind'),
                    confirmText: this.$t('yes'),
                    cancelText: this.$t('no'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {}
                    }
                }
            );
        },
        getCart() {
            if(!this.$store.getters.cart) return;

            this.is_loading_cart = true;
            this.$axios.get(`carts/${this.$store.getters.cart}`)
                .then(({data}) => {
                    this.cart = data.data;
                    let cartCounter = 0;

                    if(this.cart.relationships.blinds.data.length) {
                        this.cart.relationships.blinds.data.forEach(blind => {
                            blind.attributes.options = JSON.parse(blind.attributes.options);
                            cartCounter += 1;
                        })
                    }

                    this.$store.commit('SET_CART_COUNT', cartCounter);
                    this.is_loading_cart = false;
                })
                .catch(e => {
                    this.is_loading_cart = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('users.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async getUser() {
            if(!this.$store.getters.user) return;

            this.is_loading_user = true;

            await this.$axios.get(`profile`)
                .then(({data}) => {
                    this.user = data.data;

                    this.is_loading_user = false;
                })
                .catch(e => {
                    this.is_loading_user = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('users.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        placeOrder() {
            this.$v.billing_details.$touch();
            if(this.$v.billing_details.$anyError) return;

            let payload = {
                status: 'processing',
                value: (this.cartTotal - (this.discount ? (this.cartTotal * (this.discount / 100)).toFixed(2) : 0) + (this.installation ? parseInt(this.installation) : 0) - (this.deposit ? parseInt(this.deposit) : 0)).toFixed(2)
            };

            if(!this.isAgent) {
                payload = {...payload, client_details: this.billing_details};
            }

            if(this.note)
                payload = {...payload, note: this.note};

            if(this.installation)
                payload = {...payload, installation: this.installation};

            if(this.deposit)
                payload = {...payload, deposit: this.deposit};

            if(this.discount)
                payload = {...payload, discount: this.discount};

            this.$axios.post(`carts/${this.$store.getters.cart}/place-order`, payload)
                .then(({data}) => {
                    this.order_details = data.data;
                    this.$store.commit('SET_CART', null);
                    this.$store.commit('SET_CART_COUNT', 0);

                    this.updateVat();
                })
                .catch(e => {
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('cart.failed_to_place_order')),
                        type: 'error',
                    });
                });
        },
        updateVat() {
            this.$v.billing_details.$touch();
            if (this.$v.billing_details.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = {};

            payload.vat_num = this.billing_details.vat_num

            // payload.name = this.$v.billing_details.name.$model;
            // payload.company = this.$v.billing_details.company_name.$model;
            // payload.phone = this.$v.billing_details.phone.$model;
            // payload.address = this.$v.billing_details.address.$model;
            // payload.email_address = this.$v.billing_details.address.$model;
            //
            //
            //

            this.$axios.patch(`profile`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('profile.success_updated'),
                    type: 'success',
                });

                this.is_saving = false;
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('profile.error_update')),
                    type: 'error',
                });
            });
        },
    },
    async mounted() {
        this.getCart();
        await this.getUser();

        this.populate();
    }
}
</script>

<style lang="scss" scoped>

.page-container {
    @apply w-full h-full mx-auto bg-white px-8;

    & > .page-content-container {
        @apply flex flex-col max-w-6xl mx-auto w-full;

        & > .header-container {
            @apply flex flex-col py-8;

            @screen sm {
                @apply flex-row justify-between;
            }

            & > .left {
                @apply flex flex-row mb-8;

                @screen sm {
                    @apply mb-0;
                }

                & > a {
                    @apply my-auto mr-4;

                    & > svg {
                        @apply text-primary text-2xl cursor-pointer;

                        &:hover {
                            @apply text-primary-over;
                        }
                    }
                }

                & > h1 {
                    @apply font-bold text-2xl my-auto;
                }
            }

            & > .right {
                @apply flex flex-row mx-auto;

                @screen sm {
                    @apply mr-0;
                }

                & > .price-container {
                    @apply border-2 border-grey-light rounded p-2 px-6 mr-6 text-black font-bold text-2xl;
                }

                .button {
                    @apply px-4;
                }
            }
        }

        & > .sales-person-container {
            @apply flex flex-col rounded-xl bg-grey-form p-8 pb-6 mb-10;

            & > h1 {
                @apply font-bold text-xl mb-6;
            }

            & > .form-container {
                @apply flex flex-col bg-grey-form;

                @screen lg {
                    @apply flex flex-row;
                }

                & > .input-group-two {
                    @screen lg {
                        @apply w-1/2 mr-1;
                    }
                }

                & > .input-group {
                    @screen lg {
                        @apply w-1/2 ml-1;
                    }
                }

                .form-wrapper {
                    @screen lg {
                        @apply w-1/2 mx-2;
                    }
                }
            }
        }

        & > .billing-details-container {
            @apply flex flex-col rounded-xl bg-grey-form p-8 pb-6 mb-10;

            & > h1 {
                @apply font-bold text-xl mb-6;
            }

            & > .form-container {
                @apply flex flex-col -mx-2 bg-grey-form;

                @screen lg {
                    @apply flex flex-row;
                }

                .form-wrapper {
                    @screen lg {
                        @apply w-1/2 mx-2;
                    }
                }
            }
        }

        & > .order-placed-container {
            @apply flex flex-col my-8;

            & > .order-placed-message-container {
                @apply flex flex-col rounded-xl bg-grey-form p-8;

                @screen lg {
                    @apply p-20;
                }

                & > p {
                    @apply font-bold text-2xl mx-auto text-center;
                }

                & > svg {
                    @apply mx-auto text-8xl text-primary my-12;
                }

                & > .buttons-container {
                    @apply flex flex-row mx-auto;

                    & > button {
                        @apply px-2 mx-2;

                        @screen sm {
                            @apply px-6;
                        }
                    }
                }
            }
        }

        & > .order-details-container {
            @apply flex flex-col mb-8 overflow-x-scroll;

            @screen lg {
                @apply overflow-x-auto;
            }

            & > .title-row, .details-row {
                @apply flex flex-row mb-4 w-200;

                @screen lg {
                    @apply w-full;
                }

                & > p {
                    @apply w-1/4 text-center font-bold text-lg;
                }
            }

            & > .details-row {
                @apply bg-grey-form rounded-xl py-6 capitalize;

                & > p {
                    @apply text-grey-dark;
                }
            }
        }

        & > .form-container {
            @apply bg-grey-form border-2 border-grey-form rounded-xl py-2 mb-8;

            @screen md {
                @apply py-8;
            }

            & > .item-container {
                @apply flex flex-row flex-wrap w-full px-2;

                .item-card-container {
                    @apply w-full py-4 px-4;

                    @screen md {
                        @apply w-1/2 p-3;
                    }
                }
            }
        }
    }

}
</style>